import React, { useState } from 'react';
import { CloseOutlined, KeyOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Drawer, Form, Input } from 'antd';
import { general, generalFields } from 'common/lib/schemas/rules';
import { BlackButton } from 'common/components/UI';
import userApi from 'common/api/user';
import ListItem from '../ListItem';

type PasswordProps = {
  showError: (message: string, className?: string) => void;
  showSuccess: (message: string, className?: string) => void;
}

const Password: React.FC<PasswordProps> = ({showSuccess, showError}) => {
  const { formatMessage: t } = useIntl();
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string[]}>({
    password: [],
    newPassword: [],
    confirmPassword: [],
  })

  const rules = {
    password: [general(t)['required-field'], ...generalFields(t).password],
    newPassword: [general(t)['required-field'], ...generalFields(t).password],
    confirmPassword: [
      general(t)['required-field'],
      ...generalFields(t).password,
      general(t)['must-match'](
        'newPassword',
        t({ id: 'screens.auth.signup.inputs.password' }),
      ),
    ],
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    form.resetFields();
    onFieldsChange()
    setOpen(false);
  };

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      password: form.getFieldError('password'),
      newPassword: form.getFieldError('newPassword'),
      confirmPassword: form.getFieldError('confirmPassword'),
    }))
  }

  const onFinish = ({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    userApi.settings
      .changePassword(password, newPassword)
      .then(() => {
        showSuccess(
          t({ id: 'toasts.auth.success.password.title' }),
          'toast__password-change-success'
        );
        onCloseDrawer();
      })
      .catch(() => {
        showError(
          t({ id: 'toasts.auth.error.password.title' }),
            'toast__password-change-error'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div id='settings__password' onClick={showDrawer}>
      <ListItem
        title={t({ id: 'screens.setting.setting.setting.nav.password' })}
        withBorder
        iconComponent={
          <KeyOutlined
            style={{
              fontSize: 24,
              padding: 12,
              boxSizing: 'content-box',
              background: 'rgba(0, 0, 0, 0.04)',
              borderRadius: 100,
            }}
            rotate={90}
          />
        }
      />
      </div>
      <Drawer
        closeIcon={<CloseOutlined id='btn__password-close'/>}
        title={t({ id: 'screens.auth.password.title' })}
        placement="right"
        width={512}
        onClose={onCloseDrawer}
        open={open}>
        <Form validateTrigger="onBlur" form={form} disabled={loading} onFinish={onFinish} onFieldsChange={onFieldsChange}>
          <Form.Item help={
            <div id='error__password'>{validationErrors.password[0]}</div>
          } rules={rules.password} name="password">
            <Input.Password placeholder={t({ id: 'inputs.password.current' })} />
          </Form.Item>
          <Form.Item help={
            <div id='error__new-password'>{validationErrors.newPassword[0]}</div>
          } rules={rules.newPassword} name="newPassword">
            <Input.Password placeholder={t({ id: 'inputs.password.new' })} />
          </Form.Item>
          <Form.Item help={
            <div id='error__confirm-password'>{validationErrors.confirmPassword[0]}</div>
          } rules={rules.confirmPassword} name="confirmPassword">
            <Input.Password placeholder={t({ id: 'inputs.password.repeat' })} />
          </Form.Item>
          <BlackButton id='btn__save-password' loading={loading} type="primary" onClick={form.submit} block>
            {t({ id: 'buttons.save' })}
          </BlackButton>
        </Form>
      </Drawer>
    </>
  );
};

export default Password;
