import React from 'react';
import { Flex, Space, Typography } from 'antd';
import classes from './Requisites.module.css';
import { CopyOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { GreyContainer } from 'common/components/UI';

type RequisitesBlockProps = {
  iban?: string;
  showError?: (text: string, className?: string) => unknown;
  showSuccess?: (text: string, className?: string) => unknown;
};

const RequisitesBlock: React.FC<RequisitesBlockProps> = ({
  iban,
  showError,
  showSuccess,
}) => {
  const { formatMessage: t } = useIntl();
  const onCopy = () => {
    navigator.clipboard
      .writeText(iban || '')
      .then(() => {
        showSuccess?.(t({ id: 'common.clipboard.success' }), '.toast__copy-success');
      })
      .catch(() => {
        showError?.(t({ id: 'common.clipboard.error' }), '.toast__copy-error');
      });
  };

  if (!iban) return;

  return (
    <GreyContainer>
      <Flex vertical gap={2}>
        <Flex justify="space-between">
          <Typography.Text className={classes.text}>
            {t({ id: 'account.requsites.number' })}
          </Typography.Text>
          <CopyOutlined id='btn__copy' style={{ fontSize: 20 }} onClick={onCopy} />
        </Flex>
        <Typography.Text className={classes.number}>{iban}</Typography.Text>
      </Flex>
    </GreyContainer>
  );
};

export default RequisitesBlock;
