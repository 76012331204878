import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import JSONBigInt from 'json-bigint';


type UserApi = {
  getAccounts: () => Promise<any>;
  testRequests: () => Promise<any>;
  getClientData: () => Promise<any>;
  createAccount: (title: string) => Promise<any>;
  createPrivateClient: () => Promise<any>;
  getAccountDetails: (accountId: number) => Promise<any>;
  updateAccountTitle: (accountId: number, title: string) => Promise<any>;
  fetchVerificationSumSubToken: () => Promise<any>;
  getAccountVerificationStatus: () => Promise<any>;
  updateTin: (tin: string) => Promise<any>;
  createTemplate: (body: any) => Promise<any>;
  createTemplateFromPayment: (body: any) => Promise<any>;
  deleteTemplate: (id: string) => Promise<any>;
  editTemplate: (id: string, body: any) => Promise<any>;
  getTemplates: () => Promise<any>;
  getTemplateDetails: (id: string) => Promise<any>;
  getRecurringPayments: (id: string) => Promise<any>;
}

const userApi: UserApi = {
  getAccounts() {
    return API.get(ENDPOINTS.USER_CTRL.GET_ACCOUNTS, {
      transformResponse: [(data: string) => JSONBigInt.parse(data)],
    });
  },
  testRequests() {
    return API.get(ENDPOINTS.USER_CTRL.TEST_WALLETO);
  },

  getClientData() {
    return API.get(ENDPOINTS.USER_CTRL.GET_CLIENT_DATA);
  },

  createAccount(title) {
    return API.post(ENDPOINTS.USER_CTRL.CREATE_ACCOUNT(title));
  },

  createPrivateClient() {
    return API.post(ENDPOINTS.USER_CTRL.CREATE_PRIVATE_CLIENT);
  },

  getAccountDetails(accountId) {
    return API.get(ENDPOINTS.USER_CTRL.GET_ACCOUNT_DETAILS(accountId));
  },

  updateAccountTitle(accountId, title) {
    return API.post(ENDPOINTS.USER_CTRL.UPDATE_ACCOUNT_TITLE(accountId, title));
  },

  fetchVerificationSumSubToken() {
    return API.get(ENDPOINTS.VERIFICATION_CTRL.GET_TOKEN);
  },

  getAccountVerificationStatus() {
    return API.get(ENDPOINTS.VERIFICATION_CTRL.GET_STATUS);
  },

  updateTin(tin) {
    return API.put(ENDPOINTS.VERIFICATION_CTRL.UPDATE_TIN, {tin})
  },

  createTemplate(body) {
    return API.post(ENDPOINTS.TEMPLATES_CTRL.CREATE, body);
  },

  createTemplateFromPayment(body) {
    return API.post(ENDPOINTS.TEMPLATES_CTRL.CREATE_FROM_PAYMENT, body);
  },

  deleteTemplate(id) {
    return API.delete(ENDPOINTS.TEMPLATES_CTRL.DELETE(id));
  },

  editTemplate(id, body) {
    return API.post(ENDPOINTS.TEMPLATES_CTRL.UPDATE(id), body);
  },

  getTemplates() {
    return API.get(ENDPOINTS.TEMPLATES_CTRL.LIST);
  },

  getTemplateDetails(id) {
    return API.get(ENDPOINTS.TEMPLATES_CTRL.INFO(id));
  },

  getRecurringPayments(id) {
    return API.get(ENDPOINTS.TEMPLATES_CTRL.RECURRING_PAYMENTS(id));
  },
};

export default userApi;
