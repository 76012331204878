// custom error messages
export const ERROR_MESSAGES = {
  NOT_ENOUGH: "modals.renew.error.fee.title",
  NOT_EQUAL: "schemas.match",
  INVALID_CARD_NUMBER: 'schemas.auth.login.cardnumber.notfound',
  INVALID_AMOUNT: 'schemas.greater.than',
  INVALID_IBAN: 'schemas.iban.invalid',
  PAYMENT_DECLINED: 'screens.internationalPayment.create.declined.messages.error',
  INVALID_CODE: 'toasts.phone.error.code.title',
  FAILED_TO_SEND_CODE: 'toasts.phone.error.get.title',
  RECIPIENT_CARD_NOT_VERIFIED: 'screens.c2cpayment.create.card.verified',
  OTHER: 'modals.renew.error.title',
  LITTLE_AMOUNT: 'schemas.amount.min',
  NO_SUCH_PAYMENT: 'No such payment',
  PFS_ERROR: 'pfs.error.connection',
  INVALID_CARD_STATUS: 'sections.load.load.bank.checkPayment.error.status'
};

export const ERROR_TYPES = {
  NOT_ENOUGH: 'schemas.payment.create.amount.not.enough',
  INVALID_CARD_NUMBER: 'schemas.to.invalid',
  INVALID_SCHEME: 'schemas.invalid',
  INVALID_IBAN: 'schemas.iban.invalid',
  RECIPIENT_CARD_NOT_VERIFIED: 'screens.c2cpayment.create.card.verified',
  SERVICE_UNAVAILABLE: 'error.service.unavailable',
  PFS_ERROR: 'pfs.error.connection',
  INVALID_CARD_STATUS: 'screens.cards.verify.errors.status'
}