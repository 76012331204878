import { Flex, Form, FormInstance, Input, InputNumber, MenuProps, Typography } from 'antd';
import classes from '../SendSepa.module.scss';
import React from 'react';
import { SepaForm } from '../model/types';
import { getSendSepaScheme } from '../model/scheme';
import { CardInfoT } from 'common/types/cards';
import SelectCard from '../../../../components/CardDropdown/SelectCard';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;
const { TextArea } = Input;

type AmountProps = {
  currentFrom: CardInfoT;
  onCancel: () => void;
  fee: number;
  formValues: Partial<SepaForm>;
  form: FormInstance;
}

const Amount: React.FC<AmountProps> = ({
                                       currentFrom,
                                       onCancel,
                                       fee,
                                       formValues,
                                       form,
                                     }) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendSepaScheme(t);

  return (
    <Flex vertical gap={12}>
      <Title level={3}>{t({ id: 'sections.send.tabs.sepa' })}</Title>
      <Flex vertical gap={24}>
        <Flex vertical gap={8}>
          <Text style={{
            fontSize: 16,
            lineHeight: '20px',
          }}>
            {t({ id: 'noty.card.from' })}
          </Text>
          <SelectCard
            currentOption={currentFrom}
            className='card_disabled'
          />
        </Flex>
        <Form initialValues={formValues} form={form} name="amount">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}</Text>
            <Flex vertical gap={16}>
              <Form.Item name="beneficiary">
                <Input
                  size="large"
                  disabled
                  placeholder={t({ id: 'screens.internationalPayment.create.signer.inputs.name' })}
                />
              </Form.Item>
              <Form.Item name="iban">
                <Input
                  disabled
                  size="large"
                  placeholder="IBAN"
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical>
            <Form.Item name="amount">
              <InputNumber
                addonBefore='€'
                disabled
                placeholder={t({ id: 'noty.card.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
            <Text style={{ fontSize: 14 }}>
              {t({id: 'screens.payment.create.beneficiary.inputs.amount.info2'}, {amount: (form.getFieldValue('amount') ?? 0) + fee, fee: fee})}
            </Text>
          </Flex>
          <Flex vertical gap={8} style={{marginTop: 16}}>
            <Text>{t({ id: 'sections.send.sepa.inputs.confirmAmount' })}:</Text>
            <Form.Item name="confirmAmount" rules={rules.confirmAmount}>
              <InputNumber
                addonBefore='€'
                placeholder={t({ id: 'noty.card.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
          </Flex>
          <Form.Item name="description">
            <TextArea
              disabled
              placeholder="Add note"
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Amount;