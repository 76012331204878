import React from 'react';
import classes from './AppLayout.module.scss';
import {theme as antdTheme} from "antd";
import {AppRouter} from "common/components";
import { GlobalLoader } from 'common/components/UI';

const AppLayout = () => {
  const {token: {colorBgBase}} = antdTheme.useToken();
  return (
    <div className={classes.wrapper} style={{ backgroundColor: colorBgBase }}>
      <AppRouter/>
    </div>
  );
};

export default AppLayout;
