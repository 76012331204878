export const VALIDATION_TYPE = {
  LOGIN: 'LOGIN',
  CONFIRMATION: 'CONFIRMATION',
} as const;

export const VALIDATION_METHOD = {
  NO: 'NO',
  SMS: 'SMS',
  TWO_FA: 'TWO_FA',
  BIOMETRY : 'BIOMETRY'
} as const;

export const VALIDATION_UPDATE_STATUS = {
  NEED_SMS_CONFIRMATION: 'NEED_SMS_CONFIRMATION',
  NEED_TWO_FA_CONFIRMATION: 'NEED_TWO_FA_CONFIRMATION',
  GRANTED: 'GRANTED',
} as const;
