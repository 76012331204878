import React from 'react';
import { Button, ButtonProps } from 'antd';
import { combineClasses, getGreyColor } from 'common/lib/utils/helpers';
import classes from './BlackButton.module.scss';

const BlackButton = (props: ButtonProps) => {
  const typeStyle = {
    primary: {
      backgroundColor: props.disabled ? getGreyColor(0.08) : 'black',
      color: 'white',
      border: 0,
    },
    text: { color: 'black' },
    link: { color: 'black' },
    default: { borderColor: 'black', backgroundColor: 'transparent', color: 'black' },
    dashed: { borderColor: 'black', color: 'black' },
  };

  const style = {
    ...typeStyle[props.type ?? 'default'],
    ...props.style,
  };

  const className = combineClasses(
    props.className,
    classes['button'],
    !props.type || props.type === 'default' ? classes['button_default'] : '',
    props.type === 'primary' ? classes['button_primary'] : '',
    props.type === 'text' ? classes['button_text'] : '',
  );

  return <Button {...props} className={className} style={style} />;
};

export default BlackButton;
