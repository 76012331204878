import { Flex, Typography } from 'antd';
import classes from './Option.module.scss';
import React from 'react';
import { Card } from '../../types';
import { Card as CardComponent } from '../../../common/components/UI';
import { getLinearGradientString } from '../../../common/lib/utils/helpers';
import { COLORS } from '../../lib/constants/styles';

const { Text } = Typography;

type OptionProps = {
  onChange: (event: any, option: Card) => unknown;
  option: Card;
  id?: string;
}

const Option: React.FC<OptionProps> = ({ onChange, option, id }) => (
  <Flex id={id} justify="space-between" align="center" className={classes['option']}
        onClick={(event) => onChange(event, option)}>
    <Flex vertical gap={4}>
      <Text style={{ fontSize: 14 }}>
        Weststein Pro | {option?.numberMasked}
      </Text>
    </Flex>
    <CardComponent
      size="s"
      shortNumber={option.numberMasked.slice(-4) || ''}
      bgColor={getLinearGradientString(COLORS.MAIN.GRADIENT)}
    />
  </Flex>
);

export default Option;