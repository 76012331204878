export default {
  AT: 'countries.austria',
  BE: 'countries.belgium',
  BG: 'countries.bulgaria',
  HR: 'countries.croatia',
  CY: 'countries.cyprus',
  CZ: 'countries.czech-republic',
  DK: 'countries.denmark',
  EE: 'countries.estonia',
  FI: 'countries.finland',
  FR: 'countries.france',
  DE: 'countries.germany',
  GR: 'countries.greece',
  HU: 'countries.hungary',
  IS: 'countries.iceland',
  IE: 'countries.ireland',
  IT: 'countries.italy',
  LV: 'countries.latvia',
  LI: 'countries.liechtenstein',
  LT: 'countries.lithuania',
  LU: 'countries.luxembourg',
  MT: 'countries.malta',
  NL: 'countries.netherlands',
  NO: 'countries.norway',
  PL: 'countries.poland',
  PT: 'countries.portugal',
  RO: 'countries.romania',
  SK: 'countries.slovakia',
  SI: 'countries.slovenia',
  ES: 'countries.spain',
  SE: 'countries.sweden',
  CH: 'countries.switzerland',
  GB: 'countries.united-kingdom',
}