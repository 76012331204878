import React, { ReactNode } from 'react';
import classes from './CenteredContainer.module.scss';

interface CenteredContainerProps {
    children: ReactNode;
}

const CenteredContainer: React.FC<CenteredContainerProps> = ({ children }) => {
    return (
        <div className={classes.container}>
            {React.Children.only(children)}
        </div>
    );
};

export default CenteredContainer;
