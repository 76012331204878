import { Flex, Typography } from 'antd';
import React, { useState } from 'react';
import classes from './Balance.module.css';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import RoundContainer from 'common/components/UI/RoundContainer/RoundContainer';
import { getGreyColor, splitBalance } from 'common/lib/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'common/store';

type BalanceProps = {
  balance: number;
  isRiver: boolean;
};

const Balance: React.FC<BalanceProps> = ({ balance, isRiver }) => {
  const [wholePart, fractionalPart] = splitBalance(balance);
  const hideRiver = useSelector((state: RootState) => state.user.securedRiver);
  const hideOcean = useSelector((state: RootState) => state.user.securedOcean);
  const dispatch = useDispatch<AppDispatch>();

  const toggleSecured = () => {
    if (isRiver) {
      dispatch({ type: 'User/SECURITY_BALANCE_RIVER', payload: !hideRiver });
    } else {
      dispatch({ type: 'User/SECURITY_BALANCE_OCEAN', payload: !hideOcean });
    }
  };

  return (
    <Flex justify="space-between" align="center">
      <Typography.Text
        className={classes.balance}
        id={
          isRiver
            ? hideRiver
              ? 'balance-hidden'
              : 'balance-shown'
            : hideOcean
              ? 'balance-hidden'
              : 'balance-shown'
        }>
        {isRiver ? (hideRiver ? '**' : wholePart) : hideOcean ? '**' : wholePart},
        <Typography.Text className={classes.fractionalPart}>
          {isRiver
            ? hideRiver
              ? '**'
              : fractionalPart
            : hideOcean
              ? '**'
              : fractionalPart}{' '}
          €
        </Typography.Text>
      </Typography.Text>
      <RoundContainer
        id="#btn__show-balance"
        size={32}
        bgColor={getGreyColor()}
        pointer
        onClick={toggleSecured}>
        {isRiver ? (
          hideRiver ? (
            <EyeOutlined style={{ fontSize: 20 }} />
          ) : (
            <EyeInvisibleOutlined style={{ fontSize: 20 }} />
          )
        ) : hideOcean ? (
          <EyeOutlined style={{ fontSize: 20 }} />
        ) : (
          <EyeInvisibleOutlined style={{ fontSize: 20 }} />
        )}
      </RoundContainer>
    </Flex>
  );
};

export default Balance;
