import { Country } from "common/types/app";

export function formatPhoneNumber(phoneNumber: string) {
  const str = String(phoneNumber);

  return str.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
}

export const findCountry = (id: number, countries: Country[]): Country | undefined =>
  countries.find((country: any) => country.id === id);

export const getPhoneCode = (id: number, countries: Country[]): number => {
  const foundCountry = findCountry(id, countries);
  return foundCountry ? +foundCountry.phoneCode : -1;
};
