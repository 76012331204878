import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './AppRouter';

interface AuthorizedRouteProps {
  isAuthenticated: boolean;
  redirectPath?: string;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  isAuthenticated,
  redirectPath = ROUTES.SIGN_IN.path,
}) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AuthorizedRoute;
