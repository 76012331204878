import { Country } from '../../types/app';
import localizedCountries from '../constants/countries';

export const getLocalizedCountries = (
  countries: Country[],
  t: (descriptor: object, values?: object) => string,
  ) => {
 return countries.map(country => {
   if (country.iso2 in localizedCountries) {
     const key = country.iso2;
     const keyTyped = key as keyof typeof localizedCountries;
     return {
     ...country,
       name: t({id: localizedCountries[keyTyped]})
     }
   }
   else return country;
 })
}