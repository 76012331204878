import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import { FILE_FORMAT } from '../../common/lib/constants/constants';
import { CreateSepaBody, SendBetweenAccountsBody, TransferResponse } from './dto/transfers';
import { GetTransactionsBody, TransactionsResponse } from './dto/transactions';
import { CreateTemplateBody } from "./dto/template";

type TransferApi = {
  create: (body: CreateTemplateBody) => Promise<any>,
  delete: (id: string) => Promise<any>,
  list: () => Promise<any>,
  update: (id: string, body: CreateTemplateBody) => Promise<any>
}

const transferApi: TransferApi = {
  create: (body) => API.post(ENDPOINTS.TEMPLATES_CTRL.CREATE, body),
  delete: (id) => API.delete(ENDPOINTS.TEMPLATES_CTRL.DELETE(id)),
  list: () => API.get(ENDPOINTS.TEMPLATES_CTRL.LIST),
  update: (id, body) => API.post(ENDPOINTS.TEMPLATES_CTRL.UPDATE(id), body),
}

export default transferApi;
