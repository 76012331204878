import React, { Ref } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Flex, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../Settings.module.css';
import CodeInput, { CodeInputRef } from 'common/components/UI/CodeInput/CodeInput';

const { Text } = Typography;

type PhoneConfirmStepProps = {
  phoneNumber: string;
  codeInputRef: Ref<CodeInputRef>;
  onSubmit: (code: string) => unknown;
};

const PhoneConfirmStep: React.FC<PhoneConfirmStepProps> = ({
  phoneNumber,
  codeInputRef,
  onSubmit,
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <div>
      <Flex
        className={classes['slide-wrapper']}
        justify="center"
        align="center"
        gap={16}
        vertical>
        <PhoneOutlined
          style={{
            fontSize: 36,
            padding: 18,
            boxSizing: 'content-box',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 100,
          }}
          rotate={90}
        />
        <Space
          style={{ textAlign: 'center', marginBottom: 16 }}
          size={8}
          direction="vertical">
          <Text className={classes.bold}>
            {t({ id: 'profile.settings.phone.confirm' })}
          </Text>
          <Text className={classes.hint}>
            {t({ id: 'profile.settings.phone.codeSent' }, { phone: phoneNumber })}
          </Text>
        </Space>
        <CodeInput ref={codeInputRef} onEnter={onSubmit} />
      </Flex>
    </div>
  );
};

export default PhoneConfirmStep;
