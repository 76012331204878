import React from 'react';
import classes from './Container.module.scss';
import { combineClasses } from 'common/lib/utils/helpers';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement,
  className?: string,
}
const Container: React.FC<ContainerProps> = ({children, className, ...props}) => {
  return (
    <div {...props} className={combineClasses(classes.container, className)}>
      {children}
    </div>
  );
};

export default Container;