import React, { HTMLAttributes } from 'react';
import classes from './CardIcon.module.scss';
import { combineClasses } from '../../lib/utils/helpers';
import { Typography } from 'antd';
import { ReactComponent as MasterCardIcon } from 'common/assets/svg/mastercard.svg';

interface CardIconProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  bgImage?: any;
  gradient?: Array<string>;
  cardNumber?: string;
  disabled?: boolean;
  error?: boolean;
}
const CardIcon: React.FC<CardIconProps> = ({bgImage, error, disabled, cardNumber, title, gradient, className, ...props}) => {
  let backgroundStyle;
  if (disabled) {
    backgroundStyle = { background: 'linear-gradient(135.00deg, rgb(240,240,240) 0%, rgb(190,190,190) 100%)' };
  } else if (error) {
    backgroundStyle = { background: 'linear-gradient(135.00deg, rgb(255,100,100) 0%, rgb(255,0,0) 100%)' };
  } else if (bgImage) {
    backgroundStyle = { backgroundImage: `url(${bgImage})` };
  } else {
    backgroundStyle = { background: `linear-gradient(135.00deg, ${gradient?.[0]} 0%,${gradient?.[1]} 100%)` };
  }

  return (
    <div {...props} className={combineClasses(classes.container, className)} style={{...backgroundStyle, pointerEvents: disabled ? 'none' : 'auto'}}>
      {title && <Typography.Text className={classes.title}>{title.toUpperCase() }</Typography.Text>}
      <div className={classes.infoRow}>
        <div className={classes.cells}>
          {cardNumber && <div className={classes.cell}>
            <Typography.Text className={classes.text}>
              {cardNumber?.slice(-4) || '****'}
            </Typography.Text>
          </div>}
          <div className={classes.cell} style={{ justifyContent: 'flex-end' }}>
            <MasterCardIcon style={{ width: 25, height: 17 }} />
          </div>
        </div>
      </div>
    </div>
  );
};
CardIcon.defaultProps = {
  title: '',
  gradient: ['rgb(224, 204, 251)', 'rgb(195, 194, 251)'],
};
export default React.memo(CardIcon);