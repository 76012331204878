import { Flex, Typography } from 'antd';
import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import { BlackButton } from 'common/components/UI';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

type SuccessProps = {
  amount: number,
  name: string,
  resetForm: () => unknown
}

const Success: React.FC<SuccessProps> = ({
  amount,
  name,
  resetForm,
}) => {
  const { formatMessage: t } = useIntl();
  const navigate = useNavigate();

  return (
    <Flex vertical align="center" gap={32}>
      <Flex vertical align="center" gap={16}>
        <Flex justify="center" align="center" style={{
          borderRadius: '50%',
          backgroundColor: '#1EC624',
          width: 80,
          height: 80,
        }}>
          <CheckOutlined style={{ color: 'white', fontSize: 40 }} />
        </Flex>
        <Flex vertical align="center">
          <Text strong style={{ fontSize: 20 }}>
            {t({ id: 'sections.send.swift.success.info' }, {amount, name})}
          </Text>
          <Text strong style={{ fontSize: 20 }}>
            {t({ id: 'sections.send.swift.success.info2' })}
          </Text>
        </Flex>
      </Flex>
      <BlackButton
        onClick={() => {
          resetForm();
          navigate(ROUTES.OCEAN_SEND.path);
        }}
        type="primary"
      >
        {t({ id: 'sections.send.swift.success.button' })}
      </BlackButton>
    </Flex>
  );
};

export default Success;