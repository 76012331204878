import { IntlFormatMessageFunc } from 'common/types/app';
import { OCEAN_TRANSACTION_TYPE } from 'ocean/lib/constants/values';
import { TransactionSubtype } from 'ocean/types/transfer';
import { FILTERS } from './constants';

export const getTypeLabel = (t: IntlFormatMessageFunc, type: TransactionSubtype) => {
  switch (type) {
    case OCEAN_TRANSACTION_TYPE.CARD_RECHARGE:
      return t({ id: 'transactions.item.type.card_recharge' });
    case OCEAN_TRANSACTION_TYPE.MONTHLY_CHARGE:
      return t({ id: 'transactions.item.type.monthly_charge' });
    case OCEAN_TRANSACTION_TYPE.SEPA_INCOMING:
      return t({ id: 'transactions.item.type.sepa_incoming' });
    case OCEAN_TRANSACTION_TYPE.SEPA_INCOMING_RETURN:
      return t({ id: 'transactions.item.type.sepa_incoming_return' });
    case OCEAN_TRANSACTION_TYPE.SEPA_INCOMING_FEE:
      return t({ id: 'transactions.item.type.sepa_incoming_fee' });
    case OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING:
      return t({ id: 'transactions.item.type.sepa_outgoing' });
    case OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING_FEE:
      return t({ id: 'transactions.item.type.sepa_outgoing_fee' });
    case OCEAN_TRANSACTION_TYPE.SMS_PIN_RETRIEVAL_FEE:
      return t({ id: 'transactions.item.type.sms_pin_retrieval_fee' });
    case OCEAN_TRANSACTION_TYPE.INSTANT_SEPA_INCOMING_PAYMENT:
      return t({ id: 'transactions.item.type.instant_sepa_incoming_payment' });
    case OCEAN_TRANSACTION_TYPE.PURCHASE:
      return t({ id: 'transactions.item.type.purchase' });
    case OCEAN_TRANSACTION_TYPE.UNKNOWN:
    default:
      return t({ id: 'transactions.item.type.unknown' });
  }
};

export const getFilterTypeLabel = (
    t: IntlFormatMessageFunc,
    filter: keyof typeof FILTERS,
  ) => {
    switch (filter) {
      case FILTERS.ALL:
        return t({ id: 'transactions.filter.all' });
      case FILTERS.IN:
        return t({ id: 'transactions.filter.in' });
      case FILTERS.OUT:
        return t({ id: 'transactions.filter.out' });
    }
  };
