import React from 'react';
import { Divider, Flex, Typography } from 'antd';
import { EllipsisOutlined, RightOutlined } from '@ant-design/icons';
import {
  ActionButton,
  BackButton,
  GridContainer,
  RoundContainer
} from 'common/components/UI';
import classes from './Send.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import EuroIcon from 'common/components/icons/Euro';
import TransferIcon from 'common/components/icons/Transfer';

const { Title } = Typography;

const Index: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        <Flex gap="middle" vertical>
          <Title level={3}>{t({ id: 'sections.header.header.buttons.commonSend' })}</Title>
          <Flex vertical gap={10}>
            <RoundContainer id='send__templates' size={60} pointer onClick={() => navigate('/pro/send/templates')}>
              <EllipsisOutlined style={{fontSize: 32}} />
            </RoundContainer>
            {t({id: 'sections.templates.title'})}
          </Flex>
          <Divider />
          <Flex gap="middle" vertical>
            <ActionButton
              icon={<RightOutlined />}
              id='send__sepa'
              prefix={
                <div className={classes['icon-container']}>
                  <EuroIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.load.load.tabs.sepa' })}
              onClick={() => navigate(ROUTES.RIVER_SEND_SEPA.path)}
            />
            <ActionButton
              icon={<RightOutlined />}
              id='send__between-accounts'
              prefix={
                <div className={classes['icon-container']}>
                  <TransferIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.load.load.tabs.betweenAccounts' })}
              onClick={() => navigate(ROUTES.RIVER_SEND_BETWEEN_ACCOUNTS.path)}
            />
          </Flex>
        </Flex>
      </div>
    </GridContainer>
  );
};

export default Index;
