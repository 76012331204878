import React from 'react';
import { Button, Flex, Modal, Result } from 'antd';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

type GetPINModalProps = {
  isOpen: boolean;
  onClose: () => unknown;
  onSend: () => unknown;
};

const GetPINModal: React.FC<GetPINModalProps> = ({ isOpen, onSend, onClose }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Modal title="&nbsp;" open={isOpen} footer={false} onCancel={onClose}>
      <Result
        status="warning"
        title={t({ id: 'card.manage.getPIN' })}
        subTitle={t({ id: 'card.get.pin.text' })}
        extra={
          <Flex gap={8} vertical>
            <BlackButton type="primary" key="send" block onClick={onSend}>
              {t({ id: 'card.manage.getPIN' })}
            </BlackButton>
            <Button key="cancel" onClick={onClose} block>
              {t({ id: 'button.simple.cancel' })}
            </Button>
          </Flex>
        }
      />
    </Modal>
  );
};

export default GetPINModal;
