import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './Transactions.module.scss';
import { Flex, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'common/store';
import {
  ALL_CONFIG,
  FILTERS,
  MONTH_CONFIG,
  TRANSACTION_COUNT,
} from './model/constants';
import moment from 'moment';
import { TransactionItem } from './TransactionsList';
import DrawerTransactions from './DrawerTransactions';
import { ActivityIndicator, GreyContainer } from 'common/components/UI';
import { Transaction, TransactionType } from '../../../../types/transfer';
import { downloadTransaction, getTransactions } from '../../../../store/actions/transfer';
import { useIntl } from 'react-intl';
import { OCEAN_TRANSACTION_STATUS } from 'ocean/lib/constants/values';
import { handleLangFormat } from '../../../../../common/lib/utils/lang';
import { getUserLocale } from '../../../../../common/store/selectors/app';

type TransactionsProps = {
  cardId: number;
  isOpen?: boolean;
  onClose?: () => unknown;
  onOpen?: () => unknown;
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
  showInfo?: (text: string) => unknown;
};

const Transactions: React.FC<TransactionsProps> = ({
  cardId,
  isOpen,
  onClose,
  onOpen,
  showError,
  showSuccess,
  showInfo
}) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [monthTransactions, setMonthTransactions] = useState<Transaction[]>([]);
  const [activeFilter, setActiveFilter] = useState<TransactionType>(
    FILTERS.ALL as TransactionType,
  );
  const [activeTransaction, setActiveTransaction] = useState<Transaction>();
  const lang = handleLangFormat(useSelector(getUserLocale));

  useEffect(() => {
    moment.locale(lang);
  }, [lang])

  const fetchMonthData = useCallback(async () => {
    try {
      if (!cardId) return;

      setLoading(true);
      const { transactions: transactionsList } = await dispatch(
        getTransactions(cardId, {
          ...MONTH_CONFIG,
          type: (!activeFilter
            ? FILTERS['ALL']
            : FILTERS[activeFilter]) as TransactionType,
        }),
      );
      setMonthTransactions(transactionsList);
    } catch (error) {
      console.log('error fetching: ', error);
    } finally {
      setLoading(false);
    }
  }, [cardId, activeFilter]);

  const updateData = async (filter?: TransactionType) => {
    if (!cardId) return;

    const refreshTransactions = !filter;
    const nextPage = page + 1;

    try {
      setLoading(true);
      if (refreshTransactions) {
        const { transactions: transactionsList, totalPages } = await dispatch(
          getTransactions(cardId, {
            ...ALL_CONFIG,
            type: FILTERS[activeFilter] as TransactionType,
            page: nextPage,
          }),
        );
        setCanLoadMore(totalPages - 1 > nextPage);
        setTransactions((prev) => [...prev, ...transactionsList]);
      }
    } catch (error) {
      console.log('error fetching: ', error);
    } finally {
      setPage(nextPage);
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (!cardId) return;

    try {
      setLoading(true);
      const { transactions: transactionsList, totalSize } = await dispatch(
        getTransactions(cardId, {
          ...ALL_CONFIG,
          type: FILTERS[activeFilter] as TransactionType,
        }),
      );
      setCanLoadMore(totalSize > TRANSACTION_COUNT);
      setTransactions(transactionsList);
    } catch (error) {
      console.log('error fetching: ', error);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [cardId, activeFilter]);

  const filteredData = useMemo(() => {
    return transactions || [];
  }, [activeFilter, transactions]);

  const groupedData = useMemo(() => {
    const grouped = filteredData.reduce((acc: TransactionItem[], transaction) => {
      const date = moment(transaction.date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY');

      let group = acc.find((item) => item.date === date);
      if (!group) {
        group = { date, transactions: [] };
        acc.push(group);
      }

      group.transactions.push(transaction);
      return acc;
    }, []);

    return grouped;
  }, [filteredData]);

  const isEmpty = filteredData.length === 0;

  const currentMonthSpendingAmount = useMemo(() => {
    return monthTransactions
      ? monthTransactions
          .filter((t) => t.amount < 0 && t.status === OCEAN_TRANSACTION_STATUS.SUCCESSFUL)
          .reduce((sum, cur) => sum + Math.abs(cur.amount), 0)
      : 0;
  }, [monthTransactions, cardId]);

  const onLoadMore = () => {
    updateData(activeFilter);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, activeFilter]);

  useEffect(() => {
    fetchMonthData();
  }, [cardId]);

  const onChangeFilter = (activeTab: TransactionType) => {
    setActiveFilter(activeTab);
  };

  const onExportPdf = useCallback(async (transactionId?: string) => {
    if (!transactionId || !cardId) return;
    showSuccess && showSuccess(t({id: "river.views.statement.downloading"}))
    setExportLoading(true);
    try {
      showInfo && showInfo(t({id: "screens.exchange.accountterms.label2"}))
      await dispatch(downloadTransaction(cardId, transactionId));
    } catch (err) {
      showError?.('Something happened...');
    } finally {
      setExportLoading(false);
    }
  }, []);

  const onDataSelect = useCallback(
    (transaction: Transaction) => {
      setActiveTransaction(transaction);
    },
    [activeFilter],
  );

  return (
    <>
      <GreyContainer
        title={t({ id: 'transactions.title' })}
        onClick={onOpen}
        className={classes['transactions-block']}>
        <Flex justify="space-between">
          <Typography.Text className={classes.text}>
            {t(
              { id: 'transactions.spent' },
              { month: t({ id: `calendar.month.${new Date().getMonth()}` }) },
            )}
          </Typography.Text>
          {loading ? (
            <ActivityIndicator size="small" />
          ) : (
            <Typography.Text className={classes.balance}>
              {`${currentMonthSpendingAmount.toFixed(2)} €`}
            </Typography.Text>
          )}
        </Flex>
      </GreyContainer>
      <DrawerTransactions
        isOpen={isOpen}
        isEmpty={isEmpty}
        onClose={onClose}
        loading={loading}
        exportLoading={exportLoading}
        canLoadMore={canLoadMore}
        onLoadMore={onLoadMore}
        onExportPdf={onExportPdf}
        activeTransaction={activeTransaction}
        setActiveTransaction={setActiveTransaction}
        onDataSelect={onDataSelect}
        onChangeFilter={onChangeFilter}
        groupedData={groupedData}
        currentMonthSpendingAmount={currentMonthSpendingAmount}
      />
    </>
  );
};

export default Transactions;
