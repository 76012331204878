import {
  BackButton,
  BlackButton,
  CenteredContainer, GridContainer,
} from 'common/components/UI';
import {
  Flex,
  Form,
  Input,
  InputNumber,
  MenuProps, Typography,
} from 'antd';
import { CardDropdown } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'common/store';
import React, { useState } from 'react';
import { CardInfoT } from 'common/types/cards';
import classes from './TopUpByCard.module.scss';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../lib/utils/errorHandler';
import { createBankPayment, getBankPayment } from '../../../store/actions/transfer';
import SelectCard from '../../../components/CardDropdown/SelectCard';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getErrorMessage } from './model/errors';
import { general, generalFields } from "../../../../common/lib/schemas/rules";

const { Title, Text } = Typography;
const { TextArea } = Input;

const TopUpByBank: React.FC = () => {
  const oceanCards = useSelector((state: RootState) => state.user.cards);

  const [form] = Form.useForm();
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { showError, showSuccess } = useMessage();
  const [currentOption, setCurrentOption] = useState<CardInfoT>(
    Object.values(oceanCards)[0],
  );
  const [options, setOptions] = useState<MenuProps['items']>(
    mapCardsToOptions(oceanCards, setCurrentOption),
  );
  const [paymentId, setPaymentId] = useState<number>(0);

  const handleSubmit = (values: { amount: number }) => {
    (async () => {
      try {
        const response = await dispatch(
          createBankPayment(currentOption.id, {
            amount: form.getFieldValue('amount'),
            details: form.getFieldValue('details'),
          }),
        );

        setPaymentId(response.id);
        window.open(response.redirectUrl, '_blank');
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const handleCheckPayment = () => {
    (async () => {
      try {
        const response = await dispatch(getBankPayment(paymentId));

        switch (response.status) {
          case 'SETTLEMENT_REJECTED':
            showError(t({ id: 'sections.load.load.bank.checkPayment.status.declined' }));
            break;
          case 'TIMEOUT':
            showError(t({ id: 'sections.load.load.bank.checkPayment.status.timeout' }));
            break;
          case 'CONSENT_TIMEOUT':
            showError(t({ id: 'sections.load.load.bank.checkPayment.status.consent' }));
            break;
          case 'UNKNOWN' || 'UNEXPECTED_ERROR':
            showError(t({ id: 'sections.load.load.bank.checkPayment.status.unknown' }));
            break;
          default:
            showError(t({ id: 'sections.load.load.bank.checkPayment.notFound' }));
        }

        if (response.status === 'SETTLEMENT_COMPLETE') showSuccess(t({ id: 'sections.load.load.bank.checkPayment.success' }));
      } catch (err) {

        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.load.tabs.bank' })}</Title>
          {paymentId === 0 ? (
            <CardDropdown
              label={`${t({ id: 'components.cardsDropdown.header' })}:`}
              options={options}
              currentOption={currentOption}
            />
          ) : (
            <SelectCard className="card_disabled" currentOption={currentOption} />
          )}
          <Form form={form} onFinish={handleSubmit}>
            <Flex vertical>
              <Flex vertical>
                <Text>{t({ id: 'sections.load.load.bank.inputs.amount.description' })}</Text>
                <Form.Item name="amount" rules={[
                  general(t)['required-field'],
                  ...generalFields(t)['amount']
                ]}>
                  <InputNumber
                    addonBefore="€"
                    disabled={paymentId !== 0}
                    placeholder={t({ id: 'sections.load.load.bank.inputs.amount' })}
                    rootClassName={classes['input']}
                  />
                </Form.Item>
              </Flex>
              <Form.Item
                name="details"
                rules={[{
                  required: true, message: t(
                    { id: 'schemas.presence' },
                    { name: t({ id: 'sections.send.swift.inputs.details' }) },
                  ),
                }]}
                style={{ marginTop: 16 }}>
                <TextArea
                  disabled={paymentId !== 0}
                  placeholder={t({ id: 'sections.load.load.bank.inputs.details' })}
                />
              </Form.Item>
            </Flex>
            <Flex gap={8} style={{ marginTop: 16 }}>
              {paymentId !== 0 ? (
                <BlackButton onClick={handleCheckPayment} type="primary">
                  {t({ id: 'sections.load.load.bank.checkPayment' })}
                </BlackButton>
              ) : (
                <BlackButton htmlType="submit" type="primary">
                  {t({ id: 'sections.load.load.bank.submit' })}
                </BlackButton>
              )}
              <BlackButton onClick={() => navigate(-1)}>
                {t({ id: 'button.simple.cancel' })}
              </BlackButton>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpByBank;
