import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';
import { StopOutlined } from '@ant-design/icons';

export const ClosedOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
      <StopOutlined />
      <Typography.Text className={classes['text']}>
        {t({ id: 'card.universal.status.closed' })}
      </Typography.Text>
    </div>
  );
};
