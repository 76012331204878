import React, { useEffect, useState, FC } from 'react';
import { Carousel, Drawer, Form, Modal, Input, Button, message } from 'antd';
import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import userApi from 'river/api/user';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getSignUpScheme } from 'common/pages/Auth/SignUp/model/scheme';
import { getAccounts } from '../../store/actions/user';
import { AppDispatch } from 'common/store';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddAccountDrawer: FC<Props> = ({ open, onClose }) => {
  const [loader, setLoader] = useState(false)
  const { formatMessage: t } = useIntl();
  const formRules = getSignUpScheme(t);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    account: [],
  });
  const { showError, showSuccess } = useMessage();

  const onFieldsChange = () => {
    setValidationErrors((prevState) => ({
      ...prevState,
      account: form.getFieldError('account'),
    }));
  }; 


  const onAdd = async (account: string) => {
    try {
      setLoader(true)
      const { data: { response } } = await userApi.createAccount(account);
      const { data: { response: accountInfo } } = await userApi.getAccountDetails(response.id);
      dispatch({ type: 'River/ADD_ACCOUNTS', payload: [accountInfo] });
      showSuccess(t({ id: 'river.user.account.createRiverAccount.success' }));
      await dispatch(getAccounts());
      setLoader(false)
      form.resetFields();
      onClose();
    } catch (e) {
      setLoader(false)
      showError(t({ id: 'modals.renew.error.title' }));
    }
  };
 
  return (
    <Drawer
      closeIcon={<CloseOutlined id="btn__email-close" />}
      title={t({ id: 'demo.button.openAccount' })}
      placement="right"
      width={512}
      onClose={onClose}
      open={open}
      style={{zIndex: '99999'}}
      >
      <Form
        onFinish={(e) => onAdd(e.account)}
        onFieldsChange={onFieldsChange}
        form={form}
        validateTrigger="onChange"
        style={{
          width: '100%',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: 16,
          alignItems: 'center',
          marginTop: 50,
        }}>
        <Form.Item style={{ width: '100%' }} rules={formRules.account} name="account">
          <Input
            style={{ marginBottom: 32 }}
            placeholder={t({ id: 'river.views.plan.addAccount' })}
          />
        </Form.Item>
        <Button onClick={form.submit} type="primary" block disabled={loader}>
          {t({ id: 'river.views.plan.addAccount' })}
        </Button>
      </Form>
    </Drawer>
  );
};
