import user from './user';
import card from './card';
import transfer from './transfer';
import template from './template';

export default {
  user,
  card,
  transfer,
  template
};
