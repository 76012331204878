import React, { useRef, useState } from 'react';
import { Button, Divider, Drawer, Flex, Form, Input, Segmented, Typography } from 'antd';
import { hideLoader, showLoader } from 'common/store/actions/app';

import { useDispatch, useSelector } from 'react-redux';
import { signVirtualCard } from 'river/store/actions/user';
import { getCurrentAccount } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './DrawerAddCard.module.css';
import { useIntl } from 'react-intl';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import cardApi from 'river/api/card';
import { general, generalFields } from 'common/lib/schemas/rules';
import FormItem from 'antd/es/form/FormItem';
import { BlackButton, Push } from 'common/components/UI';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';
import { getErrorDefaultMessage } from 'common/lib/utils/helpers';

type VirtualFormProps = { onClose: () => unknown };

const VirtualForm: React.FC<VirtualFormProps> = ({ onClose }) => {
  const { showSuccess, showError } = useMessage();
  const { formatMessage: t } = useIntl();
  const account = useSelector(getCurrentAccount) || 0;
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;
  const shouldSendSms = !isBiometryEnabled && !isTwoFactorEnabled;
  const dispatch: RiverThunkDispatch = useDispatch();
  const operationId = useRef<string>('');

  const [password, setPassword] = useState<string>('');
  const [nextStep, setNextStep] = useState<boolean>(false);

  const [form] = Form.useForm();
  const validationScheme = {
    password: [general(t)['required-field'], ...generalFields(t)['3ds']],
  };

  const onSign = (code: string) => {
    const params = isTwoFactorEnabled ? { google2faCodeFromUser: code } : {};
    const body = isTwoFactorEnabled ? { password } : { code, password };

    dispatch(showLoader());
    dispatch(signVirtualCard(account, operationId.current, body, { params }))
      .then(() => {
        showSuccess?.(t({ id: 'common.message.success' }));
        onClose();
      })
      .catch(() => {
        showError?.(t({ id: 'common.otp.wrong' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onSendCode = () => {
    dispatch(showLoader());
    cardApi
      .createVCApplication(account)
      .then(({ data: { response } }) => {
        const { id } = response;
        operationId.current = String(id);

        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }));
        }

        setNextStep(true);
      })
      .catch(({ response }) => {
        const message = getErrorDefaultMessage(response) || t({ id: 'common.otp.error' });
        showError(message);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onResendCode = () => {
    dispatch(showLoader());
    cardApi
      .resendVCToken(account, operationId.current)
      .then((response) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }));
        }
        setNextStep(true);
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  return (
    <Flex vertical justify="center" align="center" gap={16}>
      <Form form={form} onFinish={onSendCode} validateTrigger="onChange">
        <FormItem rules={validationScheme.password} name="password">
          <Input
            disabled={nextStep}
            placeholder={t({ id: 'river.sheets.createCard.3ds.placeholder' })}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormItem>
        <Typography.Text className={classes.text}>
          {t({ id: 'river.sheets.createCard.3ds.hint' })}
        </Typography.Text>
      </Form>
      <BlackButton
        block
        disabled={nextStep}
        className={classes.button}
        onClick={form.submit}>
        {t({ id: 'screens.card.create.buttons.create' })}
      </BlackButton>
      {nextStep && (
        <>
          <Divider />
          {isBiometryEnabled ? (
            <Push onResend={onResendCode} timeout={60} />
          ) : (
            <>
              <Typography.Text className={classes.text}>
                {t({
                  id: isTwoFactorEnabled
                    ? 'screens.auth.two.factor.legend'
                    : 'screens.payment.create.create.nav.phone.description',
                })}
              </Typography.Text>
              <CodeInput
                timeout={60}
                onResend={isTwoFactorEnabled ? undefined : onResendCode}
                onEnter={onSign}
              />
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default VirtualForm;
