import React from "react";
import { Flex, Modal, Result } from "antd";
import { BlackButton } from "../../../../../common/components/UI";
import { useIntl } from "react-intl";
import { CloseOutlined } from "@ant-design/icons";

type DeleteTemplateProps = {
  open: boolean;
  onClose: () => unknown;
  onSubmit: () => unknown;
}

const DeleteTemplateModal: React.FC<DeleteTemplateProps> = ({open, onClose, onSubmit}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Modal
      closeIcon={<CloseOutlined id='btn__delete-templates-close' />}
      open={open}
      centered
      onCancel={onClose}
      footer={
        <Flex gap={10} justify='center'>
          <BlackButton onClick={onClose} id='btn__delete-template-cancel'>{t({ id: 'button.simple.cancel' })}</BlackButton>
          <BlackButton onClick={onSubmit} id='btn__delete-template-submit' type='primary'>{t({ id: 'button.simple.ok' })}</BlackButton>
        </Flex>
      }
    >
      <Result status='warning' title={t({ id: 'sections.templates.remove.subtitle' })} />
    </Modal>
  )
}

export default DeleteTemplateModal;