import React from 'react';
import classes from './Transactions.module.scss';
import { Button, Divider, Flex, Modal, Tag, Typography } from 'antd';
import { ActivityIndicator, BlackButton, CenteredContainer } from 'common/components/UI';
import TransactionIcon from './components/TransactionIcon';
import { TRANSACTION_STATUS_TAG } from './model/constants';
import { Transaction } from '../../../../types/transfer';
import { useIntl } from 'react-intl';
import { getTypeLabel } from './model/utils';

const { Text, Title } = Typography;

type TransactionDetailsProps = {
  loading: boolean;
  transaction?: Transaction;
  setTransaction: (transaction?: Transaction) => void;
  onExportPdf: (transactionId?: string, language?: string) => unknown;
};

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  loading,
  transaction,
  setTransaction,
  onExportPdf,
}) => {
  const { formatMessage: t } = useIntl();

  const type = transaction?.subType;
  const status = transaction?.status;
  const amount = transaction?.amount;
  const description = transaction?.details;
  const beneficiary = transaction?.beneficiary;
  const date = transaction?.date;
  const id = transaction?.transactionId;

  return (
    <Modal
      centered
      open={transaction !== undefined}
      onCancel={() => setTransaction()}
      className={classes['transaction-modal']}
      styles={{
        footer: {
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 20,
        },
      }}
      footer={() => (
        <BlackButton
          disabled={loading}
          // className={classes['transaction-modal__button']}
          onClick={() => onExportPdf(transaction?.transactionId)}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Typography>{t({ id: 'screens.history.single.buttons.pdf' })}</Typography>
          )}
        </BlackButton>
      )}>
      {id ? (
        <Flex
          vertical
          gap={16}
          justify="center"
          className={classes['transaction-details']}>
          <Text className={classes['transaction-details__header']}>{date}</Text>
          <Flex vertical className={classes['transaction-details__body']}>
            <Flex vertical align="center" gap={8}>
              <TransactionIcon size={48} type={type} status={status} />
              <Flex vertical gap={4}>
                <Title level={4} style={{ textAlign: 'center' }}>
                  {amount && amount < 0 ? amount : '+' + amount} €
                </Title>
                <Text style={{ fontSize: 12 }}>{description}</Text>
              </Flex>
            </Flex>
            <Divider style={{ margin: '20px 0 8px' }} />
            <Flex vertical gap={4}>
              {beneficiary && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'screens.history.single.transaction.beneficiary' })}
                  </Text>
                  <Text className={classes['transaction-details__text']}>
                    {beneficiary}
                  </Text>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {type && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'screens.history.single.transaction.type' })}
                  </Text>
                  <Text>{getTypeLabel(t, type)}</Text>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {status && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'screens.history.single.transaction.status' })}
                  </Text>
                  <Tag
                    style={{ width: 'min-content' }}
                    color={TRANSACTION_STATUS_TAG[status].color}>
                    {t({ id: TRANSACTION_STATUS_TAG[status].title })}
                  </Tag>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <CenteredContainer>
          <ActivityIndicator size="large" />
        </CenteredContainer>
      )}
    </Modal>
  );
};

export default TransactionDetails;
