import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';
import { ClockCircleOutlined } from '@ant-design/icons';

export const ApplicationOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
      <ClockCircleOutlined />
      <Typography.Text className={classes['text']}>{t({id: 'card.universal.status.application'})}</Typography.Text>
    </div>
  );
};
