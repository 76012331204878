import { Button, Drawer, Flex, Typography } from 'antd';
import React from 'react';
import classnames from './DrawerPlanDetails.module.css';

type DrawerPlanDetailsProps = {
  isOpen: boolean;
  title: string;
  info: string[];
  bgColor: string;
  onClose: () => unknown;
  onOpen: () => unknown;
};

const { Title } = Typography;

const DrawerPlanDetails: React.FC<DrawerPlanDetailsProps> = ({ isOpen, title, info, bgColor, onOpen, onClose }) => {

  return (
    <Drawer width={560} style={{backgroundColor: bgColor}} open={isOpen} onClose={onClose}>
      <Flex justify="space-between" className={classnames['title-wrapper']}>
          <Title style={{ fontStyle: 'italic' }}>{title}</Title>
        </Flex>
        <ul className={classnames['plan-info-list']}>
          {info.map((infoItem, index) => (
            <li key={index}>{infoItem}</li>
          ))}
        </ul>
        <Button className={classnames.button} block onClick={onOpen}>
          Open
        </Button>
    </Drawer>
  );
};

export default DrawerPlanDetails;
