import userApi from 'common/api/user';
import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from 'common/store/index';
import { getCardInfo } from 'common/store/actions/cards';
import { batch } from 'react-redux';
import { UserT } from 'common/types/user';
import { CardInfoT } from 'common/types/cards';
import { Dispatch } from 'react';
import { ValidationMethod } from 'common/types/validation';
import { setLocale } from './language';

type GetUserFullInfoResponse = {
  user: UserT;
  cards: CardInfoT[];
};
export const getUserFullInfo =
  (): ThunkAction<Promise<GetUserFullInfoResponse>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const [userInfoResponse, userResponse] = await Promise.all([
        userApi.info.getUserInfo(),
        userApi.info.getUser(),
      ]);
      const {
        data: { response: getUserInfoResponse },
      } = userInfoResponse;
      const {
        data: { response: getUserResponse },
      } = userResponse;
      if(getUserResponse.language){
        dispatch(setLocale(getUserResponse.language));
      }
      const userData = { ...getUserResponse, ...getUserInfoResponse },
        cardholderIds = getUserInfoResponse.cardholderIds || [],
        cards = await dispatch(getCardInfo(cardholderIds.map((cId: any) => cId.id)));

      batch(() => {
        dispatch({ type: 'User/DANGEROUS_SET', payload: { meta: userData } });
        dispatch({ type: 'User/SET_CARDS', payload: cards });
      });
      return { user: userData, cards };
    } catch (e) {
      throw e;
    }
  };


export const appendUserMeta = (partialMeta: Partial<UserT>) => ({
  type: 'User/APPEND_META',
  payload: partialMeta,
});

export const setLoginValidation = (method: ValidationMethod) => ({
  type: 'User/VALIDATION/LOGIN/SET',
  payload: method,
});

export const setConfirmationValidation = (method: ValidationMethod) => ({
  type: 'User/VALIDATION/CONFIRMATION/SET',
  payload: method,
});

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: 'User/LOG_OUT' });
};

export const postUserAvatar =
  (formData: FormData): ThunkAction<Promise<any>, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      try {
        const res = await userApi.settings.setAvatar(formData);
        return res;
      } catch (e) {
        console.error(e);
      }
    };

export const deleteUserAvatar =
  (): ThunkAction<Promise<any>, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      try {
        const res = await userApi.settings.deleteAvatar();
        dispatch({type: 'User/SET_AVATAR', payload: undefined})

        return res;
      } catch (e) {
        console.error(e);
      }
    };

export const getUserAvatar =
  (): ThunkAction<Promise<any>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await userApi.settings.getAvatar();

      let url;
      if (res.status === 200 && res.data) {
        url = window.URL.createObjectURL(new Blob([res.data]));
      }
      dispatch({type: 'User/SET_AVATAR', payload: url})

      return url;
    } catch (e) {
      console.log(e);
    }
};