import React from 'react';
import { Layout, theme as antdTheme } from 'antd';
import classes from './AppLayout.module.scss';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import AuthHeader from '../AuthHeader/AuthHeader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const NonAuthLayout = () => {
  const {
    token: { colorBgBase },
  } = antdTheme.useToken();

  return (
    <Layout className={classes.container} style={{ backgroundColor: colorBgBase }}>
      <AuthHeader />
      <Layout.Content className={classes.contentContainer}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default NonAuthLayout;
