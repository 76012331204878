import React, {FC} from 'react';
import classes from './EmailResult.module.scss';
import { Button, message, Progress, Typography, Flex } from 'antd';
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';
import CustomCardContainer from 'common/components/UI/CustomCardContainer/CustomCardContainer';
import { useIntl } from 'react-intl';

type Props = {
    email: string
}

export const EmailNotFound:FC<Props> = ({email}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Flex flex={1} justify="center" align="center">
      <CustomCardContainer className={classes.card}>
        <ExclamationIcon className={classes['icon-error']} />
        <Typography.Text className={classes.text}>
          E-mail: <strong>{email}</strong> {t({id: "screens.auth.email.messages.not_found"})}
        </Typography.Text>
        <Typography.Text className={classes.desc}>
          {t({id: "screens.auth.email.messages.contact_support"})}
        </Typography.Text>
        <Button
          id="btn__contact_support"
          href="mailto:cardservices@weststeincard.com"
          type="primary"
          block>
          {t({ id: 'river.sheets.accountSoftBlocked.button' })}
        </Button>
      </CustomCardContainer>
    </Flex>
  );
};
