import {theme as antdTheme, ThemeConfig} from "antd";
import {THEME} from "common/lib/constants/constants";

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  PRIMARY: "#1aaee5",
  SUCCESS: "#1ec624",
  ERROR: "#ff2121",
  INFO: "#1aaee5",
  WARNING: '#ffb140'
} as const;

interface ThemeContainer {
  [THEME.LIGHT]: ThemeConfig;
  [THEME.DARK]: ThemeConfig;
}
export const theme: Readonly<ThemeContainer> = {
  [THEME.LIGHT]: {
    token: {
      colorPrimary: COLORS.PRIMARY,
      colorSuccess: COLORS.SUCCESS,
      colorError: COLORS.ERROR,
      colorInfo: COLORS.PRIMARY,
      fontSize: 16,
      sizeStep: 4,
      sizeUnit: 4,
      borderRadius: 16,
      borderRadiusSM: 14,
      borderRadiusXS: 12,
      wireframe: false,
      boxShadow: "none",
      boxShadowSecondary: "none",
    },
    components: {
      Layout: {
        headerBg: COLORS.WHITE,
        bodyBg: COLORS.WHITE,
        footerBg: COLORS.WHITE,
        footerPadding: "24px 60px",
        headerColor: COLORS.BLACK,
        headerHeight: 66,
        headerPadding: "16px 60px",
      }, 
      Progress: {
        circleTextColor: "rgba(0, 0, 0, 0.88)",
        defaultColor: "rgb(26, 174, 229)",
        remainingColor: "rgba(0, 0, 0, 0.05)",
        colorText: "rgba(0, 0, 0, 0.88)",
        fontFamily: "Inter, sans-serif"
      },
      Input: {
        activeBg: "rgb(255, 255, 255)",
        addonBg: "transparent",
        activeBorderColor: "rgb(26, 174, 229)",
        hoverBg: "rgb(255, 255, 255)",
        hoverBorderColor: "rgb(26, 174, 229)",
        colorBgContainer: "rgb(255, 255, 255)",
        colorBorder: "rgba(0, 0, 0, 0.1)",
        colorText: "rgb(0, 0, 0)",
        colorTextPlaceholder: "rgba(0, 0, 0, 0.6)",
        colorTextDescription: "rgba(0, 0, 0, 0.45)",
        colorTextDisabled: "rgba(0, 0, 0, 0.25)",
        paddingBlock: 16,
        paddingInline: 16,
        paddingBlockLG: 18,
        paddingInlineLG: 18,
        paddingInlineSM: 12,
        paddingBlockSM: 12,
        inputFontSize: 14,
        inputFontSizeLG: 16,
        inputFontSizeSM: 14,
        fontSize: 16,
        fontSizeIcon: 14,
        activeShadow: "none",
        errorActiveShadow: "none",
        warningActiveShadow: "none",
        borderRadiusSM: 16,
        fontFamily: "Inter, sans-serif"
      },
      Select: {
        fontFamily: "Inter, sans-serif",
        borderRadiusLG: 16,
        borderRadius: 16,
        zIndexPopup: 1050,
        optionSelectedFontWeight: 400,
        optionSelectedBg: "rgba(0, 0, 0, 0.05)",
        optionSelectedColor: "rgb(0, 0, 0)",
        selectorBg: "rgb(255, 255, 255)",
        colorBgElevated: "rgb(255, 255, 255)",
        colorText: "rgb(0, 0, 0)",
        colorTextPlaceholder: "rgba(0, 0, 0, 0.6)",
        colorTextDescription: "rgba(0, 0, 0, 0.45)",
        colorTextDisabled: "rgba(0, 0, 0, 0.25)",
        colorBorder: "rgba(0, 0, 0, 0.1)",
        fontSizeIcon: 14,
        fontSizeSM: 14,
        optionFontSize: 16,
        fontSize: 16,
        fontSizeLG: 16,
        controlOutlineWidth: 0,
        algorithm: true,
        controlHeight: 58,
        controlHeightLG: 58,
        controlPaddingHorizontal: 16,
        controlPaddingHorizontalSM: 12,
        paddingSM: 16,
        paddingXXS: 0,
        borderRadiusXS: 0,
        borderRadiusSM: 0
      },
      InputNumber: {
        activeBorderColor: "rgb(26, 174, 229)",
        addonBg: "transparent",
        handleActiveBg: "rgba(0, 0, 0, 0.02)",
        handleBg: "rgb(255, 255, 255)",
        handleHoverColor: "rgb(26, 174, 229)",
        hoverBg: "rgb(255, 255, 255)",
        hoverBorderColor: "rgb(26, 174, 229)",
        colorBgContainer: "rgb(255, 255, 255)",
        colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
        handleBorderColor: "rgba(0, 0, 0, 0.04)",
        colorBorder: "rgba(0, 0, 0, 0.04)",
        colorText: "rgb(0, 0, 0)",
        activeShadow: "none",
        errorActiveShadow: "none",
        warningActiveShadow: "none",
        borderRadiusSM: 16,
        colorTextPlaceholder: "rgba(0, 0, 0, 0.6)",
        colorTextDescription: "rgba(0, 0, 0, 0.45)",
        colorTextDisabled: "rgba(0, 0, 0, 0.25)",
        paddingBlock: 16,
        paddingInline: 16,
        paddingBlockLG: 18,
        paddingInlineLG: 18,
        paddingInlineSM: 12,
        paddingBlockSM: 12,
        fontSize: 16,
        fontFamily: "Inter, sans-serif",
      },
      Button: {
        fontFamily: "Inter, sans-serif",
        dangerColor: "rgb(255, 255, 255)",
        defaultGhostBorderColor: "rgb(26, 174, 229)",
        defaultGhostColor: "rgb(26, 174, 229)",
        defaultBorderColor: "rgb(26, 174, 229)",
        defaultColor: "rgb(26, 174, 229)",
        ghostBg: "transparent",
        primaryColor: "rgb(255, 255, 255)",
        colorBgContainer: "rgb(255, 255, 255)",
        colorBgContainerDisabled: "rgba(26, 174, 229, 0.5)",
        borderColorDisabled: "transparent",
        colorBgTextActive: "rgba(26, 174, 229, 0.15)",
        colorTextDisabled: "rgb(255, 255, 255)",
        onlyIconSize: 14,
        onlyIconSizeLG: 16,
        contentFontSize: 14,
        contentFontSizeLG: 16,
        contentFontSizeSM: 14,
        onlyIconSizeSM: 14,
        paddingBlock: 12,
        paddingInline: 32,
        paddingBlockSM: 5,
        paddingInlineSM: 10,
        paddingBlockLG: 13,
        paddingInlineLG: 32,
        // controlHeight: "auto",
        // controlHeightLG: "auto",
        // controlHeightSM: "auto",
        fontSize: 16,
        fontSizeSM: 12,
        lineWidth: 1,
        dangerShadow: "none",
        defaultShadow: "none",
        primaryShadow: "none",
        fontWeight: 600,
        borderRadiusSM: 16,
        borderRadius: 16,
        borderRadiusLG: 16
      },
      Form: {
        itemMarginBottom: 16
      },
      Typography: {
        colorText: "rgb(0, 0, 0)",
        colorTextHeading: "rgba(0, 0, 0, 0.88)",
        titleMarginBottom: "8",
        titleMarginTop: "0",
        fontSizeHeading1: 40,
        fontSizeHeading2: 32,
        fontFamilyCode: "Inter, sans-serif",
        fontFamily: "Inter, sans-serif",
        fontWeightStrong: 800,
        fontSize: 16,
      },
      Modal: {
        borderRadiusLG: 16,
        fontSize: 16,
        padding: 24,
      },
      Checkbox: {
        colorBorder: "rgba(0, 0, 0, 0.1)",
        colorBgContainer: "rgb(255, 255, 255)",
        colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
        colorPrimary: "rgb(51, 51, 51)",
        colorText: "rgb(0, 0, 0)",
        controlInteractiveSize: 32,
        fontSize: 16,
        borderRadiusSM: 16,
        fontFamily: "Inter, sans-serif",
      },
      Segmented: {
        trackBg: 'transparent',
        itemSelectedBg: 'black',
        itemSelectedColor: 'white',
        itemColor: 'black',
      }
    },
    algorithm: antdTheme.defaultAlgorithm,
  },
  [THEME.DARK]: {
    token: {
      colorPrimary: COLORS.PRIMARY,
      colorSuccess: COLORS.SUCCESS,
      colorError: COLORS.ERROR,
      colorInfo: COLORS.PRIMARY,
      wireframe: false,
      motion: false,
      borderRadius: 16,
      boxShadow: "none",
      boxShadowSecondary: "none",
      borderRadiusSM: 14,
      borderRadiusXS: 12,
    },
    components: {
      Layout: {
        headerBg: COLORS.BLACK,
        bodyBg: COLORS.BLACK,
        footerBg: COLORS.BLACK,
        footerPadding: "24px 60px",
        headerColor: COLORS.BLACK,
        headerHeight: 66,
        headerPadding: "16px 60px",
      },
      Input: {
        borderRadius: 16,
        activeShadow: "",
        errorActiveShadow: "",
        warningActiveShadow: "",
        fontSize: 16,
        paddingInline: 21,
        paddingBlock: 16,
        fontSizeIcon: 14
      }
    },
    algorithm: antdTheme.darkAlgorithm,
  }
} as const;
