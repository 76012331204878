import { ThunkAction } from 'redux-thunk';
import oceanCardsApi from 'ocean/api/cards';
import { RootState } from 'common/store/index';
import { AnyAction } from 'redux';
import { CardInfoT } from 'common/types/cards';

export const getCardInfo = (cardsIds: number[] | number): ThunkAction<Promise<CardInfoT[]>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const cardsIdsArray = Array.isArray(cardsIds) ? cardsIds : [cardsIds];
      if (cardsIdsArray.length > 0) {
        const cardInfoPromises = cardsIdsArray.map(async (cardId) => {
          const { data: { response } } = await oceanCardsApi.getCardInfo(cardId);
          return response;
        });
        const cardInfoResults = await Promise.all(cardInfoPromises);
        return cardInfoResults;
      } else return [];
    } catch (e) {
      throw e;
    }
  };