import { Flex, Form, FormInstance, Input, InputNumber, Select, Typography } from 'antd';
import classes from '../InternationalPayment.module.scss';
import React from 'react';
import { getSendInternationalPaymentScheme } from '../model/scheme';
import { InternationalForm } from '../model/types';
import SelectCard from '../../../../components/CardDropdown/SelectCard';
import { CardInfoT } from 'common/types/cards';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;
const { TextArea } = Input;

type AmountProps = {
  currentFrom: CardInfoT;
  onCancel: () => void;
  fee: number;
  formValues: Partial<InternationalForm>;
  form: FormInstance;
}

const Amount: React.FC<AmountProps> = ({
  currentFrom,
  onCancel,
  fee,
  formValues,
  form,
}) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendInternationalPaymentScheme(t);

  return (
    <Flex vertical gap={32}>
      <Title level={3}>{t({ id: 'screens.internationalPayment.create.title' })}</Title>
      <Flex vertical gap={24}>
        <SelectCard currentOption={currentFrom} className="card_disabled" />
        <Form initialValues={formValues} form={form} name="amount">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Flex vertical>
              <Form.Item name="beneficiary" rules={rules.beneficiary}>
                <Input
                  size="large"
                  disabled
                />
              </Form.Item>
              <Form.Item name="lastname" rules={rules.lastname}>
                <Input
                  size="large"
                  disabled
                />
              </Form.Item>
              <Form.Item name="iban" rules={rules.iban}>
                <Input
                  size="large"
                  disabled
                />
              </Form.Item>
              <Form.Item name="bic" rules={rules.bic}>
                <Input
                  size="large"
                  disabled
                />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography>{t({ id: 'inputs.address' })}:</Typography>
              <Flex vertical>
                <Form.Item name="creditorCountryCode">
                  <Select
                    defaultValue={form.getFieldValue('country')}
                    disabled
                  />
                </Form.Item>
                <Form.Item name="city">
                  <Input
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="postCode">
                  <Input
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="address1">
                  <Input
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="address2">
                  <Input
                    size="large"
                    disabled
                  />
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>
          <Flex vertical gap={8}>
            <Typography>{t({ id: 'sections.send.swift.inputs.amount.description' })}</Typography>
            <Flex vertical>
              <Form.Item name="amount">
                <InputNumber
                  disabled
                  rootClassName={classes['input']}
                  addonAfter={
                    <Form.Item name="currency" style={{ margin: 0, width: 300 }}>
                      <Select
                        disabled
                        size="large"
                        defaultValue={form.getFieldValue('currency')}
                      />
                    </Form.Item>
                  }
                />
              </Form.Item>
              <Text style={{ fontSize: 14 }}>
                {t({id: 'screens.payment.create.beneficiary.inputs.amount.info'}, {amount: (form.getFieldValue('amount') ?? 0) + fee, fee: fee})}
              </Text>
            </Flex>
          </Flex>
          <Flex vertical gap={8} style={{ marginTop: 16 }}>
            <Text>{t({ id: 'sections.send.swift.inputs.confirmAmount' })}:</Text>
            <Form.Item name="confirmAmount" rules={rules.confirmAmount}>
              <InputNumber
                placeholder={t({ id: 'screens.internationalPayment.inputs.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
          </Flex>
          <Form.Item name="details" rootClassName={classes['description']}>
            <TextArea
              disabled
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Amount;