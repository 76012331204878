export const patterns = {
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  onlyNumbers: /^[0-9]+$/,
  numbersAndLetters: /^[A-Za-z0-9]+$/,
  phoneNumber: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, // Международный формат
  username: /^[A-Za-z0-9_]{3,15}$/, // Буквы, цифры и подчеркивания, от 3 до 15 символов
  password: {
    weak: /^.{6,}$/, // Минимум 6 символов
    medium: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, // Буквы, хотя бы одна заглавная, одна цифра, минимум 8 символов
    strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/, // Буквы, цифры, спецсимволы, минимум 10 символов
  },
  url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, // URL
  hexColor: /^#?([a-f0-9]{6}|[a-f0-9]{3})$/, // HEX цвет
  postalCode: /^[0-9]{5}(?:-[0-9]{4})?$/, // Почтовый индекс (US)
  ipV4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, // IP адрес v4
  ipV6: /^([0-9a-f]{1,4}:){7}[0-9a-f]{1,4}$/i, // IP адрес v6
  creditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/, // Номер кредитной карты
  ssn: /^\d{3}-\d{2}-\d{4}$/, // Социальный номер (SSN)
};