import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.2026 14.5C2.98701 14.5 2 13.5 2 12.2553C2 11.0106 2.98701 10 4.2026 10C5.41818 10 6.39481 11.0106 6.39481 12.2553C6.39481 13.5 5.41818 14.5 4.2026 14.5ZM11.9948 14.5C10.7896 14.5 9.8026 13.5 9.8026 12.2553C9.8026 11.0106 10.7896 10 11.9948 10C13.2104 10 14.1974 11.0106 14.1974 12.2553C14.1974 13.5 13.2104 14.5 11.9948 14.5ZM19.7974 14.5C18.5818 14.5 17.6052 13.5 17.6052 12.2553C17.6052 11.0106 18.5818 10 19.7974 10C21.013 10 22 11.0106 22 12.2553C22 13.5 21.013 14.5 19.7974 14.5Z"
      fill="black"
    />
  </svg>
);

const Other = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Other;
