import { APP, FILE_FORMAT } from '../../../common/lib/constants/constants';
import { CardStatus } from '../../types/cards';

export const PLAN_NAME = APP.NAME;

export const ENDPOINTS = {
  USER_CTRL: {
    VERIFICATION: {
      GET_STATUS: 'jumio/getVerificationStatus',
      START: 'user/start-kyc?isWeb=true',
    },
    HMAC: {
      DELETE: 'user/hmac/delete', // delete 1
      DISABLE_NOTIFICATIONS: 'user/hmac/notification-disable', // post 1
      SIGN: 'user/hmac/sign', // post !
      UPDATE: 'user/hmac/update', // put !
    }
  },
  TRANSFER_CTRL: {
    TRANS_TO_CARD: (cardId: number) => `private/card-info/${cardId}/trans-to-card`,
    STATEMENT: {
      REQUEST: (cardId: number) => `private/view-statement/${cardId}`,
      DOWNLOAD: (cardId: number, format: 'pdf' | 'csv' = FILE_FORMAT.PDF) => `private/view-statement/${format}/${cardId}`,
      DOWNLOAD_SINGLE: (cardId: number, transactionId: string) => `private/view-statement/pdf/${cardId}/${transactionId}`,
    },
    SEPA: {
      LIST: (cardId: number) => `private/payment/${cardId}/sepa`,
      CREATE: (cardId: number) => `private/payment/${cardId}/sepa/create`,
      CONFIRM: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/confirm/${paymentId}`,
      SIGN: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/sign/${paymentId}`,
      RESEND_CODE: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/${paymentId}/resend-token`,
      REJECT: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/reject/${paymentId}`,
      DELETE: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/${paymentId}`,
      DOWNLOAD: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/pdf/${paymentId}`,
      INFO: (cardId: number, paymentId: number) => `private/payment/${cardId}/sepa/${paymentId}`
    },
    C2C: {
      LIST: (cardId: number) => `private/payment/${cardId}/card-to-card`,
      CREATE: (cardId: number) => `private/payment/${cardId}/card-to-card/create`,
      CONFIRM: (cardId: number, paymentId: number) => `private/payment/${cardId}/card-to-card/${paymentId}/confirm`,
      SIGN: (cardId: number, paymentId: number) => `private/payment/${cardId}/card-to-card/${paymentId}/sign`,
      RESEND_CODE: (cardId: number, paymentId: number) => `private/payment/${cardId}/card-to-card/${paymentId}/resend-token`,
      DELETE: (cardId: number, paymentId: number) => `private/payment/${cardId}/card-to-card/${paymentId}`,
      INFO: (cardId: number, paymentId: number) => `private/payment/${cardId}/card-to-card/${paymentId}`
    },
    SWIFT: {
      LIST: (cardId: number) => `private/payment/${cardId}/international`,
      CREATE: (cardId: number) => `private/payment/${cardId}/international/create`,
      CONFIRM: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/confirm/${paymentId}`,
      SIGN: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/sign/${paymentId}`,
      RESEND_CODE: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/${paymentId}/resend-token`,
      REJECT: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/reject/${paymentId}`,
      DELETE: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/${paymentId}`,
      INFO: (cardId: number, paymentId: number) => `private/payment/${cardId}/international/${paymentId}`
    },
    INVOICE: {
      DOWNLOAD: (cardId: number) => `private/invoice/${cardId}/sepa/pdf`,
    },
    OPEN_BANKING: {
      INFO: (paymentId: number) => `openbanking/info/${paymentId}`,
      CREATE: (cardId: number) => `openbanking/${cardId}/create`,
      LIST: 'openbanking/list',
    },
    VELURE: {
      CREATE: 'private/topup/velure',
      // CONFIRM: (paymentId: number) => `private/topup/velure/${paymentId}`,
    },
  },
  CARDS_CTRL: {
    INFO: (cardId: number) => `private/card-info/${cardId}`,
    GET_PIN: (cardId: number | string) => `private/card-info/${cardId}/pin-reminder`,
    REISSUE: (cardId: number) => `private/card-reissue/${cardId}`,
    BLOCK: (cardId: string | number) => `private/card-info/${cardId}/block`,
    UNBLOCK: (cardId: string | number) => `private/card-info/${cardId}/un-block`,
    GET_CARD_NUMBER: (cardId: number) => `private/card-info/${cardId}/number`,
    REQUEST_PLASTIC: (cardId: number) => `private/card-request/${cardId}/plastic`,
    SEND_CVV: (cardId: number | string) => `private/card-info/${cardId}/cvv`,
    GET_EXP_DATE: (cardId: number) => `private/card-info/${cardId}/exp-date`,
    REQUEST_DRAFT: 'private/create-regulatory',
    REQUEST_PRIMARY: 'private/card-request',
    REQUEST_SELF_SECONDARY: (cardId: number) => `private/card-request/${cardId}/secondary`,
    REQUEST_OTHER_SECONDARY: (cardId: number) => `private/card-request/${cardId}/secondary/other`,
    GET_CARD_LIMIT: (cardId: number) => `private/card-info/${cardId}/limit`,
  }
}

export const CARD_STATUS = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  ISSUED: 'ISSUED',
  DEPOSIT_ONLY: 'DEPOSIT_ONLY',
} as const;

export const CARD_DELIVERY_TYPE = {
  VC: 'VC',
  PC: 'PC'
} as const;

export const BLOCKING = {
  PRODUCT: {
    NO_PAYMENTS: ['CARD_04', 'CARD_05', 'CARD_06'],
    NO_TOPUP: ['CARD_01', 'CARD_02', 'CARD_03'],
    VERIFIED: ['CARD_01', 'CARD_02', 'CARD_03', 'CARD_07', 'CARD_08', 'CARD_14', 'CARD_15'],
  },
  STATUS: {
    ACTIVE_CARDS: [CARD_STATUS.OPEN, CARD_STATUS.ISSUED, CARD_STATUS.DEPOSIT_ONLY] as Partial<CardStatus>[],
    INACTIVE_CARDS: [CARD_STATUS.CLOSED] as Partial<CardStatus>[],
    DEPOSIT_ONLY: CARD_STATUS.DEPOSIT_ONLY,
  },
} as const;

export const VERIFICATION_STATUS = {
  NOT_IN_PROGRESS: 'not_in_process',
  IN_PROGRESS: 'in_process',
  SUCCESS: 'success',
  FAILED: 'failed',
} as const;

export const OCEAN_TRANSACTION_TYPE = {
  CARD_RECHARGE: 'CARD_RECHARGE',
  MONTHLY_CHARGE: 'MONTHLY_CHARGE',
  SEPA_INCOMING: 'SEPA_INCOMING',
  SEPA_INCOMING_RETURN: 'SEPA_INCOMING_RETURN',
  SEPA_INCOMING_FEE: 'SEPA_INCOMING_FEE',
  SEPA_OUTGOING: 'SEPA_OUTGOING',
  SEPA_OUTGOING_FEE: 'SEPA_OUTGOING_FEE',
  SMS_PIN_RETRIEVAL_FEE: 'SMS_PIN_RETRIEVAL_FEE',
  INSTANT_SEPA_INCOMING_PAYMENT: 'INSTANT_SEPA_INCOMING_PAYMENT',
  PURCHASE: 'PURCHASE',
  UNKNOWN: 'UNKNOWN',
} as const;

export const OCEAN_TRANSACTION_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  DECLINED: 'DECLINED',
} as const;

