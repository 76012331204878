import { GridContainer } from 'common/components/UI';
import { Tabs } from 'antd';
import Profile from './Profile/Profile';
import Settings from './Settings';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { useIntl } from 'react-intl';
import classes from './ProfileMain.module.css';

export default function ProfilePage() {
  const { showError, showSuccess } = useMessage();
  const { formatMessage: t } = useIntl();

  return (
    <GridContainer>
      <Tabs
        className={classes.wrapper}
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: t({ id: 'profile.tabs.profile' }),
            children: <Profile showError={showError} showSuccess={showSuccess} />,
          },
          {
            key: '2',
            label: t({ id: 'profile.tabs.settings' }),
            children: <Settings showError={showError} showSuccess={showSuccess} />,
          },
        ]}
        onChange={() => {}}
      />
    </GridContainer>
  );
}
