import React from 'react';
import Option from '../Option';
import { Card } from '../../../types';

export const mapCardsToOptions = (
  cards: Card[],
  onChange: (card: Card) => void,
  optionId?: string
) => {
  if (cards.length === 0) return [];

  const fromOptions = cards.map((card: Card, idx: number) => ({
    key: idx,
    label: (
      <Option
        id={(optionId ? optionId : 'card') + '-' + idx}
        onChange={() => onChange(card)}
        option={card}
      />
    ),
  }));

  return fromOptions;
};