import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';
import { LockFilled } from '@ant-design/icons';

export const BlockedOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
    <LockFilled />
    <Typography.Text className={classes['text']}>{t({id: 'card.universal.status.blocked'})}</Typography.Text>
  </div>
  );
};
