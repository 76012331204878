import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Flex, Space, Switch } from 'antd';
import { CenteredContainer } from 'common/components/UI';
import classes from './Settings.module.css';
import { useIntl } from 'react-intl';

const { Text } = Typography;

type ListItemProps = {
  iconComponent?: JSX.Element;
  title: string;
  withBorder?: boolean;
  switcher?: boolean;
  switcherValue?: boolean;
  subtitle?: string;
  verified?: boolean;
  onClick?: VoidFunction;
};

const ListItem = ({
  title,
  subtitle,
  switcher,
  switcherValue,
  verified = false,
  withBorder = false,
  iconComponent,
  onClick,
}: ListItemProps) => {
  const { formatMessage: t } = useIntl();
  return (
    <Row gutter={16} className={classes['listItemWrapper']} onClick={onClick}>
      {iconComponent && (
        <Col span={4}>
          <CenteredContainer>{iconComponent}</CenteredContainer>
        </Col>
      )}
      <Col span={iconComponent ? 16 : 20}>
        <Flex vertical justify="center" style={{ height: '100%' }}>
          <Text style={{ fontSize: 18, fontWeight: 600, lineHeight: '26px' }}>
            {title}
            {verified && (
                <Text className={classes['verified']} style={{ marginLeft: 8 }}>
                {t({ id: 'profile.settings.verifed' })}
              </Text>
              )}
          </Text>
          {subtitle && (
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#00000099',
                }}>
                {subtitle}
              </Text>  
          )}
        </Flex>
      </Col>
      <Col span={4}>
        <CenteredContainer>
          {switcher ? (
            <Switch
              value={switcherValue}
              onChange={(value, event) => {
                event.preventDefault();
              }}
            />
          ) : (
            <RightOutlined
              style={{
                fontSize: 24,
                color: '#00000066',
              }}
            />
          )}
        </CenteredContainer>
      </Col>
    </Row>
  );
};

export default ListItem;
