import { BackButton, BlackButton, CenteredContainer, GridContainer } from 'common/components/UI';
import { Flex, Form, InputNumber, MenuProps, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import classes from './TopUpInvoice.module.scss';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { general, generalFields } from '../../../../common/lib/schemas/rules';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import CardDropdown from '../../../components/CardDropdown/CardDropdown';
import { getCards, getCurrentCardObject } from '../../../store/selectors';
import { downloadInvoice } from '../../../store/actions/transfer';
import { CardInfoT } from '../../../../common/types/cards';
import { AppDispatch } from "../../../../common/store";

const { Title, Text } = Typography;

const TopUpInvoice: React.FC = () => {
  const cards = useSelector(getCards);

  const currentCard = useSelector(getCurrentCardObject);

  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const {showError, showSuccess} = useMessage();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<Partial<{amount: number}>>()

  const [currentOption, setCurrentOption] = useState<CardInfoT>(currentCard ?? cards[0]);
  const [options, setOptions] = useState<MenuProps['items']>(mapCardsToOptions(cards, setCurrentOption));

  const onFinish = async (values: {amount: number}) => {
    try {
      await dispatch(downloadInvoice(currentOption.id, values.amount));
      showSuccess(t({id: 'common.message.success'}));
    } catch (err: any) {
      showError(t({id: 'common.message.error'}));
    }
  }

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.invoice.title' })}</Title>
          <CardDropdown
            label={`${t({ id: 'components.cardsDropdown.header' })}:`}
            options={options}
            currentOption={currentOption}
          />
          <Text>{t({ id: 'sections.load.invoice.text' })}</Text>
          <Form form={form} initialValues={formData} onFinish={onFinish}>
            <Form.Item name='amount' rules={[
              general(t)['required-field'],
              ...generalFields(t)['amount']
            ]}>
              <InputNumber
                placeholder={t({id: 'noty.card.amount'})}
              />
            </Form.Item>
            <BlackButton type='primary' htmlType='submit'>
              {t({ id: 'sections.load.invoice.btn' })}
            </BlackButton>
          </Form>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpInvoice;