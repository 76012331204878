import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { List } from 'antd';

import blockClasses from './ManageCard.module.css';
import { useIntl } from 'react-intl';
import { RoundContainer } from "../../../../../common/components/UI";

type ManageCardProps = {
  isVirtual: boolean;
  onGetPIN: () => unknown;
  onSendCVV: () => unknown;
};

const ManageCard: React.FC<ManageCardProps> = ({ isVirtual, onGetPIN, onSendCVV }) => {
  const { formatMessage: t } = useIntl();

  return (
    <List>
     {!isVirtual && <List.Item
        className={blockClasses['list-item']}
        actions={[
          <RoundContainer
            size={32}
            key="change-pin-action"
            onClick={onGetPIN}
            bgColor='transparent'
            pointer
          >
            <RightOutlined
              style={{ fontSize: 16, color: 'rgba(0,0,0,0.6)' }}
            />
          </RoundContainer>
        ]}>
        <List.Item.Meta title={t({ id: 'card.manage.getPIN' })} />
      </List.Item>}
      {isVirtual && (
        <List.Item
          className={blockClasses['list-item']}
          actions={[
            <RoundContainer
              size={32}
              key="change-cvv-action"
              onClick={onSendCVV}
              bgColor='transparent'
              pointer
            >
              <RightOutlined
                style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.45)' }}
              />
            </RoundContainer>
          ]}>
          <List.Item.Meta title={t({ id: 'card.manage.getCVV' })} />
        </List.Item>
      )}
    </List>
  );
};

export default ManageCard;
