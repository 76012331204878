import { Flex, Form, FormInstance, Input, InputNumber, MenuProps, Select, Typography } from 'antd';
import classes from '../InternationalPayment.module.scss';
import React from 'react';
import { getSendInternationalPaymentScheme } from '../model/scheme';
import { InternationalForm } from '../model/types';
import { CardDropdown } from '../../../../components';
import { CardInfoT } from 'common/types/cards';
import { Country, Currency } from 'common/types/app';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';
import currenciesImg from 'common/lib/constants/currencies';

const { Title, Text } = Typography;
const { TextArea } = Input;

type BeneficiaryProps = {
  fromOptions: MenuProps['items'];
  currentFrom: CardInfoT;
  countries: Country[];
  loading: { convert: boolean; currencies: boolean; countries: boolean; };
  onAmountChange: (value: number | null) => unknown;
  onCurrencyChange: (value: string) => unknown;
  currencies: Currency[];
  formValues: Partial<InternationalForm>;
  form: FormInstance;
  convertedAmount: string;
  onCancel: () => unknown;
}

const Beneficiary: React.FC<BeneficiaryProps> = ({
  fromOptions,
  currentFrom,
  countries,
  currencies,
  loading,
  onAmountChange,
  onCurrencyChange,
  formValues,
  form,
  convertedAmount,
  onCancel,
}) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendInternationalPaymentScheme(t);

  return (
    <Flex vertical gap={32}>
      <Title level={3}>{t({ id: 'sections.send.tabs.swift' })}</Title>
      <Flex vertical gap={24}>
        <CardDropdown
          label={t({ id: 'noty.card.from' })}
          options={fromOptions}
          currentOption={currentFrom}
        />
        <Form initialValues={formValues} form={form} name="beneficiary">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'screens.internationalPayment.select.beneficiaryType' })}:</Text>
            <Form.Item name="client">
              <Select
                defaultValue="private"
                popupClassName={classes['select-popup']}
                options={[
                  {
                    value: 'private',
                    label: t({ id: 'screens.internationalPayment.select.beneficiaryType.PrivateClient' }),
                  },
                  {
                    value: 'corporate',
                    label: t({ id: 'screens.internationalPayment.select.beneficiaryType.Company' }),
                  },
                ]}
              />
            </Form.Item>
          </Flex>
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Flex vertical>
              <Form.Item name="beneficiary" rules={rules.beneficiary}>
                <Input
                  size="large"
                  placeholder={form.getFieldValue('client') === 'private' ?
                    t({ id: 'screens.internationalPayment.inputs.beneficiary.Name' }) :
                    t({ id: 'screens.internationalPayment.inputs.beneficiary' })
                  }
                />
              </Form.Item>
              {form.getFieldValue('client') === 'private' &&
                <Form.Item name="lastname" rules={rules.lastname}>
                  <Input
                    size="large"
                    placeholder={t({ id: 'screens.internationalPayment.inputs.beneficiary.Surname' })}
                  />
                </Form.Item>
              }
              <Form.Item name="iban" rules={rules.iban}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.iban' })}
                />
              </Form.Item>
              <Form.Item name="bic" rules={rules.bic}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.bic' })}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical gap={8}>
            <Typography>{t({ id: 'screens.internationalPayment.prompts.beneficiaryAddress' })}</Typography>
            <Flex vertical>
              <Form.Item rules={rules.country} name="creditorCountryCode">
                <Select
                  loading={loading.countries}
                  options={countries.map(country => ({
                    value: country.id,
                    label: `${country.name}`,
                  }))}
                  popupClassName={classes['select-popup']}
                  placeholder={t({ id: 'inputs.nationality' })}
                />
              </Form.Item>
              <Form.Item name="city" rules={rules.city}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.city' })}
                />
              </Form.Item>
              {form.getFieldValue('creditorCountryCode') === 'US' &&
                <Form.Item name="state" rules={rules.state}>
                  <Input
                    size="large"
                    placeholder={t({ id: 'screens.internationalPayment.inputs.creditorCountryCode' })}
                  />
                </Form.Item>
              }
              <Form.Item name="postCode" rules={rules.postCode}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.postCode' })}
                />
              </Form.Item>
              <Form.Item name="address1" rules={rules.address1}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.creditorAddress1' })}
                />
              </Form.Item>
              <Form.Item name="address2" rules={rules.address2}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.inputs.creditorAddress2' })}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical gap={8}>
            {/*<Typography>{t({ id: 'sections.send.swift.inputs.amount.description' })}</Typography>*/}
            <Form.Item name="amount" rules={rules.amount}>
              <InputNumber
                placeholder={t({ id: 'sections.send.swift.inputs.amount.description' })}
                onChange={(e: number | null) => onAmountChange(e)}
                rootClassName={classes['input']}
                addonAfter={
                  <Form.Item name="currency" noStyle>
                    <Select
                      size="large"
                      loading={loading.currencies}
                      style={{ margin: 0, width: 140 }}
                      onChange={onCurrencyChange}
                      defaultValue="EUR"
                      options={currencies.map(currency => ({
                        value: currency.code,
                        label: <Flex align='center' gap={8}>
                          {(currency.code in currenciesImg) &&
                            currenciesImg[currency.code as keyof typeof currenciesImg].img()
                          }
                          <Text style={{fontSize: 18, lineHeight: '21px', fontWeight: 500}}>{currency.code}</Text>
                        </Flex>,
                      }))}
                      popupClassName={classes['select-popup']}
                      placeholder="Currency"
                      className={classes['currency-selector']}
                    />
                  </Form.Item>
                }
              />
            </Form.Item>
          </Flex>
          <Flex vertical gap={8}>
            {/*<Typography>{t({ id: 'sections.send.swift.inputs.amount.description2' })}</Typography>*/}
            <Flex vertical>
              <Form.Item>
                <Input
                  disabled
                  size="large"
                  style={{ zIndex: 3 }}
                  value={loading.convert ? '...' : convertedAmount}
                  placeholder={t({ id: 'sections.send.swift.inputs.amount.description2' })}
                  rootClassName={classes['input']}
                  addonAfter={
                    <Select
                      size="large"
                      disabled
                      loading={loading.currencies}
                      style={{ margin: 0, width: 140 }}
                      defaultValue="EUR"
                      options={currencies.map(currency => ({
                        value: currency.code,
                        label: <Flex align='center' gap={8} style={{opacity: 0.5}}>
                          {(currency.code in currenciesImg) &&
                            currenciesImg[currency.code as keyof typeof currenciesImg].img()
                          }
                          <Text style={{fontSize: 18, lineHeight: '21px', fontWeight: 500}}>{currency.code}</Text>
                        </Flex>,
                      }))}
                      popupClassName={classes['select-popup']}
                      placeholder="Currency"
                      className={classes['currency-selector']}
                    />
                  }
                />
              </Form.Item>
              <Text style={{ fontSize: 14 }}>
                {t({ id: 'sections.send.swift.inputs.amount.info' })}
              </Text>
            </Flex>
          </Flex>
          <Form.Item name="details" rules={rules.details} rootClassName={classes['description']}>
            <TextArea
              placeholder={t({ id: 'sections.send.swift.inputs.details' })}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Beneficiary;