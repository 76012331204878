import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 24C18.5647 24 24 18.5647 24 12C24 5.44706 18.5529 0 11.9882 0C5.43529 0 0 5.44706 0 12C0 18.5647 5.44706 24 12 24ZM13.8 17.8941C11.2118 17.8941 8.95294 16.5059 8.30588 13.7294H6.91765C6.58823 13.7294 6.34118 13.4471 6.34118 13.1176C6.34118 12.7647 6.58823 12.5059 6.91765 12.5059H8.12941C8.12941 12.4 8.11765 12.2 8.11765 12C8.11765 11.8 8.11765 11.6471 8.12941 11.4824H6.91765C6.58823 11.4824 6.34118 11.2 6.34118 10.8706C6.34118 10.5176 6.58823 10.2588 6.91765 10.2588H8.31765C8.96471 7.49412 11.2235 6.11765 13.8 6.11765C14.4 6.11765 14.8235 6.17647 15.1059 6.28235C15.5294 6.45882 15.6471 6.70588 15.6471 7C15.6471 7.42353 15.3529 7.68235 14.9176 7.68235C14.6706 7.68235 14.2941 7.61176 13.7765 7.61176C12.0118 7.61176 10.5412 8.49412 10 10.2588H13.7765C14.1176 10.2588 14.3765 10.5176 14.3765 10.8706C14.3765 11.2 14.1176 11.4824 13.7765 11.4824H9.74118C9.72941 11.6471 9.72941 11.8353 9.72941 12.0118C9.72941 12.1765 9.72941 12.3529 9.74118 12.5059H13.7765C14.1176 12.5059 14.3765 12.7647 14.3765 13.1176C14.3765 13.4471 14.1176 13.7294 13.7765 13.7294H9.97647C10.5176 15.5176 12 16.4118 13.7765 16.4118C14.2941 16.4118 14.6588 16.3412 14.9176 16.3412C15.3529 16.3412 15.6471 16.6 15.6471 17.0235C15.6471 17.3294 15.4824 17.5529 15.1294 17.7059C14.8353 17.8235 14.3882 17.8941 13.8 17.8941Z"
    />
  </svg>

);

const Euro = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Euro;
