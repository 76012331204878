import React, { useEffect } from 'react';
import './common/css/index.css';
import { persistor, RootState, store } from 'common/store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist';
import { THEME } from 'common/lib/constants/constants';
import { ConfigProvider } from 'antd';
import { theme } from 'common/lib/constants/theme';
import AppLayout from 'common/components/layout/AppLayout/AppLayout';
import LanguageProvider from 'common/locales/Provider';
import { GlobalLoader } from 'common/components/UI';
import { MessageProvider } from 'common/lib/hooks/useMessage/MessageProvider';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { SoftBlockModal } from 'common/components/modals';

export const PersistorContext = React.createContext<Persistor | null>(null);
export const history = createBrowserHistory();
// const AppConfigProvider = () => {
//   const currentTheme = useSelector((state: RootState) => state.app.theme) || THEME.LIGHT;
//   return (
//     <ConfigProvider wave={{ disabled: true }} theme={theme[currentTheme]}>
//       <AppLayout />
//     </ConfigProvider>
//   );
// };
console.warn = () => null;
console.error = () => null;
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PersistorContext.Provider value={persistor}>
          <LanguageProvider>
            <ConfigProvider wave={{ disabled: true }} theme={theme[THEME.LIGHT]}>
              <BrowserRouter>
                <MessageProvider>
                  <AppLayout />
                  <GlobalLoader />
                  <SoftBlockModal/>
                </MessageProvider>
              </BrowserRouter>
            </ConfigProvider>
          </LanguageProvider>
        </PersistorContext.Provider>
      </PersistGate>
    </Provider>
  );
}

export default App;
