import { JointContent, MessageType, TypeOpen } from 'antd/es/message/interface';
import { ReactNode, createContext } from 'react';

export type OpenMessageParams = [
  content: ReactNode,
  className?: string,
  duration?: number,
  onClose?: VoidFunction
];
type OpenMessageFunc = (...params: OpenMessageParams) => MessageType | null;

export interface IMessageContext {
  showError: OpenMessageFunc;
  showSuccess: OpenMessageFunc;
  showWarning: OpenMessageFunc;
  showInfo: OpenMessageFunc;
  showLoading: OpenMessageFunc;
}
const MessageContext = createContext<IMessageContext>({
  showError: (content, className, duration, onClose) => null,
  showSuccess: (content, className, duration, onClose) => null,
  showWarning: (content, className, duration, onClose) => null,
  showInfo: (content, className, duration, onClose) => null,
  showLoading: (content, className, duration, onClose) => null,
});

export default MessageContext;
