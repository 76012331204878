import { Flex, Form, FormInstance, Input, InputNumber, MenuProps, Typography } from 'antd';
import classes from '../SendSepa.module.scss';
import React from 'react';
import { getSendSepaScheme } from '../model/scheme';
import { SepaForm } from '../model/types';
import { CardInfoT } from 'common/types/cards';
import CardDropdown from '../../../../components/CardDropdown/CardDropdown';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;
const { TextArea } = Input;

type BeneficiaryProps = {
  fromOptions: MenuProps['items'];
  currentFrom: CardInfoT;
  formValues: Partial<SepaForm>;
  form: FormInstance;
  onCancel: () => unknown;
}

const Beneficiary: React.FC<BeneficiaryProps> = ({
                                       fromOptions,
                                       currentFrom,
                                       formValues,
                                       form,
                                       onCancel,
                                     }) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendSepaScheme(t);

  return (
    <Flex vertical gap={12}>
      <Title level={3}>{t({ id: 'sections.send.tabs.sepa' })}</Title>
      <Flex vertical gap={24}>
        <CardDropdown
          label={`${t({ id: 'noty.card.from' })}:`}
          options={fromOptions}
          currentOption={currentFrom}
        />
        <Form initialValues={formValues} form={form} name="beneficiary">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Flex vertical>
              <Form.Item name="beneficiary" rules={rules.beneficiary}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.internationalPayment.create.signer.inputs.name' })}
                />
              </Form.Item>
              <Form.Item name="iban" rules={rules.iban}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.iban' })}
                />
              </Form.Item>
              <Form.Item name="bic" rules={rules.bic}>
                <Input
                  size="large"
                  placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.bic' })}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical>
            <Form.Item name="amount" rules={rules.amount}>
              <InputNumber
                addonBefore="€"
                placeholder={t({ id: 'noty.card.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
            <Text style={{ fontSize: 14 }}>
              {t({ id: 'screens.payment.create.beneficiary.inputs.amount.info3' })}
            </Text>
          </Flex>
          <Form.Item name="description" rules={rules.description} style={{ marginTop: 16 }}>
            <TextArea
              placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.details' })}
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Beneficiary;