import { general, generalFields } from "../../../../../common/lib/schemas/rules";

export const getSendC2CScheme = (
  t: (descriptor: object, values?: object) => string
) => ({
  to: [
    general(t)['required-field'],
    {
      min: 16,
      max: 16,
      message: t({id: 'schemas.to.invalid'})
    },
    general(t)['numbers-and-letters-and-spaces']
  ],
  amount: [
    general(t)['required-field'],
    ...generalFields(t)['amount']
  ],
  confirmAmount: [
    general(t)['required-field'],
  ],
})