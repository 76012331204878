import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import { FILE_FORMAT } from '../../common/lib/constants/constants';
import {
  GetHoldsBody,
  GetTransactionsBody, HoldDetailsResponse,
  TransactionDetailsResponse,
  TransactionsResponse,
} from './dto/transactions';
import JSONBigInt from 'json-bigint';

type TransactionApi = {
  statement: {
    // request: (cardId: number, { start, end, type, status, size, page, language }: {
    //   start?: string, end?: string, type?: 'IN' | 'OUT' | 'ALL', status?: 'DECLINED' | 'SUCCESSFUL', size?: number, page?: number, language?: string
    // }) => Promise<any>;
    download: (accountId: number, { start, end, language, format }: {
      start?: string, end?: string, language?: string, format?: 'pdf' | 'csv',
    }) => Promise<any>;
    downloadTransaction: (cardId: number, transactionId: string, { language }: { language?: string }) => Promise<any>;
  },
  getTransactions: (accountId: number, body: GetTransactionsBody) => Promise<TransactionsResponse>;
  getHolds: (accountId: number, { start, end, page, size }: GetHoldsBody) => Promise<TransactionsResponse>;
  getTransactionById: (accountId: number, transactionId: string) => Promise<TransactionDetailsResponse>;
  getHoldById: (accountId: number, transactionId: string) => Promise<HoldDetailsResponse>;
  downloadInvoice: (accountId: number, amount: number, language?: string) => Promise<any>
}

const transactionApi: TransactionApi = {
  statement: {
    download(accountId, { start, end, language, format = FILE_FORMAT.PDF }) {
      return API.get(ENDPOINTS.TRANSACTION_CTRL.DOWNLOAD(accountId, format), {
        params: {
          start, end, language,
        },
        headers: {
          'Content-Type': `application/${format}`,
        },
        responseType: 'blob',
      });
    },
    downloadTransaction(accountId, tranactionId, {language}) {
      return API.get(ENDPOINTS.TRANSACTION_CTRL.DOWNLOAD_TRANSACTION_PDF(accountId, tranactionId, language), {
        headers: {
          'Content-Type': `application/${FILE_FORMAT.PDF}`,
        },
        responseType: 'blob',
      });
    },
  },

  getTransactions(accountId, body) {
    return API.get(ENDPOINTS.TRANSACTION_CTRL.GET_TRANSACTIONS(accountId), {
      transformResponse: [(data: string) => {
        const answer = data.replace(/"id":(\d+)/g, `"id":"$1"`);
        return JSON.parse(answer)
      }],
      params: body,
    });
  },

  getHolds(accountId, { start, end, page, size }) {
    return API.get(ENDPOINTS.TRANSACTION_CTRL.GET_HOLDS(accountId), {
      transformResponse: [(data: string) => {
        const answer = data.replace(/"id":(\d+)/g, `"id":"$1"`);
        return JSON.parse(answer)
      }],
      params: {
        start,
        end,
        page,
        size,
      },
    });
  },

  getTransactionById(accountId, transactionId) {
    return API.get(ENDPOINTS.TRANSACTION_CTRL.GET_TRANSACTION_BY_ID(accountId, transactionId), {
      transformResponse: [data => JSONBigInt.parse(data)]
    });
  },

  getHoldById(accountId, holdId) {
    return API.get(ENDPOINTS.TRANSACTION_CTRL.GET_HOLD_BY_ID(accountId, holdId));
  },

  downloadInvoice(cardId, amount, language) {
    return API.get(ENDPOINTS.TRANSACTION_CTRL.DOWNLOAD_PDF_INVOICE(cardId), {
      params: { amount, language },
      headers: {
        'Content-Type': `application/${FILE_FORMAT.PDF}`,
      },
      responseType: 'blob',
    })
  }
};

export default transactionApi;