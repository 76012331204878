import { Flex, Form, FormInstance, InputNumber, MenuProps, Typography } from 'antd';
import classes from '../BetweenAccounts.module.scss';
import React from 'react';
import { Account } from '../../../../types';
import AccountDropdown from '../../../../components/AccountDropdown/AccountDropdown';
import { BlackButton } from 'common/components/UI';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { general, generalFields } from "../../../../../common/lib/schemas/rules";

const { Title } = Typography;

type Step1Props = {
  fromOptions: MenuProps['items'];
  toOptions: MenuProps['items'];
  currentFrom: Account;
  currentTo: Account;
  form: FormInstance;
  onSubmit: ({ amount }: { amount: number }) => unknown;
}

const Beneficiary: React.FC<Step1Props> = ({
                                             fromOptions,
                                             toOptions,
                                             form,
                                             currentFrom,
                                             currentTo,
                                             onSubmit,
                                           }) => {

  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  return (
    <Flex vertical gap={32}>
      <Title level={3}>{t({ id: 'sections.load.load.tabs.betweenAccounts' })}</Title>
      <Form form={form} onFinish={onSubmit}>
        <Flex vertical gap={24}>
          <AccountDropdown
            label={`${t({ id: 'noty.card.from' })}:`}
            options={fromOptions}
            currentOption={currentFrom}
            id='account-from'
          />
          <AccountDropdown
            label={`${t({ id: 'noty.card.to' })}:`}
            options={toOptions}
            currentOption={currentTo}
            id='account-to'
          />
          <Form.Item name='amount' rules={[
            general(t)['required-field'],
            ...generalFields(t)['amount']
          ]}>
            <InputNumber
              id='amount-input'
              addonBefore="€"
              placeholder={t({ id: 'noty.card.amount' })}
              rootClassName={classes['input']}
            />
          </Form.Item>
          <Flex gap={8}>
            <BlackButton
              id='btn__send-between-accounts'
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
};

export default Beneficiary;