import React from 'react';
import { Button, Flex, Modal, Result } from 'antd';
import { BlackButton } from 'common/components/UI';
import { CloseOutlined } from "@ant-design/icons";



type WarningModalProps = {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => unknown;
    onBlock: () => unknown;
};

const WarningModal: React.FC<WarningModalProps> = ({isOpen, isLoading, onBlock, onClose}) => {

  return (
    <Modal
      closeIcon={<CloseOutlined id='btn__block-close'/>}
      title="&nbsp;"
      open={isOpen}
      footer={false}
      onCancel={onClose}
    >
    <Result
      status="warning"
      title="Are you sure?"
      subTitle="When the card is blocked you can only order new one. The old one is not to be unblocked."
      extra={
        <Flex gap={8} vertical>
          <BlackButton id='btn__block-cancel' type='primary' key="cancel" block onClick={onClose}>
            Cancel
          </BlackButton>
          <Button
            danger
            id='btn__block-submit'
            type="default"
            key="block"
            loading={isLoading}
            block
            onClick={onBlock}>
            Block card
          </Button>
        </Flex>
      }
    />
  </Modal>
  );
};

export default WarningModal;
