import { useState } from 'react';
import CustomCardContainer from 'common/components/UI/CustomCardContainer/CustomCardContainer';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { APP } from 'common/lib/constants/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import userApi from 'common/api/user';
import { general, generalFields } from 'common/lib/schemas/rules';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import classes from './ResetPasswordConfirm.module.scss';

const ResetPasswordConfirm = () => {
  const { token } = useParams();
  const email = new URLSearchParams(location.search).get('email');
  const { showSuccess, showError } = useMessage();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  const rules = {
    password: [general(t)['required-field'], ...generalFields(t).password],
    repeatPassword: [
      general(t)['required-field'],
      ...generalFields(t).password,
      general(t)['must-match'](
        'password',
        t({ id: 'screens.auth.signup.inputs.password' }),
      ),
    ],
  };

  const onFinish = ({ password }: { password: string }) => {
    setLoading(true);
    userApi.auth
      .resetPasswordConfirm(token!, { email: email!, password })
      .then(() => {
        showSuccess(t({ id: 'toasts.auth.success.password.title' }));
        navigate(ROUTES.SIGN_IN.path);
      })
      .catch(() => {
        showError(t({ id: 'toasts.auth.error.password.title' }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!token) {
    navigate(ROUTES.SIGN_IN.path);
  }

  return (
    <div className={classes.wrapper}>
      <Flex flex={1} justify="center" align="center">
        <CustomCardContainer className={classes.card}>
          <Typography.Text className={classes.companyName}>{APP.NAME}</Typography.Text>
          <Typography.Text className={classes.title}>
            {t({ id: 'screens.auth.password.title' })}
          </Typography.Text>
          <Typography.Paragraph className={classes.desc}>
            {t({ id: 'screens.auth.password.legend' })}
          </Typography.Paragraph>
          <Form
            validateTrigger="onBlur"
            form={form}
            disabled={loading}
            onFinish={onFinish}>
            <Form.Item rules={rules.password} name="password">
              <Input.Password
                placeholder={t({ id: 'screens.auth.signup.inputs.password' })}
              />
            </Form.Item>
            <Form.Item rules={rules.repeatPassword} name="repeatPassword">
              <Input.Password
                placeholder={t({ id: 'screens.auth.signup.inputs.passwordConfirm' })}
              />
            </Form.Item>
            <Button loading={loading} type="primary" onClick={form.submit} block>
              {t({ id: 'screens.resetPassword.btn' })}
            </Button>
          </Form>
        </CustomCardContainer>
      </Flex>
    </div>
  );
};

export default ResetPasswordConfirm;
