require('moment/locale/bg');
require('moment/locale/cs');
require('moment/locale/de');
require('moment/locale/et');
require('moment/locale/es');
require('moment/locale/fr');
require('moment/locale/it');
require('moment/locale/lt');
require('moment/locale/lv');
require('moment/locale/nl');
require('moment/locale/pl');
require('moment/locale/ru');
