import { Card, Dropdown, Flex, MenuProps, Typography } from 'antd';
import React from 'react';
import { CardInfoT } from 'common/types/cards';
import SelectCard from './SelectCard';
import './CardDropdown.scss';

const { Text } = Typography;

type CardDropdownProps = {
  label: string;
  options: MenuProps['items'];
  disabled?: boolean;
  currentOption: CardInfoT;
}

const CardDropdown: React.FC<CardDropdownProps> = ({ label, options, currentOption, disabled }) => {
  return (
    <Flex vertical gap={8}>
      <Text>
        {label}
      </Text>
      <Dropdown
        menu={{
          items: options,
          selectable: true,
          defaultSelectedKeys: ['0'],
        }}
        overlayStyle={{ maxHeight: 250, overflow: 'scroll', overflowX: 'hidden' }}
        placement="bottom"
        trigger={['click']}
        overlayClassName="card-dropdown"
      >
        {SelectCard({ currentOption })}
      </Dropdown>
    </Flex>
  );
};

export default CardDropdown;