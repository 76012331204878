import { IntlFormatMessageFunc } from 'common/types/app';
import { RIVER_TRANSACTION_TYPE } from 'river/lib/constants/values';
import { RiverTransactionType } from 'river/types/transactions';
import { FILTERS } from './constants';

export const getTransactionTypeLabel = (
  t: IntlFormatMessageFunc,
  type: RiverTransactionType,
) => {
  switch (type) {
    case RIVER_TRANSACTION_TYPE.SEND_CREDIT:
      return t({ id: 'transactions.item.type.send_credit' });
    case RIVER_TRANSACTION_TYPE.SEND_DEBIT:
      return t({ id: 'transactions.item.type.send_debit' });
    case RIVER_TRANSACTION_TYPE.TRANSFER_CREDIT:
      return t({ id: 'transactions.item.type.transfer_credit' });
    case RIVER_TRANSACTION_TYPE.TRANSFER_DEBIT:
      return t({ id: 'transactions.item.type.transfer_debit' });
    case RIVER_TRANSACTION_TYPE.WITHDRAW_SEPA:
      return t({ id: 'transactions.item.type.withdraw_sepa' });
    case RIVER_TRANSACTION_TYPE.CLEARING_FEE:
      return t({ id: 'transactions.item.type.clearing_fee' });
    case RIVER_TRANSACTION_TYPE.DEPOSIT:
      return t({ id: 'transactions.item.type.deposit' });
    case RIVER_TRANSACTION_TYPE.BANK_FEE:
      return t({ id: 'transactions.item.type.bank_fee' });
    case RIVER_TRANSACTION_TYPE.DEPOSIT_SEPA:
      return t({ id: 'transactions.item.type.deposit_sepa' });
    case RIVER_TRANSACTION_TYPE.WITHDRAW_SEPA_CANCEL:
      return t({ id: 'transactions.item.type.withdraw_sepa_cancel' });
    case RIVER_TRANSACTION_TYPE.WITHDRAW:
      return t({ id: 'transactions.item.type.withdraw' });
    case RIVER_TRANSACTION_TYPE.CLEARING_DEBIT:
      return t({ id: 'transactions.item.type.clearing_debit' });
  }
};

export const getFilterTypeLabel = (
  t: IntlFormatMessageFunc,
  filter: keyof typeof FILTERS,
) => {
  switch (filter) {
    case FILTERS.ALL:
      return t({ id: 'transactions.filter.all' });
    case FILTERS.HOLD:
      return t({ id: 'transactions.filter.hold' });
    case FILTERS.IN:
      return t({ id: 'transactions.filter.in' });
    case FILTERS.OUT:
      return t({ id: 'transactions.filter.out' });
    case FILTERS.PENDING:
      return t({ id: 'transactions.filter.pending' });
  }
};
