import React from 'react';
import { Typography } from 'antd';
import classes from '../../Cards.module.css';
import { useIntl } from 'react-intl';

type ManageCardProps = {
  options: React.ReactElement;
};

const ManageCard: React.FC<ManageCardProps> = ({ options }) => {
  const { formatMessage: t } = useIntl();
  return (
    <div>
      <Typography.Text className={classes.title}>
        {t({ id: 'card.manage' })}
      </Typography.Text>
      {options}
    </div>
  );
};

export default ManageCard;
