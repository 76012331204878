import React from 'react';
import { Typography, Col, Row, Button, Flex } from 'antd';
import { DataCellProps, DataTableProps } from '../types';
import DataCell from './DataCell';
import styles from '../Profile.module.css';
import { useIntl } from 'react-intl';

const { Title } = Typography;

const ROWS_COUNT = 2;

function chunkCells(arr: Array<DataCellProps>, size: number) {
  const result = [];

  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}

export default function DataTable({ title, cells, onEdit }: DataTableProps) {
  const chunkedCells = chunkCells(cells, ROWS_COUNT);
  const { formatMessage: t } = useIntl();

  return (
    <div>
      <Flex align="center" justify="space-between" style={{ marginBottom: 16 }}>
        <Title level={4}>
          {title}
        </Title>
        {onEdit && (
          <Button
            id='#btn__address-edit'
            size="small"
            className={styles['edit_btn']}
            onClick={onEdit}
          >
            {t({id: "buttons.edit"})}
          </Button>
        )}
      </Flex>
      <div className={styles['profile_data']}>
        {chunkedCells.map((cellRow, index) => (
          <Row
            style={index === chunkedCells.length - 1 ? {} : { marginBottom: 24 }}
            key={index}
            gutter={[32, 24]}>
            {cellRow.map((cell, j) => (
              <Col key={`${cell.label}-${index}-${j}`} span={12}>
                <DataCell label={cell.label} value={cell.value} />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  );
}
