import React from 'react';
import { Flex, Modal, Result } from 'antd';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

type GetCVVModalProps = {
  isOpen: boolean;
  onClose: () => unknown;
  onSend: () => unknown;
};

const GetCVVModal: React.FC<GetCVVModalProps> = ({ isOpen, onSend, onClose }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Modal title="&nbsp;" open={isOpen} footer={false} onCancel={onClose}>
      <Result
        status="warning"
        title={t({ id: 'card.manage.getCVV' })}
        subTitle={t({ id: 'card.get.cvv.text' })}
        extra={
          <Flex gap={8} vertical>
            <BlackButton type="primary" key="send" block onClick={onSend}>
              {t({ id: 'card.manage.getCVV' })}
            </BlackButton>
            <BlackButton key="cancel" onClick={onClose} block>
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        }
      />
    </Modal>
  );
};

export default GetCVVModal;
