import React from 'react';
import { Card, Flex, Space, Typography } from 'antd';
import Icon, { RightOutlined } from '@ant-design/icons';
import { ActionButton, BackButton, CenteredContainer, GridContainer } from 'common/components/UI';
import classes from './Send.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import EuroIcon from 'common/components/icons/Euro';
import TransferIcon from 'common/components/icons/Transfer';
import CardIcon from 'common/components/icons/Card';

const { Title } = Typography;

const Index: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        <Flex gap="middle" vertical>
          <Title level={3}>{t({ id: 'sections.header.header.buttons.commonSend' })}</Title>
          <Flex gap="middle" vertical>
            <ActionButton
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <EuroIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.send.tabs.sepa' })}
              onClick={() => navigate(ROUTES.OCEAN_SEND_SEPA.path)}
            />
            <ActionButton
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <TransferIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.header.header.buttons.c2csend' })}
              onClick={() => navigate(ROUTES.OCEAN_SEND_C2C.path)}
            />
            <ActionButton
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <CardIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.send.tabs.swift' })}
              onClick={() => navigate(ROUTES.OCEAN_SEND_INTERNATIONAL.path)}
            />
          </Flex>
        </Flex>
      </div>
    </GridContainer>
  );
};

export default Index;
