import React, { useState } from 'react';
import { CopyOutlined, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Flex, Space, Typography } from 'antd';
import { ActionButton, RoundContainer } from 'common/components/UI';
import classes from '../../Cards.module.css';
import { useIntl } from 'react-intl';

type RequisitesProps = {
  cardNumber: string;
  cvv: string;
  expDate: string;
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
};

const Requisites: React.FC<RequisitesProps> = ({
  cardNumber,
  cvv,
  expDate,
  showError,
  showSuccess,
}) => {
  const { formatMessage: t } = useIntl();
  const [hide, setHide] = useState<boolean>(true);

  const shortNumber = cardNumber.slice(-4) || '';
  const fullCardNumber = hide ? `•••• •••• •••• ${shortNumber}` : cardNumber;
  const CVV = hide ? `•••` : cvv;
  const cardExpDate = hide ? `••/••` : expDate;

  const toggleHide = () => {
    setHide((prev) => !prev);
  };

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccess?.(t({ id: 'common.clipboard.success' }));
      })
      .catch(() => {
        showError?.(t({ id: 'common.clipboard.error' }));
      });
  };

  return (
    <Space className={classes.fullWidth} direction="vertical" size={8}>
      <Flex justify="space-between">
        <Typography.Text className={classes.title}>
          {t({ id: 'card.requisites' })}
        </Typography.Text>
        <RoundContainer padding={6} id='#btn__show-requisites' pointer onClick={toggleHide}>
          {hide ? (
            <EyeFilled style={{ fontSize: 20 }} />
          ) : (
            <EyeInvisibleFilled style={{ fontSize: 20 }} />
          )}
        </RoundContainer>
      </Flex>
      <ActionButton
        id='requisites__card-number'
        text={fullCardNumber}
        icon={<CopyOutlined id='copy-icon' style={{ fontSize: 20 }} />}
        onClick={() => onCopy(fullCardNumber)}
      />
      <Flex gap={8}>
        <ActionButton
          id='requisites__card-date'
          text={cardExpDate}
          icon={<CopyOutlined id='copy-icon' style={{ fontSize: 20 }} />}
          onClick={() => onCopy(cardExpDate)}
        />
        <ActionButton
          id='requisites__card-cvv'
          text={CVV}
          icon={<CopyOutlined id='copy-icon' style={{ fontSize: 20 }} />}
          onClick={() => onCopy(CVV)}
        />
      </Flex>
    </Space>
  );
};

export default Requisites;
