import React from 'react';
import { Button, Flex, Typography } from 'antd';

import { InfoCircleFilled } from '@ant-design/icons';
import classnames from './Plan.module.css';

const { Title } = Typography;

export type PlanInfoComponentProps = {
  title: string;
  info: string[];
  bgColor: string;
  onOpen: () => unknown;
};

const PlanInfoComponent = ({ title, info, bgColor, onOpen }: PlanInfoComponentProps) => {
  return (
    <div className={classnames['plan-wrapper']} style={{ background: bgColor }}>
      <Flex justify="space-between" className={classnames['title-wrapper']}>
        <Title style={{ fontStyle: 'italic' }}>{title}</Title>
        <InfoCircleFilled style={{ fontSize: 20 }} />
      </Flex>
      <ul className={classnames['plan-info-list']}>
        {info.map((infoItem, index) => (
          <li key={index}>{infoItem}</li>
        ))}
      </ul>
      <Button className={classnames.button} block onClick={onOpen}>
        Open
      </Button>
    </div>
  );
};

export default PlanInfoComponent;
