import { ERROR_MESSAGES, RIVER_ERROR_TYPES } from '../../../../lib/constants/errors';

export const getErrorMessage = (
  errorId: string,
  t: (descriptor: object, values?: object) => string
) => {
  switch (errorId) {
    case RIVER_ERROR_TYPES.INVALID_ACCOUNT:
      return t({id: ERROR_MESSAGES.INVALID_ACCOUNT})
    default:
      return t({id: ERROR_MESSAGES.OTHER})
  }
}