import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, MenuProps } from 'antd';
import classes from './Sepa.module.scss';
import { BackButton, GridContainer } from 'common/components/UI';
import { AppDispatch } from 'common/store';
import { Account } from '../../../types';
import {
  confirmSepa,
  createSepa,
  resendCode,
  signSepa,
} from '../../../store/actions/transfers';
import Beneficiary from './components/Beneficiary';
import Amount from './components/Amount';
import Code from './components/Code';
import { SepaForm } from './model/types';
import { FormFinishInfo } from 'rc-field-form/lib/FormContext';
import { getAccounts, getCurrentAccount } from '../../../store/selectors';
import { mapAccountsToOptions } from '../../../components/AccountDropdown/actions';
import Success from './components/Success';
import { refreshAccounts } from '../../../store/actions/user';
import { ERROR_MESSAGES } from '../../../lib/constants/errors';
import { handleError } from '../../../lib/utils/errorHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { useIntl } from 'react-intl';
import { getErrorMessage } from './model/errors';
import Declined from './components/Declined';
import { RIVER_TRANSACTION_STATUS } from '../../../lib/constants/values';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';

const Sepa: React.FC = () => {
  const currentTemplate = useLocation()?.state?.currentTemplate;
  const accounts = useSelector(getAccounts);
  const currentAccount = useSelector(getCurrentAccount);
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;

  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();

  const [currentFrom, setCurrentFrom] = useState<Account>(currentAccount ? accounts[currentAccount] : Object.values(accounts)[0]);
  const [fromOptions, setFromOptions] = useState<MenuProps['items']>(
    mapAccountsToOptions(accounts, setCurrentFrom),
  );
  const [step, setStep] = useState<number>(0);
  const [predictedFee, setPredictedFee] = useState<number>(0);
  const [formData, setFormData] = useState<Partial<SepaForm>>({});
  const [paymentId, setPaymentId] = useState<number>(0);
  const [paymentDeclined, setPaymentDeclined] = useState<boolean>(false);

  const resetForm = () => {
    form.resetFields();
    setStep(0);
  }

  const checkBeneficiaryErrors = () => {
    if (form.getFieldValue('amount') > currentFrom.available) {
      showError(t({ id: ERROR_MESSAGES.NOT_ENOUGH }));
      return 1;
    }

    return 0;
  };

  const checkAmountErrors = () => {
    if (form.getFieldValue('amount') !== form.getFieldValue('confirmAmount')) {
      showError(t({ id: ERROR_MESSAGES.NOT_EQUAL }, {name: t({ id: 'noty.card.amount' })}));
      return 1;
    }

    if (form.getFieldValue('amount') + predictedFee > currentFrom.available) {
      showError(t({ id: ERROR_MESSAGES.NOT_ENOUGH }));
      return 1;
    }

    return 0;
  };

  const toNextStep = () => {
    setStep((prev) => (prev < Object.keys(steps).length - 1 ? prev + 1 : prev));
  };

  const toPrevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleBeneficiarySubmit = () => {
    (async () => {
      if (checkBeneficiaryErrors()) return;

      const body = {
        iban: form.getFieldValue('iban').toUpperCase(),
        amount: form.getFieldValue('amount') ?? 0,
        recipientName: form.getFieldValue('beneficiary'),
        description: form.getFieldValue('description'),
      };

      try {
        const response = await dispatch(createSepa(currentFrom.id, body));
        setPredictedFee(response.predictedFee);
        setPaymentId(response.id);

        toNextStep();
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const handleAmountSubmit = () => {
    (async () => {
      if (checkAmountErrors()) return;

      try {
        await dispatch(
          confirmSepa(
            currentFrom.id,
            paymentId,
            form.getFieldValue('confirmAmount') ?? 0,
          ),
        );

        toNextStep();
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const handleCodeSubmit = (code: string) => {
    (async () => {
      try {
        const transaction = await dispatch(signSepa(currentFrom.id, paymentId, code, isTwoFactorEnabled));

        if (transaction.status === RIVER_TRANSACTION_STATUS.DECLINED)
          setPaymentDeclined(true);

        showSuccess?.(t({ id: 'common.message.success' }), 'toast__code-success');
        toNextStep();
        await dispatch(refreshAccounts());
      } catch (err) {
        showError?.(t({ id: 'common.otp.wrong' }), 'toast__code-error');
      }
    })();
  };

  const onEachFormFinish = (formName: string, formInfo: FormFinishInfo) => {
    const updatedFormData = { ...formData, ...formInfo.values };
    setFormData(updatedFormData);

    switch (formName) {
      case 'beneficiary':
        handleBeneficiarySubmit();
        break;
      case 'amount':
        handleAmountSubmit();
        break;
      default:
        return;
    }
  };

  const handleResend = () => {
    (async () => {
      try {
        await dispatch(resendCode(currentFrom.id, paymentId));
        showSuccess(t({ id: 'common.otp.success' }),'toast__code-sent');
      } catch (err) {
        showError?.(t({id: 'common.otp.error'}), 'toast__code-sent-error');
      }
    })();
  };

  const steps: Record<number, JSX.Element> = useMemo(
    () => ({
      0: (
        <Beneficiary
          form={form}
          fromOptions={fromOptions}
          currentFrom={currentFrom}
          formValues={formData}
          template={currentTemplate}
          onCancel={() => navigate(ROUTES.RIVER_SEND.path)}
        />
      ),
      1: (
        <Amount
          form={form}
          currentFrom={currentFrom}
          formValues={formData}
          onCancel={toPrevStep}
          fee={predictedFee}
        />
      ),
      2: (
        <Code
          isTwoFactorEnabled={isTwoFactorEnabled}
          isBiometryEnabled={isBiometryEnabled}
          onResend={handleResend}
          onSubmit={(code: string) => handleCodeSubmit(code)}
          onCancel={() => setStep(0)}
        />
      ),
      3: (
        <Success
          resetForm={resetForm}
          name={form.getFieldValue('beneficiary')}
          amount={form.getFieldValue('amount')}
        />
      ),
    }),
    [predictedFee, paymentId, currentFrom],
  );

  if (paymentDeclined)
    return (
      <GridContainer>
        <Declined />
      </GridContainer>
    )

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        <Form.Provider onFormFinish={onEachFormFinish}>{steps[step]}</Form.Provider>
      </div>
    </GridContainer>
  );
};

export default Sepa;
