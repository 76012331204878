import { getClient, getAccounts, refreshAccounts, getApplications } from './user';
import { getCards } from './cards';
import { Account } from 'river/types';
import { RiverThunkDispatch } from 'river/types/thunk';

export const initRiver = () => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const [riverClient, riverAccounts] = await Promise.allSettled([
        dispatch(getClient()),
        dispatch(getAccounts()),
      ]);
      let accounts: Account[] = riverAccounts.status === 'fulfilled' ? riverAccounts.value as Account[] : [];
      let applications = [];
      if (accounts.length > 0) {
        accounts = await Promise.all(
          accounts.map(async account => {
            try {
              const cards = await dispatch(getCards(account.id));

              return { ...account, cards };
            } catch (e) {
              // console.error(`Error fetching cards for account ${account.id}:`, e.message);
              return { ...account, cards: [] };
            }
          })
        );
        applications = await dispatch(getApplications());
      }
      return {
        meta: riverClient.status === 'fulfilled' ? riverClient.value : {},
        accounts,
        applications,
      };
    } catch (e) {
      // console.log('Error fetching river data');
    }
  };
};

export const refreshRiver = () => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const riverAccounts = await dispatch(refreshAccounts());

      if (riverAccounts.length > 0) {
        await Promise.all(
          riverAccounts.map(async account => {
            await dispatch(getCards(account.id));
          })
        );
        await dispatch(getApplications());
      }
    } catch (e) {
      // console.log('Error fetching river data');
    }
  };
};
