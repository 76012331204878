import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { ApiResponse, IntlFormatMessageFunc } from 'common/types/app';
import PFSTC_EN from 'common/assets/docs/terms_pfs/TC__en.pdf';
import PFSTC_DE from 'common/assets/docs/terms_pfs/TC__de.pdf';
import PFSTC_ES from 'common/assets/docs/terms_pfs/TC__es.pdf';
import PFSTC_LV from 'common/assets/docs/terms_pfs/TC__lv.pdf';
import PFSTC_FR from 'common/assets/docs/terms_pfs/TC__fr.pdf';
import PFSTC_RU from 'common/assets/docs/terms_pfs/TC__ru.pdf';
import PFSTC_IT from 'common/assets/docs/terms_pfs/TC__it.pdf';
import WallettoTC_EN from 'common/assets/docs/terms_walletto/WallettoTC__en.pdf';
import WallettoTC_DE from 'common/assets/docs/terms_walletto/WallettoTC__de.pdf';
import WallettoTC_ES from 'common/assets/docs/terms_walletto/WallettoTC__es.pdf';
import WallettoTC_LV from 'common/assets/docs/terms_walletto/WallettoTC__lv.pdf';
import WallettoTC_FR from 'common/assets/docs/terms_walletto/WallettoTC__fr.pdf';
import WallettoTC_RU from 'common/assets/docs/terms_walletto/WallettoTC__ru.pdf';
import WallettoTC_IT from 'common/assets/docs/terms_walletto/WallettoTC__it.pdf';
import Privacy_EN from 'common/assets/docs/privacy/PrivacyPolicy__en.pdf';
import Privacy_DE from 'common/assets/docs/privacy/PrivacyPolicy__de.pdf';
import Privacy_LV from 'common/assets/docs/privacy/PrivacyPolicy__lv.pdf';
import Privacy_FR from 'common/assets/docs/privacy/PrivacyPolicy__fr.pdf';
import Privacy_RU from 'common/assets/docs/privacy/PrivacyPolicy__ru.pdf';
import Privacy_IT from 'common/assets/docs/privacy/PrivacyPolicy__it.pdf';

export const combineClasses = (...classNames: (string | undefined | false)[]) => {
  return classNames.filter(Boolean).join(' ');
};
export const getRouteByKey = (key: string) => {
  for (const routeKey in ROUTES) {
    if (ROUTES[routeKey as keyof typeof ROUTES].key === key) {
      return ROUTES[routeKey as keyof typeof ROUTES];
    }
  }
  return undefined;
};

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r: number, g: number, b: number): string {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const colorToHex = (color: string): string | null => {
  if (color.startsWith('#')) {
    // Цвет уже в HEX
    return color;
  } else if (color.startsWith('rgb')) {
    // Преобразование RGB(A) в HEX
    const components = color
      .replace(/rgba?\(([^)]+)\)/, '$1')
      .split(',')
      .map((component) => component.trim());
    // Убираем альфа-канал, если он есть, и преобразуем в числа
    const [r, g, b] = components.slice(0, 3).map(Number);
    return rgbToHex(r, g, b);
  } else {
    // Для именованных цветов и HSL(A) здесь может быть дополнительная логика преобразования
    console.error('Unsupported color format:', color);
    return null;
  }
};

export const getGreyColor = (opacity: number = 0.05) => {
  return `rgba(0, 0, 0, ${opacity})`;
};

export const getLinearGradientString = (
  gradient: string[],
  angle: number = 135,
): string => {
  return `linear-gradient(${angle}.00deg, ${gradient[0]} 0%,${gradient[1]} 100%)`;
};

export const getErrorDefaultMessage = (
  response: ApiResponse<unknown>,
): string | undefined => {
  if (response?.data?.errors?.[0]) {
    if (response.data.errors[0].field === 'system') {
      return undefined;
    }
    return response.data.errors[0]?.defaultMessage;
  }
  return undefined;
};

export const handleErrorWithCommonFallback = (response: ApiResponse<unknown>, t: IntlFormatMessageFunc, showError: (message: string) => unknown ) => {
  if (response) {
    showError(
      getErrorDefaultMessage(response) || t({ id: 'common.message.error' }),
    );
  } else {
    showError(t({ id: 'common.message.error' }));
  }
} 

export const splitBalance = (balance: number | undefined) => {
  const balanceFixed =
    balance !== undefined && isFinite(balance) ? balance.toFixed(2) : '0.00';
  const balanceString = balanceFixed.replace('.', ',');
  return balanceString.split(',');
};
