import { Badge, Flex, message, Modal } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { BlackButton, UserAvatar } from '../../../../components/UI';
import buttonClasses from '../../../../components/UI/BlackButton/BlackButton.module.scss';
import React, { useEffect, useState } from 'react';
import classes from './ProfileAvatar.module.scss';
import { deleteUserAvatar, getUserAvatar, postUserAvatar } from '../../../../store/actions/user';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';

type ProfileAvatarProps = {
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ showError, showSuccess }) => {
  const { formatMessage: t } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const url = useSelector((state: RootState) => state.user.avatar);

  useEffect(() => {
    setAvatar();

    const input = document.getElementById('avatar-upload') as HTMLInputElement;
    input?.addEventListener('change', () => {
      if (input?.files?.length == 1) {
        const file = input?.files[0];
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')
          uploadAvatar(input?.files[0]);
      }
    });
  }, []);

  const setAvatar = async () => {
    setLoading(true);
    try {
      await dispatch(getUserAvatar());
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const uploadAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      await dispatch(postUserAvatar(formData));
      await setAvatar();
    } catch (err) {
      console.log(err);
      showError?.(t({id: 'common.message.error'}));
    } finally {
      setLoading(false)
    }
  };

  const deleteAvatar = async () => {
    setLoading(true);
    try {
      await dispatch(deleteUserAvatar());
    } catch (err) {
      console.log(err);
      showError?.(t({id: 'common.message.error'}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{position: 'relative'}}>
        <input type="file" id="avatar-upload" style={{ display: 'none' }} />
        <div
          id='btn__avatar-pencil'
          onClick={() => setModalOpen(true)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 3
          }}
        >
          <Badge count={
            <div className={classes['pen-icon']}>
              <EditOutlined style={{ fontSize: 20 }} />
            </div>
          } />
        </div>
        <UserAvatar url={url} size={120} loading={loading}/>
      </div>
      <Modal
        centered
        closeIcon={<CloseOutlined id='btn__avatar-close' />}
        width={300}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={
          <Flex vertical>
            {url ?
              <label htmlFor="avatar-upload" id='btn__avatar-edit' style={{ zIndex: 1 }}>
                <div className={`${classes['edit-btn']} ${buttonClasses['button']} ${buttonClasses['button_primary']}`}>
                  {t({ id: 'buttons.edit' })}
                </div>
              </label> :
              <label htmlFor="avatar-upload" id='btn__avatar-upload' style={{ zIndex: 1 }}>
                <div className={`${classes['edit-btn']} ${buttonClasses['button']} ${buttonClasses['button_primary']}`}>
                  {t({ id: 'buttons.upload' })}
                </div>
              </label>
            }
            {url &&
              <BlackButton id='btn__avatar-delete' onClick={deleteAvatar}>
                {t({id: "buttons.delete"})}
              </BlackButton>
            }
          </Flex>
        }
      >
        <UserAvatar url={url} size={240} loading={loading} />
      </Modal>
    </>
  )
}

export default ProfileAvatar;