import storage from './storage';

const LAST_UPDATED_KEY = 'lastUpdated';
const MINUTES_BEFORE_UPDATE = 1;

export const setLastUpdated = () => {
  return storage.set(LAST_UPDATED_KEY, Math.floor(Date.now() / 1000));
};

export const getLastUpdated = () => {
  return storage.get(LAST_UPDATED_KEY);
};

export const getShouldUpdate = () => {
  const lastUpdated = getLastUpdated();
  return Date.now() / 1000 - Number(lastUpdated) > MINUTES_BEFORE_UPDATE * 60;
};
