// custom error messages
export const ERROR_MESSAGES = {
  NOT_ENOUGH: "modals.renew.error.fee.title",
  NOT_EQUAL: "schemas.match",
  INVALID_IBAN: 'schemas.iban.invalid',
  INVALID_ACCOUNT: 'schemas.account.invalid',
  INVALID_CODE: 'toasts.phone.error.code.title',
  OTHER: 'modals.renew.error.title',
};

export const ERROR_TYPES = {
  RIVER: 'river.api.error',
  NOT_ENOUGH: 'schemas.payment.create.amount.not.enough',
  INVALID_SCHEME: 'schemas.invalid'
}

// TODO: change error's recognition by message to recognition by id
// recognize river error's type by its message (for now)
export const RIVER_ERROR_TYPES = {
  INVALID_IBAN: 'Invalid IBAN',
  INVALID_ACCOUNT: 'Invalid account',
}