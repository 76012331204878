import { PURGE } from 'redux-persist/es/constants';
import { Action } from 'redux';
import { CardInfoT } from 'common/types/cards';
import { UserT } from 'common/types/user';
import { CardInfo } from 'ocean/types/cards';
import { ValidationMethod } from 'common/types/validation';

interface UserReducerInterface {
  meta: UserT;
  cards: CardInfoT[];
  currentCard: null | number;
  referralCode: string;
  avatar?: string; // avatar blob url
  securedRiver: boolean;
  securedOcean: boolean;
}

const initialState: UserReducerInterface = {
  meta: {
    language: 'EN',
  },
  cards: [],
  currentCard: null,
  referralCode: '',
  avatar: undefined,
  securedRiver: false,
  securedOcean: false,
};
type SetAction = {
  type: 'User/DANGEROUS_SET';
  payload: Partial<UserReducerInterface>;
};
type SetCardsAction = {
  type: 'User/SET_CARDS';
  payload: CardInfoT[];
};

type UpdateCardAction = {
  type: 'User/UPDATE_CARD';
  payload: CardInfo;
};

type SetMetaAction = {
  type: 'User/SET_META';
  payload: Partial<UserT>;
};

type SetAvatarAction = {
  type: 'User/SET_AVATAR';
  payload: string;
};

type AppendCardsAction = {
  type: 'User/APPEND_CARDS';
  payload: CardInfoT[];
};

type AppendMetaAction = {
  type: 'User/APPEND_META';
  payload: Partial<UserT>;
};

export type UserLogoutAction = {
  type: 'User/LOG_OUT';
  payload: unknown;
};

type ValidationLoginSetAction = {
  type: 'User/VALIDATION/LOGIN/SET';
  payload: ValidationMethod;
};

type ValidationConfirmationSetAction = {
  type: 'User/VALIDATION/CONFIRMATION/SET';
  payload: ValidationMethod;
}

export type UserSecurityBalanceRiver = {
  type: 'User/SECURITY_BALANCE_RIVER';
  payload: boolean;
};

export type UserSecurityBalanceOcean = {
  type: 'User/SECURITY_BALANCE_OCEAN';
  payload: boolean;
};

const userReducer = (
  state: UserReducerInterface = initialState,
  action:
    | SetAction
    | SetCardsAction
    | UpdateCardAction
    | SetMetaAction
    | SetAvatarAction
    | AppendCardsAction
    | AppendMetaAction
    | UserLogoutAction
    | ValidationLoginSetAction
    | ValidationConfirmationSetAction
    | UserSecurityBalanceRiver
    | UserSecurityBalanceOcean
    | Action<typeof PURGE>,
) => {
  switch (action.type) {
    case 'User/DANGEROUS_SET':
      return { ...state, ...action.payload };

    case 'User/SET_CARDS': {
      const newCurrentCard =
        state.currentCard === null && action.payload.length > 0
          ? action.payload[0].id ?? null
          : state.currentCard;

      return {
        ...state,
        cards: action.payload,
        currentCard: newCurrentCard,
      };
    }

    case 'User/UPDATE_CARD': {
      const newCard = action.payload;
      const updatedCards = state.cards.map((card) => {
        if (card.id !== newCard.id) return card;
        else return newCard;
      });

      return {
        ...state,
        cards: updatedCards,
      };
    }

    case 'User/SET_META':
      return { ...state, meta: { ...state.meta, ...action.payload } };

    case 'User/SET_AVATAR':
      return { ...state, avatar: action.payload };

    case 'User/APPEND_CARDS':
      return { ...state, cards: [...state.cards, ...action.payload] };

    case 'User/APPEND_META':
      return { ...state, meta: { ...state.meta, ...action.payload } };

    case PURGE:
      return {
        ...initialState,
        meta: {
          ...initialState,
          language: state.meta.language,
        },
      };

    case 'User/VALIDATION/LOGIN/SET': {
      return {
        ...state,
        meta: { ...state.meta, validateLogin: action.payload },
      };
    }

    case 'User/VALIDATION/CONFIRMATION/SET': {
      return {
        ...state,
        meta: { ...state.meta, validateConfirmation: action.payload },
      };
    }

    case 'User/LOG_OUT': {
      return initialState;
    }

    case 'User/SECURITY_BALANCE_RIVER': {
      return {
        ...state,
        securedRiver: action.payload
      }
    }
    case 'User/SECURITY_BALANCE_OCEAN': {
      return {
        ...state,
        securedOcean: action.payload
      }
    }

    default:
      return state;
  }
};

export default userReducer;
