import { ThunkAction } from 'redux-thunk';
import { RootState } from 'common/store';
import { Action } from 'redux';
import userApi from 'common/api/user';
import moment from 'moment';
import { message } from 'antd';
import { AxiosError } from 'axios';

export const createAccount = (formData: any): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({ type: 'App/SHOW_LOADER' });
  try {
    const { data: { response } } = await userApi.auth.createAccount({
      address: {
        city: formData.city,
        country: formData.country,
        line1: formData.address,
        line2: "",
        postalCode: formData.postalCode
      },
      dateOfBirth: moment({
        year: formData.dateOfBirth?.year,
        month: formData.dateOfBirth?.month ? formData.dateOfBirth.month - 1 : undefined,
        day: formData.dateOfBirth?.day }).format("DD-MM-YYYY"),
      email: formData.email,
      firstName: formData.name,
      'g-recaptcha-response': formData['g-recaptcha-response'],
      gender: formData.gender,
      language: 'EN',
      lastName: formData.surname,
      password: formData.password,
      phone: formData.phoneNumber,
      phoneCountry: formData.phoneCountry,
      privacyPolicy: true,
      termsAndConditions: true,
      agreeMonitored: true,
      utm: {},
    });

    dispatch({ type: 'User/SET_META', payload: {
        ...response,
        address: {
          city: formData.city,
          country: formData.country,
          line1: formData.address,
          line2: '',
          postalCode: formData.postalCode,
        },
      } });

    return response;
  } catch (e: any) {
    throw e.response?.data?.errors;
  } finally {
    dispatch({ type: 'App/HIDE_LOADER' });
  }
}