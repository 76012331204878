/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import appApi from 'common/api/app';
import userApi from 'common/api/user';

type ChangePhoneForm = {
  phoneCountry: number;
  phone: string;
};

export const changeEmail = async (email: string, reason?: string) => {
  try {
    const {
      data: { response },
    } = await userApi.settings.requestEmailChange(email, reason);

    return response;
  } catch (error) {
    if(isAxiosError(error)) throw error.response
    throw error;
  }
};

export const confirmEmailChange = async (formId: string) => {
  try {
    const { data: { response } } = await userApi.settings.confirmEmailChange(formId);
    return response;
  } catch (error) {
    if(isAxiosError(error)) throw error.response
    throw error;
  }
};
