import { RootState } from 'common/store';
import { CardInfoT } from 'common/types/cards';

export const getUserMeta = (state: RootState) => {
  return state.user.meta;
};

export const getIBAN = (state: RootState): string => {
  return state.user.cards[0].iban || '';
};

export const getTotalBalance = (state: RootState): number => {
  return state.user.cards.reduce<number>((sum, card) => sum + card.availBal, 0);
};

export const getCards = (state: RootState): CardInfoT[] => {
  return state.user.cards;
};

export const getCurrentCard = (state: RootState): number | null => {
  return state.user.currentCard;
};

export const getCurrentCardObject = (state: RootState): CardInfoT => {
  return state.user.cards.filter(card => card.id === state.user.currentCard)[0];
};

export const getAddress = (state: RootState) => {
  return getUserMeta(state).address;
};

export const getZipCode = (state: RootState) => {
  return getAddress(state)?.postalCode;
};