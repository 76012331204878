import React from 'react';
import { Button, Divider, Flex, Typography } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { OCEAN_TRANSACTION_STATUS } from 'ocean/lib/constants/values';
import classes from './Transactions.module.scss';
import { BlackButton } from 'common/components/UI';
import TransactionIcon from './components/TransactionIcon';
import { Transaction } from '../../../../types/transfer';
import { getTypeLabel } from './model/utils';

const { Text } = Typography;

export type TransactionItem = {
  date: string;
  transactions: Transaction[]
}

type TransactionsListProps = {
  transactions: TransactionItem[];
  loading: boolean;
  canLoadMore: boolean;
  onLoadMore: () => void;
  onDataSelect: (transaction: Transaction) => void;
}

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions,
  loading,
  onLoadMore,
  canLoadMore,
  onDataSelect,
}) => {
  const { formatMessage: t } = useIntl();

  const formatAmount = (amount: number) => {
    return amount < 0
      ? `${Number(amount).toFixed(2)}\u00A0€`
      : `+${Number(amount).toFixed(2)}\u00A0€`;
  };


  const renderTransactionItem = (item: TransactionItem, index: number) => {
    return (
      <Flex vertical>
        <Text strong>{moment(item.date, 'DD-MM-YYYY').format('LL')}</Text>
        {item.transactions.map(transaction => (
          <Flex key={transaction.transactionId} onClick={() => onDataSelect(transaction)} vertical className={classes['transaction']}>
            <Flex justify="space-between" align='center' style={{ padding: '8px' }}>
              <Flex gap={12} align="center">
                <TransactionIcon size={40} type={transaction.subType} status={transaction.status} />
                <Flex vertical gap={2}>
                  <Text className={classes['transaction__recipient']}>
                    {transaction.beneficiary}
                  </Text>
                  <Text className={classes['transaction__description']}>
                    {getTypeLabel(t,transaction.subType)} · {transaction.date?.slice(10)}
                  </Text>
                </Flex>
              </Flex>
              <Text  className={classes['no-break']} strong type={
                    transaction.status === OCEAN_TRANSACTION_STATUS.DECLINED
                      ? 'secondary'
                      : transaction.amount <= 0
                        ? 'danger'
                        : 'success'
                  }>
                {formatAmount(transaction.amount)}
              </Text>
            </Flex>
            <Divider style={{ margin: 0 }} />
          </Flex>
        ))}
        {index === transactions.length - 1 && canLoadMore && (
          <BlackButton onClick={onLoadMore} style={{ alignSelf: 'center', marginTop: 8 }}>
            Load more
          </BlackButton>
        )}
      </Flex>
    );
  };

  return (
    <Flex vertical gap={16}>
      {transactions.map((t, idx) => renderTransactionItem(t, idx))}
    </Flex>
  );
};

export default TransactionsList;
