import { ERROR_MESSAGES, ERROR_TYPES, RIVER_ERROR_TYPES } from '../constants/errors';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'react-router';
import { Response } from '../../../ocean/types/app';
import { ResponseError, RiverVariable } from '../../types/app';

const errorMessages = {
  [RIVER_ERROR_TYPES.INVALID_IBAN]: ERROR_MESSAGES.INVALID_IBAN,
  [RIVER_ERROR_TYPES.INVALID_ACCOUNT]: ERROR_MESSAGES.INVALID_ACCOUNT,
  [ERROR_TYPES.NOT_ENOUGH]: ERROR_MESSAGES.NOT_ENOUGH,
};

export const handleError = (
  e: unknown,
  getErrorMessage: (
    errorId: string,
    t: (descriptor: object, values?: object) => string,
  ) => string,
  t: (descriptor: object, values?: object) => string,
): string => {
  const axiosError = e as AxiosError;
  const axiosResponse = axiosError.response as ErrorResponse;

  if (axiosError && axiosResponse) {
    let { errors } = axiosResponse.data as Response;
    errors = errors as [ResponseError];
    const error = errors[0];
    const errorId = error.id;
    const errorField = error.field;

    if (errorField) {
      if (errorField.localeCompare('code') === 0 && errorId === ERROR_TYPES.INVALID_SCHEME)
        return t({ id: ERROR_MESSAGES.INVALID_CODE });
    }

    if (error.id === ERROR_TYPES.RIVER) {
      const riverError = error.variables as RiverVariable;

      return getErrorMessage(riverError['message: '], t) ?? t({ id: ERROR_MESSAGES.OTHER });
    }

    return getErrorMessage(error.id, t) ?? t({ id: ERROR_MESSAGES.OTHER });
  }

  return t({ id: ERROR_MESSAGES.OTHER });
};