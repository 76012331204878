import React, { useEffect, useState } from 'react';
import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import ListItem from '../ListItem';
import { UserT } from 'common/types/user';
import { Carousel, Drawer, Form, Modal } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { useIntl } from 'react-intl';
import { hideLoader, showLoader } from '../../../../store/actions/app';
import { getErrorDefaultMessage } from '../../../../lib/utils/helpers';
import { logout } from '../../../../store/actions/user';
import EmailInfoStep from './steps/Info';
import EmailRequestStep from './steps/Request';
import { CodeInputRef } from '../../../../components/UI/CodeInput/CodeInput';
import { CommonThunkDispatch } from '../../../../types/store';
import { useDispatch } from 'react-redux';
import { useMessage } from '../../../../lib/hooks/useMessage/useMessage';
import SumsubWebSdk from '@sumsub/websdk-react';
import { changeEmail, confirmEmailChange } from './actions';
import api from '../../../../../river/api';
import { ACCOUNT_STATUS } from '../../../../../river/lib/constants/values';

const emailChangeInitialState = {
  email: '',
  haveErrors: false,
};

type EmailProps = {
  userInfo: Partial<UserT>;
};

export type EmailChangeState = {
  email: string,
  reason?: string,
  haveErrors: boolean
}

const Email = ({ userInfo }: EmailProps) => {
  const { formatMessage: t } = useIntl();
  const dispatch: CommonThunkDispatch = useDispatch();
  const { showError, showSuccess } = useMessage();

  const [open, setOpen] = useState<boolean>(false);
  const [emailChangeForm, setEmailChangeForm] = useState<EmailChangeState>(emailChangeInitialState);
  const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');
  const [showSumSubModal, setShowSumSubModal] = useState(false);
  const [clientExists, setClientExists] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [formData, setFormData] = useState<Partial<{email: string}>>({email: emailChangeForm.email})
  const carouselRef = React.useRef<CarouselRef>(null);
  const email = userInfo.email;
  const isVerified = userInfo.verified;
  const codeInputRef = React.useRef<CodeInputRef>(null);
  const currentId = React.useRef<string>(null!);

  useEffect(() => {
    checkIfClientExists();
  }, [])

  const checkIfClientExists = async () => {
    try {
      const { data: { response } } = await api.user.getClientData();
      if (response.status === ACCOUNT_STATUS.ACTIVE)
        setClientExists(true);
    } catch (err: any) {
      if (err.response?.status !== 400)
        showError(t({id: 'common.message.error'}))
    }
  }

  const showDrawer = () => setOpen(true);
  const onCloseDrawer = () => {
    setOpen(false);
    carouselRef.current?.goTo(0);
    codeInputRef.current?.clear();
    form.resetFields();
  };

  const openSumSubModal = () => setShowSumSubModal(true);
  const closeSumSubModal = () => {
    onSubmit()
    setShowSumSubModal(false);
  }

  const onNextSlide = () => {
    if (carouselRef.current && carouselRef.current.next) {
      carouselRef.current.next();
    }
  };

  const onChangeEmail: React.MouseEventHandler<HTMLElement> = () => {
    dispatch(showLoader());
    changeEmail(emailChangeForm.email, emailChangeForm.reason)
    .then((response) => {
      currentId.current = String(response.id);

      if (response.type === 'EMAIL_KYC') {
        setSumSubAccessToken(response.verificationToken)
        openSumSubModal();
      }
    })
    .catch((error: any) => {
      const errors = error?.data?.errors;
      if (errors?.[0]?.field === 'email' && errors?.[0]?.id === "schemas.exists") {
        showError(getErrorDefaultMessage(error), 'toast__email-exists')
      } else {
        showError(getErrorDefaultMessage(error) || t({ id: 'common.message.error' }));
      }
    })
    .finally(() => {
      dispatch(hideLoader());
    });
  };

  const onSubmit = () => {
    dispatch(showLoader());

    confirmEmailChange(currentId.current)
    .then((response) => {
      if (String(response.status) === 'GRANTED') {
        if (response?.updateResults && !response?.updateResults?.clientUpdated) {
          showSuccess(t({id: 'river.user.account.sumsub.changeEmail.inProgress'}))
        } else {
          dispatch(logout())
          showSuccess(t({ id: 'profile.settings.email.success' }))
        }
      } else if (String(response.status) === 'REJECTED') {
        showError(t({id: 'profile.settings.request.rejected'}))
      } else {
        showError(t({id: 'common.message.error'}))
      }

      onCloseDrawer();
    })
    .catch((error: any) => {
      if (error?.data?.errors?.[0]?.id !== "errors.entry.not.found" && error?.data?.errors?.[0]?.id !== "verification.status") {
        codeInputRef.current?.clear();
        showError(getErrorDefaultMessage(error) || t({ id: 'common.message.error' }));
      }
    })
    .finally(() => {
      dispatch(hideLoader());
    });
  };

  return (
    <>
      <Modal closeIcon={<CloseOutlined id='btn__email-sumsub-close'/>} style={{zIndex: 1001}} footer={null} open={showSumSubModal} onCancel={closeSumSubModal} centered>
        <SumsubWebSdk
          accessToken={sumSubAccessToken}
          expirationHandler={closeSumSubModal}
        />
      </Modal>
      <div id='settings__email' onClick={showDrawer}>
        <ListItem
          title={t({id: "profile.settings.email.title"})}
          subtitle={email}
          verified={isVerified}
          iconComponent={
            <MailOutlined
              style={{
                fontSize: 24,
                padding: 12,
                boxSizing: 'content-box',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 100,
              }}
            />
          }
        />
      </div>
      <Drawer
        closeIcon={<CloseOutlined id='btn__email-close'/>}
        title={t({id: 'inputs.email'})}
        placement="right"
        width={512}
        onClose={onCloseDrawer}
        open={open}>
        <Carousel dots={false} infinite={false} ref={carouselRef}>
          <EmailInfoStep
            clientExists={clientExists}
            email={email || ''}
            isVerified={!!isVerified}
            onNextStep={onNextSlide}
          />
          {clientExists &&
            <EmailRequestStep
              form={form}
              formData={formData}
              emailChangeForm={emailChangeForm}
              setEmailChangeForm={setEmailChangeForm}
              onNextStep={onChangeEmail}
            />
          }
        </Carousel>
      </Drawer>
    </>
  );
};

export default Email;
