import React, { useState } from 'react';
import { CheckOutlined, CloseOutlined, GlobalOutlined } from '@ant-design/icons';
import ListItem from '../ListItem';
import { Drawer, Typography, List, Flex, Space } from 'antd';
import { Locale } from 'common/locales/types';
import userApi from 'common/api/user';
import classnames from '../Settings.module.css';
import { getFlagSvg } from './svg';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'common/store/actions/app';

const { Text } = Typography;

type LangItem = {
  id: Locale;
  name: string;
};

const LANGUAGE_LIST: LangItem[] = [
  {
    name: 'English',
    id: 'EN',
  },
  {
    name: 'Français',
    id: 'FR',
  },
  {
    name: 'Deutsch',
    id: 'DE',
  },
  {
    name: 'Latviski',
    id: 'LV',
  },
  {
    name: 'Italian',
    id: 'IT',
  },
  {
    name: 'Български',
    id: 'BG',
  },
  {
    name: 'Česky',
    id: 'CZ',
  },
  {
    name: 'Eesti',
    id: 'EE',
  },
  {
    name: 'Español',
    id: 'ES',
  },
  {
    name: 'Lietuviškai',
    id: 'LT',
  },
  {
    name: 'Polski',
    id: 'PL',
  },
  {
    name: 'Русский',
    id: 'RU',
  },
];

type LanguageProps = {
  locale: Locale;
  onSetLocale: (locale: Locale) => void;
  showError: (message: string, className?: string) => void;
  showSuccess: (message: string, className?: string) => void;
};

const Language = ({ locale, onSetLocale, showError, showSuccess }: LanguageProps) => {
  const { formatMessage: t } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const currentLanguage = LANGUAGE_LIST.find((lang) => lang.id === locale);

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const messageText = (locale: Locale) => {
    switch (locale) {
      case 'BG':
        return 'Езикът е запазен';
      case 'CZ':
        return 'Jazyk uložen';
      case 'DE':
        return 'Sprache Gespeichert';
      case 'EE':
        return 'Keelevalik on salvestatud';
      case 'EN':
        return 'Language saved';
      case 'ES':
        return 'Idioma guardado';
      case 'FR':
        return 'Langue enregistrée';
      case 'IT':
        return 'Language saved';
      case 'LT':
        return 'Kalba pakeista';
      case 'LV':
        return 'Valoda saglabāta';
      case 'NL':
        return 'Language saved';
      case 'PL':
        return 'Język został zmieniony';
      case 'RU':
        return 'Язык сохранен';
      default:
        return 'Language saved';
    }
  };

  const onSelectLanguage = (locale: Locale) => {
    dispatch(showLoader());

    userApi.settings
      .setLanguage(locale)
      .then(() => {
        onSetLocale(locale);
        showSuccess(
          messageText(locale),
          // t({ id: 'toasts.language.success.edit.title' }),
          'toast__language-change-success',
        );
      })
      .catch(() => {
        showError(t({ id: 'toasts.language.error.edit.title' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const renderListItem = (item: LangItem) => {
    const isSelected = locale === item.id;

    return (
      <List.Item
        id={`settings__language-${item.id.toLowerCase()}`}
        className={classnames['langugage-item']}
        onClick={() => void onSelectLanguage(item.id as Locale)}>
        <Flex justify="space-between">
          <Flex gap={12} justify="center" align="center">
            {getFlagSvg(item.id)}
            <Text className={classnames['langugage-item-text']}>{item.name}</Text>
          </Flex>

          {isSelected && <CheckOutlined />}
        </Flex>
      </List.Item>
    );
  };

  return (
    <>
      <div id="settings__language" onClick={showDrawer}>
        <ListItem
          title={t({ id: 'inputs.language' })}
          subtitle={currentLanguage?.name}
          iconComponent={
            <GlobalOutlined
              style={{
                fontSize: 24,
                padding: 12,
                boxSizing: 'content-box',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 100,
              }}
            />
          }
        />
      </div>
      <Drawer
        title={t({ id: 'inputs.language' })}
        placement="right"
        width={512}
        closeIcon={<CloseOutlined id="btn__language-close" />}
        onClose={onCloseDrawer}
        open={open}>
        <List
          itemLayout="vertical"
          dataSource={LANGUAGE_LIST}
          split={false}
          renderItem={renderListItem}
        />
      </Drawer>
    </>
  );
};

export default Language;
