import React, { useRef, useState } from 'react';
import { Button, Divider, Drawer, Flex, Form, Input, Typography } from 'antd';
import { hideLoader, showLoader } from 'common/store/actions/app';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentAccount, getCurrentCard } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './Activate.module.css';
import { useIntl } from 'react-intl';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import cardApi from 'river/api/card';
import { BlackButton, Push } from 'common/components/UI';
import FormItem from 'antd/es/form/FormItem';
import { general, generalFields } from 'common/lib/schemas/rules';
import { useNavigate } from 'react-router-dom';
import { refreshRiver } from 'river/store/actions';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';

type ActivateProps = {
  isOpen: boolean;
  onClose: () => unknown;
  showError?: (text: string) => unknown;
  showSuccess?: (text: string) => unknown;
};

const Activate: React.FC<ActivateProps> = ({
  isOpen,
  onClose,
  showError,
  showSuccess,
}) => {
  const { formatMessage: t } = useIntl();
  const dispatch: RiverThunkDispatch = useDispatch();
  const cardId = useSelector(getCurrentCard) || '';
  const account = useSelector(getCurrentAccount) || 0;
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;
  const shouldSendSms = !isBiometryEnabled && !isTwoFactorEnabled;

  const operationId = useRef<string>('');
  const [password, setPassword] = useState<string>('');
  const [cvn, setCVN] = useState<string>('');
  const [nextStep, setNextStep] = useState<boolean>(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const validationScheme = {
    cvn: [
      general(t)['required-field'],
      general(t)['min-length'](3),
      general(t)['max-length'](3),
      general(t)['only-numbers'],
    ],
    password: [general(t)['required-field'], ...generalFields(t)['3ds']],
  };

  const onSign = (code: string) => {
    const params = isTwoFactorEnabled ? { google2faCodeFromUser: code } : {};
    const body = isTwoFactorEnabled
      ? { staticPassword: password, cvn }
      : { code, staticPassword: password, cvn };

    dispatch(showLoader());
    cardApi
      .activatePCCardSignAdvanced(account, cardId, body, { params })
      .then(({ data: { response: card } }) => {
        navigate('/');
        dispatch(refreshRiver());
        showSuccess?.(t({ id: 'common.message.success' }));
        onCloseOverload();
      })
      .catch(() => {
        showError?.(t({ id: 'common.message.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onSendCode = () => {
    dispatch(showLoader());
    cardApi
      .activatePCCard(account, cardId)
      .then(({ response }) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }));
        }

        setNextStep(true);
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onResendCode = () => {
    dispatch(showLoader());
    cardApi
      .activatePCCardResend(account, cardId)
      .then((response) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }));
        }
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onCloseOverload = () => {
    setNextStep(false);
    setPassword('');
    setCVN('');
    onClose();
  };

  return (
    <Drawer
      title={t({ id: 'card.application.activate' })}
      open={isOpen}
      onClose={onCloseOverload}
      width={560}
      destroyOnClose>
      <Flex vertical justify="center" align="center" gap={16}>
        <Form form={form} onFinish={onSendCode} validateTrigger="onChange">
          <FormItem rules={validationScheme.cvn} name="cvn">
            <Input
              disabled={nextStep}
              placeholder="CVN"
              value={cvn}
              maxLength={3}
              onChange={(e) => {
                setCVN(e.target.value);
              }}
            />
          </FormItem>
          <FormItem rules={validationScheme.password} name="password">
            <Input
              disabled={nextStep}
              placeholder={t({ id: 'river.sheets.createCard.3ds.placeholder' })}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormItem>
          <Typography.Text className={classes.text}>
            {t({ id: 'river.sheets.createCard.3ds.hint' })}
          </Typography.Text>
        </Form>
        <BlackButton
          disabled={nextStep}
          type="primary"
          className={classes.button}
          onClick={form.submit}>
          {t({ id: 'card.application.activate' })}
        </BlackButton>
        {nextStep && (
          <>
            <Divider />
            {isBiometryEnabled ? (
              <Push onResend={onResendCode} />
            ) : (
              <>
                <Typography.Text className={classes.text}>
                  {t({
                    id: isTwoFactorEnabled
                      ? 'screens.auth.two.factor.legend'
                      : 'screens.payment.create.create.nav.phone.description',
                  })}
                </Typography.Text>
                <CodeInput
                  timeout={60}
                  onResend={isTwoFactorEnabled ? undefined : onResendCode}
                  onEnter={onSign}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </Drawer>
  );
};

export default Activate;
