import React from 'react';
import { Avatar, Flex } from 'antd';
import { ActivityIndicator } from '../index';
import { UserOutlined } from '@ant-design/icons';

type UserAvatarProps = {
  size: number,
  loading?: boolean,
  url?: string
}

const UserAvatar: React.FC<UserAvatarProps> = ({ size, loading, url }) => {
  return (
    <Flex id='header-avatar' align="center" justify="center" style={{ position: 'relative' }}>
      {loading &&
        <div style={{
          width: size,
          height: size,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
          backgroundColor: 'rgba(197,197,197,0.5)',
          borderRadius: '50%',
        }}>
          <ActivityIndicator />
        </div>
      }
      {url ?
        <Avatar
          size={size}
          src={url}
          style={{ display: 'flex', alignItems: 'center' }}
        /> :
        <Avatar size={size} icon={<UserOutlined />} />
      }
    </Flex>
  );
}

export default UserAvatar;