import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';
import {ReactComponent as InactiveCard} from '../../../../assets/svg/inactive-card.svg'

export const InactiveOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
      <InactiveCard />
      <Typography.Text className={classes['text']}>{t({id: 'components.card.status.inactive'})}</Typography.Text>
    </div>
  );
};
