import React, { useEffect, useState } from "react";
import { Flex, Form, List, Typography } from "antd";
import { BackButton, GridContainer, RoundContainer } from "common/components/UI";
import { Template, TemplateForm } from "../../../types/templates";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  RightOutlined
} from "@ant-design/icons";
import { useMessage } from "common/lib/hooks/useMessage/useMessage";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAccount } from "../../../store/selectors";
import { useIntl } from "react-intl";
import { AppDispatch } from "common/store";
import { createTemplate, deleteTemplate, editTemplate, getTemplates } from "../../../store/actions/templates";
import { CreateTemplateBody } from "../../../api/dto/template";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "common/components/AppRouter/AppRouter";
import AddTemplateModal from "./modals/AddTemplate";
import EditTemplateModal from "./modals/EditTemplate";
import DeleteTemplateModal from "./modals/DeleteTemplate";

const Templates = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [updateValues, setUpdateValues] = useState<Partial<CreateTemplateBody>>()

  const currentAccount = useSelector(getCurrentAccount);
  const { formatMessage: t } = useIntl();
  const navigate = useNavigate();
  const { showSuccess, showError } = useMessage();
  const dispatch = useDispatch<AppDispatch>();
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  useEffect(() => {
    fetchTemplates();
  }, [])

  useEffect(() => {
    updateForm.setFieldsValue(updateValues)
  }, [updateForm, updateValues])

  const openCreateModal = () => setShowCreateModal(true);
  const closeCreateModal = () => {
    setShowCreateModal(false);
    createForm.resetFields();
  }

  const openUpdateModal = (template: Template) => {
    setUpdateValues({
      title: template.title,
      description: template.description,
      iban: template.iban,
      accountId: template.accountId,
      amount: template.amount,
      type: 'SEPA',
      recipientName: template.recipientName,
    })
    setCurrentTemplate(template);
    setShowUpdateModal(true);
  }
  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  }

  const openDeleteModal = (template: Template) => {
    setCurrentTemplate(template);
    setShowDeleteModal(true);
  }
  const closeDeleteModal = () => setShowDeleteModal(false);

  const fetchTemplates = async () => {
    const templates = await dispatch(getTemplates());
    setTemplates(templates);
  }

  const onSubmit = async (data: TemplateForm) => {
    try {
      const { title, description, amount, recipientName, iban } = data;
      await dispatch(createTemplate({
        title,
        description,
        amount,
        recipientName,
        iban,
        accountId: currentAccount,
        type: 'SEPA',
      }))
      showSuccess(t({ id: 'screens.setting.setting.notifications.toast.success' }), 'toast__add-template-success');
      closeCreateModal();
      fetchTemplates();
    } catch (err) {
      showError(t({ id: 'common.message.error' }));
    }
  }

  const onDelete = async () => {
    if (!currentTemplate) return;

    try {
      await dispatch(deleteTemplate(currentTemplate.id));
      showSuccess(t({ id: 'screens.history.history.status.deleted' }), 'toast__delete-template-success');
      closeDeleteModal();
      fetchTemplates();
    } catch (err) {
      showError(t({ id: 'common.message.error' }));
    }
  }

  const onEdit = async (data: {
    title: string,
    description: string,
    amount: number,
    recipientName: string,
    iban: string
  }) => {
    if (!currentTemplate) return;

    try {
      const { title, description, amount, recipientName, iban } = data;
      await dispatch(editTemplate(currentTemplate.id, {
        title,
        description,
        amount,
        recipientName,
        iban,
        accountId: currentAccount,
        type: 'SEPA',
      }));
      showSuccess(t({ id: 'screens.setting.setting.notifications.toast.success' }), 'toast__add-template-success')
      closeUpdateModal();
      fetchTemplates();
    } catch (err: any) {
      showError(t({ id: 'common.message.error' }));
    }
  }

  return (
    <GridContainer>
      <BackButton />

      <AddTemplateModal
        form={createForm}
        open={showCreateModal}
        onClose={closeCreateModal}
        onSubmit={onSubmit}
      />

      <EditTemplateModal
        form={updateForm}
        open={showUpdateModal}
        onClose={closeUpdateModal}
        onSubmit={onEdit}
        initialValues={updateValues}
      />

      <DeleteTemplateModal
        open={showDeleteModal}
        onSubmit={onDelete}
        onClose={closeDeleteModal}
      />

      <Flex justify='space-between'>
        <Typography.Title level={3}
                          style={{ paddingBottom: 20 }}>{t({ id: 'sections.templates.title' })}</Typography.Title>
        <RoundContainer
          id='btn__add-template'
          size={40}
          pointer
          onClick={openCreateModal}
        >
          <PlusOutlined />
        </RoundContainer>
      </Flex>
      <List
        pagination={{
          position: 'bottom',
          align: 'center',
          pageSize: 10,
        }}
        dataSource={templates}
        renderItem={(template, idx) =>
          <List.Item key={idx} actions={[
            <Flex key={idx} gap={4}>
              <RoundContainer
                size={32}
                pointer
                id='btn__delete-template'
                onClick={() => openDeleteModal(template)}
                bgColor='#ffffff'
              >
                <DeleteOutlined style={{ color: 'black' }} />
              </RoundContainer>
              <RoundContainer
                size={32}
                pointer
                id='btn__edit-template'
                onClick={() => openUpdateModal(template)}
                bgColor='#ffffff'
              >
                <EditOutlined style={{ color: 'black' }} />
              </RoundContainer>
              <RoundContainer
                size={32}
                pointer
                onClick={() => {
                  navigate(ROUTES.RIVER_SEND_SEPA.path, { state: { currentTemplate: template } })
                }}
                bgColor='#ffffff'
              >
                <RightOutlined style={{ color: 'black' }} />
              </RoundContainer>

            </Flex>
          ]}>
            <List.Item.Meta
              title={
                <Typography.Text strong style={{ cursor: 'pointer' }} onClick={() => {
                  navigate(ROUTES.RIVER_SEND_SEPA.path, { state: { currentTemplate: template } })
                }}>
                  {template.title}
                </Typography.Text>
              }
              description={template.recipientName + ' | ' + t({ id: 'screens.payment.create.create.title' })}
            />
          </List.Item>
        }
      />
    </GridContainer>
  )
}

export default Templates;