import { UserT } from 'common/types/user';
import { ValidateProvider } from './context';
import ValidateSettings from './ValidateSettings';

const Validation = ({ userInfo }: { userInfo: Partial<UserT> }) => {
  return (
    <ValidateProvider userInfo={userInfo}>
      <ValidateSettings />
    </ValidateProvider>
  );
};

export default Validation;
