import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import { FILE_FORMAT } from '../../common/lib/constants/constants';
import { CreateSepaBody, SendBetweenAccountsBody, SepaTransferParams, TransferResponse } from './dto/transfers';

type TransferApi = {
  createSepa: (accountId: number, body: CreateSepaBody) => Promise<TransferResponse>;
  confirmSepa: (accountId: number, paymentId: number, amount: number) => Promise<TransferResponse>;
  signSepa: (accountId: number, paymentId: number, params: SepaTransferParams) => Promise<TransferResponse>;
  resendSepa: (accountId: number, paymentId: number) => Promise<TransferResponse>;
  sendBetween: (body: SendBetweenAccountsBody) => Promise<TransferResponse>;
  statement: {
    // request: (cardId: number, { start, end, type, status, size, page, language }: {
    //   start?: string, end?: string, type?: 'IN' | 'OUT' | 'ALL', status?: 'DECLINED' | 'SUCCESSFUL', size?: number, page?: number, language?: string
    // }) => Promise<any>;
    download: (accountId: number, { start, end, language, format }: {
      start?: string, end?: string, language?: string, format?: 'pdf' | 'csv',
    }) => Promise<any>;
    // downloadTransaction: (cardId: number, transactionId: string, { language }: { language?: string }) => Promise<any>;
  },
}

const transferApi: TransferApi = {
  createSepa(accountId, body) {
    return API.post(ENDPOINTS.TRANSFER_CTRL.CREATE_SEPA_PAYMENT(accountId), body);
  },

  confirmSepa(accountId, paymentId, amount) {
    return API.post(
      ENDPOINTS.TRANSFER_CTRL.CONFIRM_SEPA_PAYMENT(accountId, paymentId, amount),
    );
  },

  signSepa(accountId, paymentId, params) {
    return API.post(
      ENDPOINTS.TRANSFER_CTRL.SIGN_SEPA_PAYMENT(accountId, paymentId), {}, { params }
    );
  },

  resendSepa(accountId, paymentId) {
    return API.post(ENDPOINTS.TRANSFER_CTRL.RESEND_TOKEN(accountId, paymentId));
  },

  sendBetween(body) {
    return API.post(ENDPOINTS.TRANSFER_CTRL.TRANSFER, body);
  },

  statement: {
    download(accountId, { start, end, language, format = FILE_FORMAT.PDF }) {
      return API.get(ENDPOINTS.TRANSACTION_CTRL.DOWNLOAD(accountId, format), {
        params: {
          start, end, language,
        },
        headers: {
          'Content-Type': `application/${format}`,
        },
        responseType: 'blob',
      });
    },
  },
};

export default transferApi;
