import UseCountdown from 'common/lib/hooks/useCountdown';
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import classnames from './CodeInput.module.css';
import { Button, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';

const INPUT_LENGTH = 6;
const { Text } = Typography;

export interface CodeInputProps {
  timeout?: number;
  onEnter?: (value: string) => any;
  onResend?: () => any;

  // just for ids
  isPhone?: boolean;
  isEmail?: boolean;
}

export interface CodeInputRef {
  focus: () => void;
  clear: () => void;
  error: (errorText?: string) => void;
  success: () => void;
}

const CodeInput: React.ForwardRefRenderFunction<CodeInputRef, CodeInputProps> = (
  { timeout = 60, onEnter, onResend, isPhone, isEmail },
  ref,
) => {
  const { formatMessage: t } = useIntl();
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setFocused] = useState<boolean>(false);
  const [error, setError] = useState<string | boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [countdown, resetCountdown] = UseCountdown(timeout);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isResendDisabled = countdown !== 0;
  const digitsArray = new Array(INPUT_LENGTH).fill(0);

  const errorClass = error ? ` ${classnames['codeInput-error']}` : '';
  const successClass = success ? ` ${classnames['codeInput-success']}` : '';

  const getDigitClassName = (index: number): string => {
    const focusedDigit = isFocused ? inputValue.length : -1;

    if (
      focusedDigit === index ||
      (focusedDigit === INPUT_LENGTH && index === INPUT_LENGTH - 1)
    ) {
      return classnames['focused'];
    }

    return '';
  };

  const formatTime = (time: number): string => {
    const minutes: string = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    if (/^(\d*)$/.test(value) && value.length <= INPUT_LENGTH) {
      setInputValue(value);
      setSuccess(false);
      setError(false);
      if (value.length === INPUT_LENGTH) {
        onEnter?.(value);
        inputRef.current?.blur();
        setFocused(false);
      }
    }
  };

  const handleFocusRef = () => {
    inputRef.current?.focus();
    setFocused(true);
  };

  const handleBlurRef = () => {
    setFocused(false);
  };

  const handleResendClick = () => {
    onResend?.();
    resetCountdown(timeout);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setInputValue('');
      setFocused(false);
      setSuccess(false);
      setError(false);
    },
    error: (errorMessage) => {
      setError(errorMessage || true);
    },
    focus: () => {
      handleFocusRef();
    },
    success: () => {
      setSuccess(true);
    },
  }));

  return (
    <div className={classnames['codeInput-wrapper']}>
      <input
        ref={inputRef}
        id={isPhone ? 'input__phone-verification-code' : (isEmail ? 'input__email-verification-code' : 'input__verification-code')}
        type="text"
        inputMode="numeric"
        value={inputValue}
        className={classnames['codeInput-ref']}
        onBlur={handleBlurRef}
        onChange={handleInputChange}
      />
      <div
        className={`${classnames['codeInput-container']}${errorClass}${successClass}`}
        onClick={handleFocusRef}>
        {digitsArray.map((_, index) => (
          <div
            id={'input__digit' + index}
            key={index}
            className={`${classnames['codeInput-digit']} ${getDigitClassName(index)}`}>
            {inputValue[index] || ''}
          </div>
        ))}
      </div>
      {onResend &&
        (countdown > 0 ? (
          <Text
            style={{ opacity: isResendDisabled || error ? 1 : 0 }}
            type={error ? 'danger' : 'secondary'}>
            {error || t({ id: 'common.otp.resend.time' }, { time: formatTime(countdown) })}
          </Text>
        ) : (
          <Button
            style={{ padding: 0 }}
            type="link"
            id='link__resend'
            disabled={isResendDisabled}
            onClick={handleResendClick}>
            {t({ id: 'common.otp.resend' })}
          </Button>
        ))}
    </div>
  );
};

export default forwardRef(CodeInput);
