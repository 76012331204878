export const GTAG_EVENTS = {
  EMAIL_VERIFIED: {
    name: 'NEW: Email verified'
  },
  PHONE_VERIFIED: {
    name: 'NEW: Phone verified'
  },
  SUMSUB_SEND: {
    name: 'NEW: Send for verification'
  },
  SUMSUB_VERIFIED: {
    name: 'NEW: SumSub verified'
  }
}