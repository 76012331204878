import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './C2C.module.scss';
import { Flex, Form, MenuProps, message } from 'antd';
import { AppDispatch, RootState } from '../../../../common/store';
import { CardInfoT } from '../../../../common/types/cards';
import {
  BackButton,
  CenteredContainer,
  GridContainer,
} from '../../../../common/components/UI';
import { FormFinishInfo } from 'rc-field-form/lib/FormContext';
import { ERROR_MESSAGES } from '../../../lib/constants/errors';
import {
  confirmC2C,
  createC2C,
  resendC2CCode,
  signC2C,
} from '../../../store/actions/transfer';
import Beneficiary from './components/Beneficiary';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../common/components/AppRouter/AppRouter';
import { C2CForm } from './model/types';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import { handleError } from '../../../lib/utils/errorHandler';
import Amount from './components/Amount';
import Code from './components/Code';
import Success from './components/Success';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { useIntl } from 'react-intl';
import { getErrorMessage } from './model/errors';
import Declined from './components/Declined';
import { OCEAN_TRANSACTION_STATUS } from 'ocean/lib/constants/values';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';

const C2CPayment: React.FC = () => {
  const oceanCards = useSelector((state: RootState) => state.user.cards);
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;

  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();
  const [currentFrom, setCurrentFrom] = useState<CardInfoT>(oceanCards[0]);
  const [fromOptions, setFromOptions] = useState<MenuProps['items']>(
    mapCardsToOptions(oceanCards, setCurrentFrom),
  );
  const [paymentId, setPaymentId] = useState<number>(0);
  const [predictedFee, setPredictedFee] = useState<number>(0);
  const [formData, setFormData] = useState<Partial<C2CForm>>({});
  const [step, setStep] = useState<number>(0);
  const [paymentDeclined, setPaymentDeclined] = useState<boolean>(false);

  const resetForm = () => {
    form.resetFields();
    setStep(0);
  };

  const checkBeneficiaryErrors = () => {
    if (form.getFieldValue('amount') === 0) {
      showError(t({ id: ERROR_MESSAGES.INVALID_AMOUNT }));
      return 1;
    }

    if (form.getFieldValue('amount') > currentFrom.availBal) {
      showError(t({ id: ERROR_MESSAGES.NOT_ENOUGH }));
      return 1;
    }

    return 0;
  };

  const checkAmountErrors = () => {
    if (form.getFieldValue('amount') !== form.getFieldValue('confirmAmount')) {
      showError(
        t({ id: ERROR_MESSAGES.NOT_EQUAL }, { name: t({ id: 'noty.card.amount' }) }),
      );
      return 1;
    }

    return 0;
  };

  const toNextStep = () => {
    setStep((prev) => (prev < Object.keys(steps).length - 1 ? prev + 1 : prev));
  };

  const toPrevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleBeneficiarySubmit = () => {
    (async () => {
      if (checkBeneficiaryErrors()) return;

      const body = {
        to: form.getFieldValue('to'),
        amount: form.getFieldValue('amount'),
      };

      try {
        const response = await dispatch(createC2C(currentFrom.id, body));
        setPaymentId(response.id);
        setPredictedFee(response.predictedFee);

        toNextStep();
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const handleAmountSubmit = () => {
    (async () => {
      if (checkAmountErrors()) return;

      try {
        await dispatch(
          confirmC2C(currentFrom.id, paymentId, form.getFieldValue('confirmAmount') ?? 0),
        );

        toNextStep();
      } catch (err) {
        showError('');
      }
    })();
  };

  const handleCodeSubmit = (code: string) => {
    (async () => {
      try {
        const transaction = await dispatch(
          signC2C(currentFrom.id, paymentId, code, isTwoFactorEnabled),
        );

        if (transaction.status === OCEAN_TRANSACTION_STATUS.DECLINED)
          setPaymentDeclined(true);

        toNextStep();
        // await dispatch(refreshAccounts());
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const onEachFormFinish = (formName: string, formInfo: FormFinishInfo) => {
    const updatedFormData = { ...formData, ...formInfo.values };
    setFormData(updatedFormData);

    switch (formName) {
      case 'beneficiary':
        handleBeneficiarySubmit();
        break;
      case 'amount':
        handleAmountSubmit();
        break;
      default:
        return;
    }
  };

  const handleResend = () => {
    (async () => {
      try {
        await dispatch(resendC2CCode(currentFrom.id, paymentId));
        showSuccess(t({ id: 'notifications.codeSent.success' }));
      } catch (err) {
        showError('');
      }
    })();
  };

  const steps: Record<number, JSX.Element> = useMemo(
    () => ({
      0: (
        <Beneficiary
          form={form}
          fromOptions={fromOptions}
          currentFrom={currentFrom}
          formValues={formData}
          onCancel={() => navigate(ROUTES.OCEAN_SEND.path)}
        />
      ),
      1: (
        <Amount
          form={form}
          currentFrom={currentFrom}
          formValues={formData}
          onCancel={toPrevStep}
          fee={predictedFee}
        />
      ),
      2: (
        <Code
          isBiometryEnabled={isBiometryEnabled}
          isTwoFactorEnabled={isTwoFactorEnabled}
          onResend={handleResend}
          onSubmit={(code: string) => handleCodeSubmit(code)}
          onCancel={toPrevStep}
        />
      ),
      3: <Success amount={form.getFieldValue('amount')} resetForm={resetForm} />,
    }),
    [currentFrom, predictedFee],
  );

  if (paymentDeclined)
    return (
      <CenteredContainer>
        <Declined />
      </CenteredContainer>
    );

  return (
    <>
      <GridContainer>
        <div className={classes['wrapper']}>
          <BackButton />
          <Form.Provider onFormFinish={onEachFormFinish}>{steps[step]}</Form.Provider>
        </div>
      </GridContainer>
    </>
  );
};

export default C2CPayment;
