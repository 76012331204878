import React from 'react';
import { Flex, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ActionButton, BackButton, GridContainer } from 'common/components/UI';
import classes from './TopUp.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/components/AppRouter/AppRouter';
import EuroIcon from 'common/components/icons/Euro';
import CardIcon from 'common/components/icons/Card';
import SendIcon from 'common/components/icons/Send';
import { useIntl } from 'react-intl';

const { Title } = Typography;

const Index: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        <Flex gap="middle" vertical>
          <Title level={3}>{t({ id: 'sections.load.load.header' })}</Title>
          <Flex gap="middle" vertical>
            <ActionButton
              id='load__sepa'
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <EuroIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.load.load.tabs.sepa' })}
              onClick={() => navigate(ROUTES.RIVER_TOP_UP_SEPA.path)}
            />
            <ActionButton
              id='load__salary'
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <CardIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.load.load.tabs.salary' })}
              onClick={() => navigate(ROUTES.RIVER_TOP_UP_SALARY.path)}
            />
            <ActionButton
              id='load__invoice'
              icon={<RightOutlined />}
              prefix={
                <div className={classes['icon-container']}>
                  <SendIcon className={classes['icon']} />
                </div>
              }
              text={t({ id: 'sections.load.invoice.title' })}
              onClick={() => navigate(ROUTES.RIVER_TOP_UP_INVOICE.path)}
            />
          </Flex>
        </Flex>
      </div>
    </GridContainer>
  );
};

export default Index;
