import { CARD_STATUS } from 'river/lib/constants/values';
import { RootState } from 'common/store';
import { Card } from 'river/types';
import { getCurrentAccountObject, getAccountsArray } from './account';

export const getCurrentCard = (state: RootState) => {
  return state.river.currentCard;
};

export const getCurrentCardObject = (state: RootState) => {
  const currentCard = getCurrentCard(state);
  const currentAccountObject = getCurrentAccountObject(state);

  const foundCard = currentAccountObject?.cards?.find((card) => card.id === currentCard);
  if (foundCard) {
    return foundCard;
  }

  return {} as Card;
};

export const getCards = (state: RootState) => {
  const currentAccount = getCurrentAccountObject(state);

  return currentAccount && currentAccount.cards ? currentAccount.cards : [];
};

export const getAllCards = (state: RootState) => {
  const accounts = getAccountsArray(state);

  return accounts.reduce<Card[]>((cards, account) => {
    if (Array.isArray(account.cards)) {
      return [...cards, ...account.cards];
    }
    return cards;
  }, []);
};

export const getIsAddCardAvailable = (state: RootState) => {
  const allCardsArray = getAllCards(state);

  return (
    allCardsArray.filter((cardObj) => cardObj.status !== CARD_STATUS.CLOSED)
      .length < 5
  );
};
