import { ConfirmValidateUpdateParams } from 'common/api/dto/validation';
import userApi from 'common/api/user';
import { VALIDATION_METHOD, VALIDATION_TYPE } from 'common/lib/constants/validation';

const enableLoginNo = () =>
  userApi.settings.updateValidation({
    validationMethod: VALIDATION_METHOD.NO,
    validationType: VALIDATION_TYPE.LOGIN,
  });

const enableLoginTwoFactor = () =>
  userApi.settings.updateValidation({
    validationMethod: VALIDATION_METHOD.TWO_FA,
    validationType: VALIDATION_TYPE.LOGIN,
  });

export const loginActions = {
  enableNo: enableLoginNo,
  enableTwoFactor: enableLoginTwoFactor,
};

const enableConfirmationSms = () =>
  userApi.settings.updateValidation({
    validationMethod: VALIDATION_METHOD.SMS,
    validationType: VALIDATION_TYPE.CONFIRMATION,
  });

const enableConfirmationTwoFactor = () =>
  userApi.settings.updateValidation({
    validationMethod: VALIDATION_METHOD.TWO_FA,
    validationType: VALIDATION_TYPE.CONFIRMATION,
  });

export const confirmationActions = {
  enableSms: enableConfirmationSms,
  enableTwoFactor: enableConfirmationTwoFactor,
};

const confirmUpdate = (id: string | number, params: ConfirmValidateUpdateParams) =>
  userApi.settings.confirmUpdateValidation(id, params);

const resendUpdate = (id: string | number) => userApi.settings.resendUpdateValidation(id);

export const updateActions = {
  confirm: confirmUpdate,
  resend: resendUpdate,
};
