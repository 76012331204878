import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import { Flex, Space, Typography } from 'antd';
import React from 'react';
import { BlackButton, Push } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;

type CodeProps = {
  isTwoFactorEnabled: boolean;
  isBiometryEnabled?: boolean;
  onSubmit: (code: string) => void;
  onResend: () => any;
  onCancel: () => void;
};

const Code: React.FC<CodeProps> = ({
  isTwoFactorEnabled,
  isBiometryEnabled,
  onSubmit,
  onResend,
  onCancel,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Flex vertical gap={24}>
      {isBiometryEnabled ? (
        <Push onResend={onResend} />
      ) : (
        <>
          <Flex vertical gap={8}>
            <Title level={3}>
              {t({ id: 'screens.payment.create.create.nav.phone' })}
            </Title>
            <Text>
              {t({
                id: isTwoFactorEnabled
                  ? 'screens.auth.two.factor.legend'
                  : 'screens.payment.create.create.nav.phone.description',
              })}
            </Text>
          </Flex>
          <CodeInput onEnter={onSubmit} onResend={onResend} />
        </>
      )}
      <Space>
        <BlackButton
          id='#btn__cancel'
          onClick={onCancel}
        >
          {t({ id: 'button.simple.cancel' })}
        </BlackButton>
      </Space>
    </Flex>
  );
};

export default Code;
