import { Button, Result } from 'antd';
import { GridContainer } from 'common/components/UI';
import React, { Component } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

type ErrorBoundaryProps = {
  children: React.ReactElement;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Error caught in ErrorBoundary:', error, info);
  }

  handleRefresh() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <GridContainer>
          <Result
            status="error"
            title="Something went wrong"
            subTitle="Please, refresh page or try again later"
            extra={
              <Button type="default" key="refresh" onClick={this.handleRefresh}>
                Refresh
              </Button>
            }
          />
        </GridContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
