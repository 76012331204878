import React, { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from 'common/pages/Dashboard/Dashboard';
import SignIn from 'common/pages/Auth/SignIn/SignIn';
import SignUp from 'common/pages/Auth/SignUp/SignUp';
import Profile from 'common/pages/Profile';
import ResetPassword from 'common/pages/Auth/ResetPassword/ResetPassword';
import ResetPasswordConfirm from 'common/pages/Auth/ResetPasswordConfirm/ResetPasswordConfirm';
import AuthLayout from '../layout/AppLayout/AuthLayout';
import NonAuthLayout from '../layout/AppLayout/NonAuthLayout';
import VerifyEmail from 'common/pages/Auth/VerifyEmail/VerifyEmail';
import VerifyPhone from 'common/pages/Auth/VerifyPhone/VerifyPhone';
import Plans from 'common/pages/Plans/Plans';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store';
import OceanTopUp from 'ocean/pages/TopUp';
import OceanTopUpSepa from 'ocean/pages/TopUp/Sepa';
import OceanTopUpByBank from 'ocean/pages/TopUp/ByBank';
import OceanTopUpInvoice from 'ocean/pages/TopUp/Invoice';
import RiverTopUp from 'river/pages/TopUp';
import RiverTopUpSepa from 'river/pages/TopUp/Sepa';
import RiverTopUpSalary from 'river/pages/TopUp/Salary';
import RiverTopUpInvoice from 'river/pages/TopUp/Invoice';
import OceanSend from '../../../ocean/pages/Send';
import OceanSendC2C from '../../../ocean/pages/Send/CardToCard';
import OceanSendSepa from '../../../ocean/pages/Send/Sepa';
import OceanSendInternational from '../../../ocean/pages/Send/InternationalPayment';
import RiverSend from '../../../river/pages/Send';
import RiverSendBetweenAccounts from '../../../river/pages/Send/BetweenAccounts';
import RiverSendSepa from '../../../river/pages/Send/Sepa';
import RiverAccount from 'river/pages/Account';
import RiverCard from 'river/pages/Card';
import OceanAccount from 'ocean/pages/Account';
import OceanCard from 'ocean/pages/Cards';
import BlockCard from 'river/pages/BlockCard';
import NotFound from 'common/pages/NotFound/NotFound';
import AuthorizedRoute from './AuthorizedRoute';
import Notifications from '../../pages/Notifications/Notifications';
import TwoFactor from 'common/pages/Auth/TwoFactor/TwoFactor';
import Templates from '../../../river/pages/Send/Templates';

export type RouteConfig = {
  key: string;
  path: string;
  element: ReactNode;
};

export const ROUTES = Object.freeze({
  NOT_FOUND: {
    key: 'not_found',
    path: 'not-found',
  },
  DASHBOARD: {
    key: 'dashboard',
    path: '/',
  },
  SIGN_IN: {
    key: 'sign_in',
    path: '/auth/signin',
  },
  SIGN_UP: {
    key: 'sign_up',
    path: '/auth/signup',
  },
  RESET_PASSWORD: {
    key: 'reset_password',
    path: '/auth/reset_password',
  },
  RESET_PASSWORD_CONFIRM: {
    key: 'reset_password_confirm',
    path: '/auth/password/:token',
  },
  VERIFY_EMAIL: {
    key: 'verify_email',
    path: '/auth/signup/verify_email',
  },
  VERIFY_PHONE: {
    key: 'verify_phone',
    path: '/auth/signup/verify_phone',
  },
  TWO_FACTOR: {
    key: '2fa',
    path: '/auth/two-factor'
  },
  PROFILE: {
    key: 'profile',
    path: '/profile',
  },
  NOTIFICATIONS: {
    key: 'notifications',
    path: '/notifications',
  },
  TEMPLATES: {
    key: 'templates',
    path: '/pro/send/templates',
  },
  PLANS: {
    key: 'plans',
    path: '/plans',
  },
  OCEAN_ACCOUNT: {
    key: 'ocean',
    path: '/standart',
  },
  OCEAN_CARD: {
    key: 'ocean',
    path: '/standart/card/:cardId',
  },
  OCEAN_TOP_UP: {
    key: 'ocean-top-up',
    path: '/standart/top-up',
  },
  OCEAN_TOP_UP_SEPA: {
    key: 'ocean-top-up-sepa',
    path: '/standart/top-up/sepa',
  },
  OCEAN_TOP_UP_BY_BANK: {
    key: 'ocean-top-up-by-bank',
    path: '/standart/top-up/bank',
  },
  OCEAN_TOP_UP_INVOICE: {
    key: 'ocean-top-up-invoice',
    path: '/standart/top-up/invoice',
  },
  RIVER_TOP_UP: {
    key: 'river-top-up',
    path: '/pro/top-up',
  },
  RIVER_TOP_UP_SEPA: {
    key: 'river-top-up-sepa',
    path: '/pro/top-up/sepa',
  },
  RIVER_TOP_UP_SALARY: {
    key: 'river-top-up-salary',
    path: '/pro/top-up/salary',
  },
  RIVER_TOP_UP_INVOICE: {
    key: 'river-top-up-invoice',
    path: '/pro/top-up/invoice',
  },
  OCEAN_SEND: {
    key: 'ocean-send',
    path: '/standart/send',
  },
  OCEAN_SEND_C2C: {
    key: 'ocean-send-c2c',
    path: '/standart/send/card-to-card',
  },
  OCEAN_SEND_SEPA: {
    key: 'ocean-send-sepa',
    path: '/standart/send/sepa',
  },
  OCEAN_SEND_INTERNATIONAL: {
    key: 'ocean-send-international',
    path: '/standart/send/international-payment',
  },
  RIVER_SEND: {
    key: 'river-send',
    path: '/pro/send',
  },
  RIVER_SEND_BETWEEN_ACCOUNTS: {
    key: 'river-send-between-accounts',
    path: '/pro/send/between-accounts',
  },
  RIVER_SEND_SEPA: {
    key: 'river-send-sepa',
    path: '/pro/send/sepa',
  },
  RIVER_ACCOUNT: {
    key: 'river-account',
    path: '/pro/:id',
  },
  RIVER_CARD: {
    key: 'river-card',
    path: '/pro/:id/card/:cardId',
  },
  RIVER_BLOCK: {
    key: 'river-card-block',
    path: '/pro/:id/card/:cardId/block',
  },
});

const authorizedRoutes: RouteConfig[] = [
  { ...ROUTES.DASHBOARD, element: <Dashboard /> },
  { ...ROUTES.PROFILE, element: <Profile /> },
  { ...ROUTES.NOTIFICATIONS, element: <Notifications /> },
  { ...ROUTES.TEMPLATES, element: <Templates /> },
  { ...ROUTES.OCEAN_ACCOUNT, element: <OceanAccount /> },
  { ...ROUTES.OCEAN_CARD, element: <OceanCard /> },
  { ...ROUTES.OCEAN_TOP_UP, element: <OceanTopUp /> },
  { ...ROUTES.OCEAN_TOP_UP_SEPA, element: <OceanTopUpSepa /> },
  { ...ROUTES.OCEAN_TOP_UP_BY_BANK, element: <OceanTopUpByBank /> },
  { ...ROUTES.OCEAN_TOP_UP_INVOICE, element: <OceanTopUpInvoice /> },
  { ...ROUTES.RIVER_TOP_UP, element: <RiverTopUp /> },
  { ...ROUTES.RIVER_TOP_UP_SEPA, element: <RiverTopUpSepa /> },
  { ...ROUTES.RIVER_TOP_UP_SALARY, element: <RiverTopUpSalary /> },
  { ...ROUTES.RIVER_TOP_UP_INVOICE, element: <RiverTopUpInvoice /> },
  { ...ROUTES.OCEAN_SEND, element: <OceanSend /> },
  { ...ROUTES.OCEAN_SEND_C2C, element: <OceanSendC2C /> },
  { ...ROUTES.OCEAN_SEND_SEPA, element: <OceanSendSepa /> },
  { ...ROUTES.OCEAN_SEND_INTERNATIONAL, element: <OceanSendInternational /> },
  { ...ROUTES.RIVER_SEND, element: <RiverSend /> },
  { ...ROUTES.RIVER_SEND_BETWEEN_ACCOUNTS, element: <RiverSendBetweenAccounts /> },
  { ...ROUTES.RIVER_SEND_SEPA, element: <RiverSendSepa /> },
  { ...ROUTES.PLANS, element: <Plans /> },
  { ...ROUTES.RIVER_ACCOUNT, element: <RiverAccount /> },
  { ...ROUTES.RIVER_CARD, element: <RiverCard /> },
  { ...ROUTES.RIVER_BLOCK, element: <BlockCard /> },
];
const unauthorizedRoutes: RouteConfig[] = [
  { ...ROUTES.SIGN_IN, element: <SignIn /> },
  { ...ROUTES.SIGN_UP, element: <SignUp /> },
  { ...ROUTES.RESET_PASSWORD, element: <ResetPassword /> },
  { ...ROUTES.RESET_PASSWORD_CONFIRM, element: <ResetPasswordConfirm /> },
  { ...ROUTES.VERIFY_EMAIL, element: <VerifyEmail /> },
  { ...ROUTES.VERIFY_PHONE, element: <VerifyPhone /> },
  { ...ROUTES.TWO_FACTOR, element: <TwoFactor /> },
];

const commonRoutes: RouteConfig[] = [
  { ...ROUTES.NOT_FOUND, element: <NotFound /> },
  { ...ROUTES.PLANS, element: <Plans /> },
];

const AppRouter: React.FC = () => {
  const { id, verified, phoneVerified } = useSelector(
    (state: RootState) => state.user.meta,
  );
  const isAuthenticated = id && verified && phoneVerified;
  return (
    <Routes>
      {/* Common routes */}
      <Route element={<NonAuthLayout />}>
        {commonRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
      </Route>
      {/* Public routes */}
      <Route element={<NonAuthLayout />}>
        <Route
          element={
            <AuthorizedRoute
              isAuthenticated={!isAuthenticated}
              redirectPath={ROUTES.DASHBOARD.path}
            />
          }>
          {unauthorizedRoutes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>
      {/* Private routes */}
      <Route element={<AuthLayout />}>
        <Route element={<AuthorizedRoute isAuthenticated={!!isAuthenticated} />}>
          {authorizedRoutes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>
      {/* Редирект для несуществующих маршрутов */}
      <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND.path} />} />
    </Routes>
  );
};

export default AppRouter;
