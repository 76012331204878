/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import appApi from 'common/api/app';
import userApi from 'common/api/user';

type ChangePhoneForm = {
  phoneCountry: number;
  phone: string;
};

export const getPhoneCountries = async () => {
  try {
    return await appApi.getCountries();
  } catch (error) {
    throw error;
  }
};

export const changePhone = async (phoneCountry: number, phone: string, reason?: string) => {
  try {
    const {
      data: { response },
    } = await userApi.settings.requestPhoneChange(phoneCountry, phone, reason);

    return response;
  } catch (error) {
    if(isAxiosError(error)) throw error.response
    throw error; 
  }
};

export const confirmChangePhone = async (formId: string, code?: string) => {
  try {
    const { data: { response } } = await userApi.settings.confirmPhoneChange(formId, code);
    return response;
  } catch (error) {
    if(isAxiosError(error)) throw error.response
    throw error;
  }
};
