import React, { HTMLAttributes, ReactElement, ReactNode, useState } from 'react';
import classes from './PlanContainer.module.scss';
import { Button, Typography, Watermark } from 'antd';
import { ActivityIndicator } from 'common/components/UI';
import { colorToHex, combineClasses, splitBalance } from 'common/lib/utils/helpers';
import { RxCaretDown } from 'react-icons/rx';
import { BlackButton } from '../UI';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { VERIFICATION_STATUS } from 'ocean/lib/constants/values';
import { VerificationStatus } from 'river/types';


interface PlanContainerProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  active: boolean;
  availBal?: number;
  gradient: Array<string>;
  loading?: {
    active?: boolean;
    message?: string;
  };
  disabled?: boolean;
  watermark?: string;
  available?: boolean;
  benefits: Array<string>;
  bgImage?: any;
  items?: Array<object>;
  renderItems?: (item: any, secured?: boolean) => React.ReactNode;
  onCreateAccount?: () => void;
  footer?: ReactNode | (() => ReactNode);
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => any;
  secured?: boolean;
  setSecured: (s: boolean) => void;
}
const PlanContainer: React.FC<PlanContainerProps> = ({
  title,
  footer,
  onCreateAccount,
  renderItems,
  items,
  bgImage,
  active,
  benefits,
  available,
  watermark,
  disabled,
  loading,
  availBal,
  onClick,
  gradient,
  id,
  secured,
  setSecured,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [wholePart, fractionalPart] = splitBalance(availBal);

  const onExpand = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <div {...props} className={combineClasses(classes.wrapper, props.className)}>
      <Watermark
        style={{ borderRadius: 24, overflow: 'hidden' }}
        content={watermark}
        gap={[10, 10]}>
        <div
          id={id + '-plan-container'}
          onClick={active ? onExpand : onClick}
          className={classes.container}
          style={
            bgImage
              ? { backgroundImage: `url(${bgImage})` }
              : {
                  background: `linear-gradient(135.00deg, ${gradient[0]} 0%,${gradient[1]} 100%)`,
                }
          }>
          <Typography.Text ellipsis className={classes.title}>
            {title.toUpperCase()}
          </Typography.Text>

          {active ? (
            <div className={classes.secondRow}>
              <div className={classes.head}>
                <Typography.Text className={classes.balance} id={secured ? 'plan-balance-hidden' : 'plan-balance-shown'}>
                  {secured ? '*' : wholePart},
                  <Typography.Text className={classes.fractionalPart}>
                    {secured ? '**' : fractionalPart} €
                  </Typography.Text>
                </Typography.Text>
                <div
                  style={{ zIndex: 9 }}
                  id={id ?`btn__${id}-show-balance` : ''}
                  className={classes.caretIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSecured(!secured);
                  }}>
                  {secured ? (
                    <EyeOutlined className={classes.icon} />
                  ) : (
                    <EyeInvisibleOutlined className={classes.icon} />
                  )}
                </div>
              </div>
              {items && items.length > 0 && (
                <div
                  onClick={onExpand}
                  className={combineClasses(
                    classes.caretIcon,
                    expanded && classes.expanded,
                  )}>
                  <RxCaretDown />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.benefitsContainer}>
              {benefits.length !== 0 &&
                benefits.map((benefit, index) => (
                  <Typography.Text className={classes.text} key={index}>
                    {benefit}
                  </Typography.Text>
                ))}
              {onCreateAccount && (
                <div className={classes.actionsContainer}>
                  <BlackButton
                    onClick={onCreateAccount}
                    type="text"
                    style={{ width: '100%', backgroundColor: 'white' }}>
                    Open account
                  </BlackButton>
                </div>
              )}
            </div>
          )}

          {footer ? (typeof footer === 'function' ? footer() : footer) : null}

          {(disabled || loading?.active || available === false) && (
            <div className={classes.disabledOverlay}>
              {available === false ? (
                <div className={classes.notAvailableContainer}>
                  <Typography.Text className={classes.notAvailableText}>
                    NOT AVAILABLE
                  </Typography.Text>
                </div>
              ) : (
                <>
                  {loading?.active && (
                    <div className={classes.loadingContainer}>
                      <div className={classes.indicatorContainer}>
                        <ActivityIndicator />
                      </div>
                      {loading?.message && (
                        <Typography.Text className={classes.message}>
                          {loading?.message}
                        </Typography.Text>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Watermark>
      {items && items.length > 0 && (
        <div
          className={combineClasses(classes.itemsContainer, expanded && classes.expanded)}
          style={{ background: `${colorToHex(gradient[0])}55` }}>
          {renderItems && renderItems(items, secured)}
          {/*{renderItem && items.map((item, index) => (*/}
          {/*  <React.Fragment key={index}>*/}
          {/*    {renderItem(item)}*/}
          {/*  </React.Fragment>*/}
          {/*))}*/}
        </div>
      )}
    </div>
  );
};

PlanContainer.defaultProps = {
  title: 'NO NAME',
  gradient: ['rgb(224, 204, 251)', 'rgb(195, 194, 251)'],
  available: true,
};
export default React.memo(PlanContainer);
