import {
  ValidationMethod,
  ValidationType,
  ValidationUpdateStatus,
} from 'common/types/validation';
import { createContext } from 'react';

export type UpdateParams = {
  id: string | number;
  secret?: string;
  type: ValidationType;
  method: ValidationMethod;
  status: ValidationUpdateStatus;
};

type ValidationContextType = {
  email: string;
  updateParams?: UpdateParams;
  validateLogin?: ValidationMethod;
  validateConfirmation?: ValidationMethod;
  isOpenLogin: boolean;
  isOpenConfirmation: boolean;
  isOpenUpdate: boolean;
  onOpenLogin(): void;
  onCloseLogin(): void;
  onOpenConfirmation(): void;
  onCloseConfirmation(): void;
  onOpenUpdate(params: UpdateParams): void;
  onCloseUpdate(): void;
};

export const ValidationContext = createContext<ValidationContextType>({
  email: '',
  updateParams: undefined,
  validateLogin: undefined,
  validateConfirmation: undefined,
  isOpenLogin: false,
  isOpenConfirmation: false,
  isOpenUpdate: false,
  onOpenUpdate: () => undefined,
  onCloseUpdate: () => undefined,
  onOpenLogin: () => undefined,
  onCloseLogin: () => undefined,
  onOpenConfirmation: () => undefined,
  onCloseConfirmation: () => undefined,
});
