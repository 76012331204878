import { IntlFormatMessageFunc } from 'common/types/app';

export const validateClientAddress =
  (t: IntlFormatMessageFunc) =>
  ({ getFieldValue }: { getFieldValue: any }) => ({
    validator(_: any, value: any) {
      if (value) {
        // Проверка минимального количества слов
        const words = value.trim().split(/[,\s]+/);
        if (words.length < 2) {
          return Promise.reject(
            new Error(t({ id: 'schemas.address.invalid' })),
          );
        }

        // Проверка наличия хотя бы одного слова, не являющегося цифрой
        const containsNonDigitWord = words.some((word: string) => isNaN(+word));
        if (!containsNonDigitWord) {
          return Promise.reject(
            new Error(t({ id: 'schemas.address.invalid' })),
          );
        }
      }

      // Проверка, что город и адрес не одинаковы
      const city = getFieldValue('city');
      if (value === city) {
        return Promise.reject(
          new Error(t({ id: 'schemas.address.invalid' })),
        );
      }

      return Promise.resolve();
    },
  });
