import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10 20C15.4706 20 20 15.4706 20 10C20 4.53922 15.4608 0 9.9902 0C4.52941 0 0 4.53922 0 10C0 15.4706 4.53922 20 10 20ZM15.0686 6.56863C15.0686 7 14.7745 7.31373 14.3431 7.31373H8.76471L7.11765 7.23529L8.31373 8.2549L8.89216 8.78431C9.02941 8.90196 9.10784 9.07843 9.10784 9.29412C9.10784 9.69608 8.79412 10.0098 8.39216 10.0098C8.18627 10.0098 8.01961 9.94118 7.88235 9.80392L5.21569 7.21569C5.01961 7.0098 4.94118 6.79412 4.94118 6.56863C4.94118 6.36275 5.01961 6.15686 5.21569 5.96078L7.88235 3.35294C8.01961 3.22549 8.18627 3.15686 8.39216 3.15686C8.79412 3.15686 9.10784 3.47059 9.10784 3.87255C9.10784 4.08824 9.02941 4.2549 8.89216 4.38235L8.31373 4.92157L7.12745 5.91177L8.76471 5.84314H14.3431C14.7745 5.84314 15.0686 6.14706 15.0686 6.56863ZM15.0686 13.1765C15.0686 13.3824 14.9902 13.5882 14.7941 13.7843L12.1275 16.3922C11.9902 16.5294 11.8235 16.5882 11.6176 16.5882C11.2157 16.5882 10.902 16.2843 10.902 15.8824C10.902 15.6667 10.9804 15.5 11.1176 15.3627L11.6961 14.8235L12.8824 13.8333L11.2451 13.902H5.66667C5.23529 13.902 4.94118 13.6078 4.94118 13.1765C4.94118 12.7451 5.23529 12.4314 5.66667 12.4314H11.2451L12.8922 12.5098L11.6961 11.4902L11.1176 10.9608C10.9804 10.8529 10.902 10.6667 10.902 10.4608C10.902 10.049 11.2157 9.7451 11.6176 9.7451C11.8235 9.7451 11.9902 9.80392 12.1275 9.94118L14.7941 12.5392C14.9902 12.7353 15.0686 12.951 15.0686 13.1765Z"
    />
  </svg>
);

const Transfer = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Transfer;
