import React, { useState } from "react";
import { Flex, Form, FormInstance, Input, InputNumber, Modal } from "antd";
import { BlackButton } from "../../../../../common/components/UI";
import { general } from "../../../../../common/lib/schemas/rules";
import { getTemplateScheme } from "../model/scheme";
import { useIntl } from "react-intl";
import { CreateTemplateBody } from "../../../../api/dto/template";
import { TemplateForm } from "../../../../types/templates";
import { CloseOutlined } from "@ant-design/icons";

type EditTemplateProps = {
  form: FormInstance;
  open: boolean;
  onClose: () => unknown;
  onSubmit: (data: TemplateForm) => unknown;
  initialValues?: Partial<CreateTemplateBody>;
}

const EditTemplateModal: React.FC<EditTemplateProps> = ({form, open, onClose, onSubmit, initialValues}) => {
  const { formatMessage: t } = useIntl();
  const rules = getTemplateScheme(t);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    beneficiary: [],
    iban: [],
    amount: [],
    description: [],
  });

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      beneficiary: form.getFieldError('recipientName'),
      iban: form.getFieldError('iban'),
      amount: form.getFieldError('amount'),
      description: form.getFieldError('description'),
    }))
  }

  return (
    <Form initialValues={initialValues} form={form} validateTrigger='onChange' onFinish={onSubmit} onFieldsChange={onFieldsChange}>
      <Modal
        closeIcon={<CloseOutlined id='btn__edit-templates-close' />}
        centered
        title={t({ id: 'sections.templates.buttons.edit' })}
        open={open}
        onCancel={onClose}
        footer={
          <Flex justify='center'>
            <BlackButton id='btn__edit-template-submit' type='primary' onClick={form.submit}>{t({ id: 'buttons.edit' })}</BlackButton>
          </Flex>
        }
      >
        <Flex vertical justify='center' style={{ paddingTop: 30 }}>
          <Form.Item rules={[general(t)['required-field']]} name='title'>
            <Input placeholder={t({ id: 'inputs.title' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__beneficiary">{validationErrors.beneficiary[0]}</div>}
            rules={rules.beneficiary}
            name='recipientName'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.beneficiary' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__iban">{validationErrors.iban[0]}</div>}
            rules={rules.iban}
            name='iban'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.iban' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__amount">{validationErrors.amount[0]}</div>}
            rules={rules.amount}
            name='amount'
          >
            <InputNumber placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.amount' })} />
          </Form.Item>
          <Form.Item
            help={<div id="error__description">{validationErrors.description[0]}</div>}
            rules={rules.description}
            name='description'
          >
            <Input placeholder={t({ id: 'screens.payment.create.beneficiary.inputs.details' })} />
          </Form.Item>
        </Flex>
      </Modal>
    </Form>
  )
}

export default EditTemplateModal;