import {
  BackButton,
  CenteredContainer,
  GreyContainer,
  GridContainer, RoundContainer,
} from 'common/components/UI';
import { Flex, MenuProps, Typography } from 'antd';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import classes from './TopUpSalary.module.scss';
import { getAccounts, getCurrentAccount } from '../../../store/selectors';
import { Account } from '../../../types';
import AccountDropdown from '../../../components/AccountDropdown/AccountDropdown';
import { RootState } from 'common/store';
import { mapAccountsToOptions } from '../../../components/AccountDropdown/actions';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { CopyOutlined } from '@ant-design/icons';
import { BIC } from '../../../lib/constants/values';

const { Title, Text } = Typography;

const TopUpSalary: React.FC = () => {
  const riverAccounts = useSelector(getAccounts);
  const currentAccount = useSelector(getCurrentAccount);

  const client = useSelector((state: RootState) => state.river.meta);

  const { formatMessage: t } = useIntl();
  const { showError, showSuccess } = useMessage();

  const [currentOption, setCurrentOption] = useState<Account>(
    currentAccount ? riverAccounts[currentAccount] : Object.values(riverAccounts)[0],
  );
  const [options, setOptions] = useState<MenuProps['items']>(
    mapAccountsToOptions(riverAccounts, setCurrentOption),
  );

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccess(t({ id: 'common.clipboard.success' }));
      })
      .catch(() => {
        showError(t({ id: 'common.clipboard.error' }));
      });
  };

  const copiedText = () => {
    return `
    ${t({ id: 'sections.load.load.salary.text.hello' })}
    ${t({ id: 'sections.load.load.salary.text.description' })},\n
    ${t({ id: 'sections.load.load.salary.text.beneficiary' })} ${client.title}
    ${t({ id: 'sections.load.load.salary.text.iban' })} ${currentOption.iban}
    ${t({ id: 'sections.load.load.salary.text.bic' })} ${BIC} \n
    ${t({id: 'sections.load.load.salary.text.thank',
    })}
    ${client.title}
    `
  }

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.load.salary.header' })}</Title>
          <AccountDropdown
            label={`${t({ id: 'components.accountsDropdown.header' })}:`}
            options={options}
            id='account'
            currentOption={currentOption}
          />
          <Text>{t({ id: 'sections.load.load.salary.description' })}</Text>
          <GreyContainer
          // title={t({ id: 'sections.load.load.salary.text.hello' })}
            action={
              <RoundContainer
                size={32}
                id='copy-icon'
                key="show-statement-action"
                onClick={() => onCopy(copiedText())}
                bgColor='transparent'
                pointer
              >
                <CopyOutlined
                  style={{ fontSize: 20}}
                  onClick={() => onCopy(copiedText())}
                />
              </RoundContainer>
            }>
            <Flex vertical gap={12}>
          
              <Text className={classes.text}>
              {t({ id: 'sections.load.load.salary.text.hello' })}
              </Text>
              <Text className={classes.text}>
                {t({ id: 'sections.load.load.salary.text.description' })}
              </Text>
              <Text className={classes.text}>{t({ id: 'sections.load.load.salary.text.beneficiary' })} {client.title}</Text>
              <Text className={classes.text}>{t({ id: 'sections.load.load.salary.text.iban' })}{currentOption.iban}</Text>
              <Text className={classes.text}>{t({ id: 'sections.load.load.salary.text.bic' })} {BIC}</Text>
              
              <Text className={classes.text}>{`${t({
                id: 'sections.load.load.salary.text.thank',
              })}\n${client.title}`}</Text>
            </Flex>
          </GreyContainer>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpSalary;
