import { RootState } from 'common/store';

export const getAccounts = (state: RootState) => {
  return state.river.accounts;
};

export const getAccountsArray = (state: RootState) => {
  return Object.values(state.river.accounts);
};

export const getCurrentAccount = (state: RootState) => {
  return state.river.currentAccount;
};

export const getCurrentAccountObject = (state: RootState) => {
  const currentAccount = getCurrentAccount(state);

  return state.river.accounts[currentAccount!]; //TODO: Обработать currentAccount = null 
};

export const getAccountMeta = (state: RootState) => state.river.meta;

export const getAccountAddressString = (state: RootState) => {
  const meta = getAccountMeta(state);
  const addressString =
    meta.person.address +
    ', ' +
    meta.person.city +
    ', ' +
    meta.person.country +
    ', ' +
    meta.person.postal_code;
  return addressString;
};

export const getTotalBalance = (state: RootState) => {
  const accountsArray = getAccountsArray(state);

  return accountsArray
    ? accountsArray.reduce((sum, account) => sum + account.available, 0)
    : 0;
};

export const getAccountBalance = (state: RootState) => {
  const currentAccountObject = getCurrentAccountObject(state);
  return currentAccountObject.available;
};

export const getAccountTitle = (state: RootState) => {
  const currentAccountObject = getCurrentAccountObject(state);
  return currentAccountObject.title;
};
export const getAccountIsSoftBlocked = (state: RootState) => {
  const currentAccountObject = getCurrentAccountObject(state);
  return currentAccountObject.isSoftBlocked;
};

export const getAccountIBAN = (state: RootState) => {
  const currentAccountObject = getCurrentAccountObject(state);
  return currentAccountObject.iban;
};

export const getSoftBlockModal = (state: RootState) => {
  return state.river.softBlockModal
};

export const getIsSoftBlocked = (state: RootState) => {
  return state.river.isSoftBlocked
};