import React from 'react';
import classes from './GreyContainer.module.css';
import { Flex, Typography } from 'antd';

type GreyContainerProps = {
  title?: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => unknown;
  className?: string;
  id?: string;
};

const GreyContainer: React.FC<GreyContainerProps> = ({
  title,
  children,
  action,
  onClick,
  className,
  id,
}) => {
  return (
    <div id={id} className={`${classes.wrapper} ${className}`} onClick={onClick}>
      {/* {title && ( */}
        <Flex justify="space-between">
          <Typography.Text className={classes.text}>{title}</Typography.Text>
          {action}
        </Flex>
      {/* )} */}
      {children}
    </div>
  );
};

export default GreyContainer;
