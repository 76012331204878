import { general, generalFields } from '../../../../lib/schemas/rules';

export const getSignInScheme = (t: (descriptor: object, values?: object) => string) => ({
  email: [
    general(t)['required-field'],
    {
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: t({ id: 'schemas.new.fields.email' }),
    },
  ],
  password: [
    general(t)['required-field'],
  ],
})
