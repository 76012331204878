import { Drawer, Flex, List, Switch, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { VALIDATION_METHOD, VALIDATION_TYPE } from 'common/lib/constants/validation';
import { WarningFilled } from '@ant-design/icons';
import { ValidationMethod } from 'common/types/validation';
import { useValidationContext } from './context';

import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { confirmationActions } from './actions';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { handleErrorWithCommonFallback } from 'common/lib/utils/helpers';

import classes from './Validate.module.css';

type ValidateConfirmationProps = {
  isOpen: boolean;
  currentMethod: ValidationMethod;
  onClose: () => unknown;
};

const ValidateConfirmation = ({
  isOpen,
  currentMethod,
  onClose,
}: ValidateConfirmationProps) => {
  const { formatMessage: t } = useIntl();
  const { showError } = useMessage();
  const dispatch = useDispatch();
  const { onOpenUpdate } = useValidationContext();

  const isBiometryEnabled = currentMethod === VALIDATION_METHOD.BIOMETRY;

  const onToggleSms = () => {
    dispatch(showLoader());
    confirmationActions
      .enableSms()
      .then(({ data: { response } }) => {
        const { id, property1, property2, status } = response;
        onOpenUpdate({
          id: id,
          method: VALIDATION_METHOD.SMS,
          status,
          secret: property1,
          type: VALIDATION_TYPE.CONFIRMATION,
        });
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onToggleTwoFactor = () => {
    dispatch(showLoader());
    confirmationActions
      .enableTwoFactor()
      .then(({ data: { response } }) => {
        const { id, property1, property2, status } = response;
        onOpenUpdate({
          id: id,
          method: VALIDATION_METHOD.TWO_FA,
          status,
          secret: property1,
          type: VALIDATION_TYPE.CONFIRMATION,
        });
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  return (
    <Drawer
      title={t({ id: 'screens.validation.confirmation.title' })}
      placement="right"
      width={512}
      onClose={onClose}
      open={isOpen}>
      <Typography.Text className={classes['list-title']}>
        {t({ id: 'screens.validation.list.title' })}
      </Typography.Text>
      <List bordered>
        <List.Item>
          <List.Item.Meta title={t({ id: 'screens.validation.type.sms' })} />
          <Switch
            value={currentMethod === VALIDATION_METHOD.SMS}
            disabled={isBiometryEnabled}
            onClick={onToggleSms}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta title={t({ id: 'screens.validation.type.2fa' })} />
          <Switch
            value={currentMethod === VALIDATION_METHOD.TWO_FA}
            disabled={isBiometryEnabled}
            onClick={onToggleTwoFactor}
          />
        </List.Item>
        {isBiometryEnabled && (
          <List.Item>
            <List.Item.Meta title={t({ id: 'screens.validation.type.biometry' })} />
            <Switch value={true} disabled />
          </List.Item>
        )}
      </List>
      {isBiometryEnabled && (
        <Flex className={classes['warning-container']}>
          <WarningFilled />
          <Typography.Text >
            {t({ id: 'screens.validation.list.message.mobile' })}
          </Typography.Text>
        </Flex>
      )}
    </Drawer>
  );
};

export default ValidateConfirmation;
