import React from 'react';
import { Button, Flex, Modal, Result } from 'antd';
import { BlackButton } from 'common/components/UI';

type SuccessModalProps = {
  isOpen: boolean;
  shortNumber: string;
  onClose: () => unknown;
};

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, shortNumber, onClose }) => {
  return (
    <Modal title="&nbsp;" open={isOpen} footer={false} onCancel={onClose}>
      <Result
        status="success"
        title={`Card *${shortNumber} is blocked`}
        subTitle="When the card is blocked you can only order new one. The old one is not to be unblocked."
        // extra={
        //   <Flex gap={8} vertical>
        //     <BlackButton type='primary' key="cancel" block onClick={onClose}>
        //       Get new card
        //     </BlackButton>
        //   </Flex>
        // }
      />
    </Modal>
  );
};

export default SuccessModal;
