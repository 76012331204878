const initialState = {
  locale: 'EN',
};

interface LanguageState {
  locale: string;
}

type LanguageActionType = SetLanguageAction;

const languageReducer = (
  state: LanguageState = initialState,
  action: LanguageActionType,
): LanguageState => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

export default languageReducer;

export const SET_LOCALE = 'SET_LOCALE';
interface SetLanguageAction {
  type: typeof SET_LOCALE;
  payload: string;
}
