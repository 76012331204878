import React from 'react';
import { AccountsState } from '../../../store/reducers/types';
import { Account } from '../../../types';
import Option from '../Option';

export const mapAccountsToOptions = (
  accounts: AccountsState,
  onChange: (account: Account) => void,
  optionId?: string
) => {
  if (Object.keys(accounts).length === 0) return [];

  const fromOptions = [];

  let i = 0;
  for (const id in accounts) {
    fromOptions.push({
      key: id,
      label: (
        <Option
          id={(optionId ? optionId : 'account') + '-' + i}
          onChange={() => onChange(accounts[id])}
          option={accounts[id]}
        />
      ),
    });
    i++;
  }

  return fromOptions;
};