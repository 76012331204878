import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAppData } from 'common/store/actions/app';
import { AppDispatch, RootState } from 'common/store';
import Container from 'common/components/UI/Container/Container';
import classes from './Dashboard.module.scss';
import { Typography } from 'antd';
import { OceanPlanContainer } from '../../../ocean/components';
import RiverPlanContainer from '../../../river/components/RiverPlanContainer/RiverPlanContainer';
import { initChat } from "../../components/Freshchat/lib";

const Dashboard = () => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.meta);

  useEffect(() => {
    dispatch(refreshAppData());
  }, []);

  useEffect(() => {
    initChat(user);
  }, [])

  return (
    <Container className={classes.wrapper}>
      <div className={classes.container}>
        <Typography.Title id='dashboard-title' className={classes.title} level={2}>{t({id: 'screens.dashboard.title'})}</Typography.Title>
        <div className={classes.list}>
          <OceanPlanContainer />
          <RiverPlanContainer />
          {/*<PlanContainer active={false} benefits={[*/}
          {/*  t({id:'ocean.user.account.benefits2'}),*/}
          {/*  t({id:'ocean.user.account.benefits3'}),*/}
          {/*  t({id:'ocean.user.account.benefits4'}),*/}
          {/*  t({id:'river.user.account.benefits5'}),*/}
          {/*  t({id:'sea.user.account.benefits1'}),*/}
          {/*]} gradient={['rgb(212, 238, 238)', 'rgb(193, 227, 255)']} title={`${APP.NAME} ULTIMA`} watermark={t({id:'mobile.general.comingSoon'})} disabled/>*/}
        </div>
      </div>
    </Container>
  );
};
export default Dashboard;
