import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, MenuProps } from 'antd';
import classes from './InternationalPayment.module.scss';
import { BackButton, CenteredContainer, GridContainer } from 'common/components/UI';
import { AppDispatch, RootState } from 'common/store';
import Beneficiary from './components/Beneficiary';
import Amount from './components/Amount';
import Code from './components/Code';
import { InternationalForm } from './model/types';
import { FormFinishInfo } from 'rc-field-form/lib/FormContext';
import Success from './components/Success';
import { ERROR_MESSAGES } from '../../../lib/constants/errors';
import { handleError } from '../../../lib/utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import { CardInfoT } from 'common/types/cards';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import {
  convertCurrency,
  getCountries,
  getCurrencies,
} from 'common/store/actions/app';
import { Country, Currency } from 'common/types/app';
import {
  confirmInternationalPayment,
  createInternationalPayment,
  resendInternationalPaymentCode,
  signInternationalPayment,
} from '../../../store/actions/transfer';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getErrorMessage } from './model/errors';
import Declined from './components/Declined';
import { getLocalizedCountries } from '../../../../common/lib/utils/countries';
import { OCEAN_TRANSACTION_STATUS } from 'ocean/lib/constants/values';
import { getEnrollCountries, getValidateConfirmation } from '../../../../common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';

const InternationalPayment: React.FC = () => {
  const oceanCards = useSelector((state: RootState) => state.user.cards);
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;

  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();

  const countries = getLocalizedCountries(useSelector(getEnrollCountries), t);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [currentFrom, setCurrentFrom] = useState<CardInfoT>(Object.values(oceanCards)[0]);
  const [fromOptions, setFromOptions] = useState<MenuProps['items']>(
    mapCardsToOptions(oceanCards, setCurrentFrom),
  );
  const [step, setStep] = useState<number>(0);
  const [convertedAmount, setConvertedAmount] = useState<string>(
    ''
  );
  const [loading, setLoading] = useState({
    convert: false,
    currencies: false,
    countries: false,
  });
  const [predictedFee, setPredictedFee] = useState<number>(0);
  const [formData, setFormData] = useState<Partial<InternationalForm>>({
    currency: 'EUR',
    client: 'private',
  });
  const [paymentId, setPaymentId] = useState<number>(0);
  const [paymentDeclined, setPaymentDeclined] = useState<boolean>(false);

  const client = Form.useWatch('client', form);
  const country = Form.useWatch('creditorCountryCode', form);

  const resetForm = () => {
    form.resetFields();
    setStep(0);
  }

  const toggleLoading = (key: string, value: boolean) => {
    setLoading((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onAmountChange = async (e: number | null) => {
    form.setFieldValue('amount', e);

    if (e && e > 0) {
      const body = {
        amount: e ?? 0,
        currencyFrom: String(form.getFieldValue('currency')),
        currencyTo: 'EUR',
      };

      await handleConvert(body);
    }
  };

  const onCurrencyChange = async (value: string) => {
    form.setFieldValue('currency', value);

    if (form.getFieldValue('amount') && form.getFieldValue('amount') > 0) {
      const body = {
        amount: form.getFieldValue('amount'),
        currencyFrom: value,
        currencyTo: 'EUR',
      };

      await handleConvert(body);
    }
  };

  const handleConvert = async (body: {
    amount: number;
    currencyFrom: string;
    currencyTo: string;
  }) => {
    try {
      toggleLoading('convert', true);

      const response = await dispatch(convertCurrency(currentFrom.id, body));
      setConvertedAmount(String(response.amount));
    } catch (err) {
      setConvertedAmount(t({ id: 'buttons.retry' }));
    } finally {
      toggleLoading('convert', false);
    }
  };

  const fetchCountries = async () => {
    try {
      toggleLoading('countries', true);

      const resp = await dispatch(getCountries());
      const localizedCountries = getLocalizedCountries(resp, t);
    } catch (err) {
      showError('Error while fetching countries');
    } finally {
      toggleLoading('countries', false);
    }
  };

  const fetchCurrencies = async () => {
    try {
      toggleLoading('currencies', true);

      const resp = await dispatch(getCurrencies());
      setCurrencies(resp);
    } catch (err) {
      showError('Error while fetching currencies');
    } finally {
      toggleLoading('currencies', false);
    }
  };

  useEffect(() => {
    fetchCurrencies();
    fetchCountries();
  }, []);

  const checkErrors = () => {
    if (form.getFieldValue('amount') !== form.getFieldValue('confirmAmount')) {
      showError(t({ id: ERROR_MESSAGES.NOT_EQUAL }, {name: t({ id: 'noty.card.amount' })}));
      return 1;
    }

    if (form.getFieldValue('amount') + predictedFee > currentFrom.availBal) {
      showError(t({ id: ERROR_MESSAGES.NOT_ENOUGH }));
      return 1;
    }

    return 0;
  };

  const toNextStep = () => {
    setStep((prev) => (prev < Object.keys(steps).length - 1 ? prev + 1 : prev));
  };

  const toPrevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleBeneficiarySubmit = () => {
    (async () => {
      const body = {
        amount: form.getFieldValue('amount'),
        debitAmount: form.getFieldValue('amount'),
        beneficiary: form.getFieldValue('beneficiary'),
        lastname: form.getFieldValue('lastname'),
        bic: form.getFieldValue('bic'),
        city: form.getFieldValue('city'),
        // state? : string,
        creditorAddress1: form.getFieldValue('address1'),
        creditorAddress2: form.getFieldValue('address2'),
        creditorCountryCode: form.getFieldValue('creditorCountryCode'),
        currency: form.getFieldValue('currency'),
        details: form.getFieldValue('details'),
        iban: form.getFieldValue('iban'),
        postCode: form.getFieldValue('postCode'),
      };

      try {
        const response = await dispatch(createInternationalPayment(currentFrom.id, body));
        setPredictedFee(response.predictedFee);
        setPaymentId(response.id);

        toNextStep();
      } catch (err) {
        showError('');
      }
    })();
  };

  const handleAmountSubmit = () => {
    (async () => {
      if (checkErrors()) return;

      try {
        await dispatch(
          confirmInternationalPayment(
            currentFrom.id,
            paymentId,
            form.getFieldValue('confirmAmount') ?? 0,
          ),
        );

        toNextStep();
      } catch (err) {
        showError('');
      }
    })();
  };

  const handleCodeSubmit = (code: string) => {
    (async () => {
      try {
        const transaction = await dispatch(
          signInternationalPayment(currentFrom.id, paymentId, code, isTwoFactorEnabled),
        );

        if (transaction.status === OCEAN_TRANSACTION_STATUS.DECLINED)
          setPaymentDeclined(true);

        toNextStep();
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const onEachFormFinish = (formName: string, formInfo: FormFinishInfo) => {
    const updatedFormData = { ...formData, ...formInfo.values };
    setFormData(updatedFormData);

    switch (formName) {
      case 'beneficiary':
        handleBeneficiarySubmit();
        break;
      case 'amount':
        handleAmountSubmit();
        break;
      default:
        return;
    }
  };

  const handleResend = () => {
    (async () => {
      try {
        await dispatch(resendInternationalPaymentCode(currentFrom.id, paymentId));
        showSuccess(t({ id: 'notifications.codeSent.success' }));
      } catch (err) {
        showError('');
      }
    })();
  };

  const steps: Record<number, JSX.Element> = useMemo(
    () => ({
      0: (
        <Beneficiary
          form={form}
          fromOptions={fromOptions}
          currentFrom={currentFrom}
          onAmountChange={onAmountChange}
          onCurrencyChange={onCurrencyChange}
          countries={countries}
          currencies={currencies}
          formValues={formData}
          loading={loading}
          convertedAmount={convertedAmount}
          onCancel={() => navigate(-1)}
        />
      ),
      1: (
        <Amount
          form={form}
          currentFrom={currentFrom}
          formValues={formData}
          onCancel={toPrevStep}
          fee={predictedFee}
        />
      ),
      2: (
        <Code
          isTwoFactorEnabled={isTwoFactorEnabled}
          isBiometryEnabled={isBiometryEnabled}
          onResend={handleResend}
          onSubmit={(code: string) => handleCodeSubmit(code)}
          onCancel={() => setStep(0)}
        />
      ),
      3: (
        <Success
          resetForm={resetForm}
          name={form.getFieldValue('beneficiary')}
          amount={form.getFieldValue('amount')}
        />
      ),
    }),
    [
      predictedFee,
      paymentId,
      currentFrom,
      countries,
      currencies,
      loading,
      convertedAmount,
      client,
      country,
    ],
  );

  if (paymentDeclined)
    return (
      <GridContainer>
        <Declined />
      </GridContainer>
    )

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        <Form.Provider onFormFinish={onEachFormFinish}>{steps[step]}</Form.Provider>
      </div>
    </GridContainer>
  );
};

export default InternationalPayment;
