import React from 'react';
import classes from '../Transactions.module.scss';
import {
  ArrowLeftOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Flex } from 'antd';
import { OCEAN_TRANSACTION_TYPE } from 'ocean/lib/constants/values';
import { TransactionSubtype } from 'ocean/types/transfer';

type TransactionIconProps = {
  size: number;
  type?: TransactionSubtype;
  status?: string;
};

const TransactionIcon: React.FC<TransactionIconProps> = ({ size, type, status }) => {
  const renderIcon = (type: TransactionSubtype) => {
    switch (type) {
      case OCEAN_TRANSACTION_TYPE.SEPA_INCOMING_RETURN:
        return <ArrowLeftOutlined style={{ fontSize: size / 2.5 }} />;
      case OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING:
      case OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING_FEE:
        return <SwapOutlined style={{ fontSize: size / 2.5 }} />;
      case OCEAN_TRANSACTION_TYPE.MONTHLY_CHARGE:
      case OCEAN_TRANSACTION_TYPE.CARD_RECHARGE:
        return <CreditCardOutlined style={{ fontSize: size / 2.5 }} />;
      default:
        return <CreditCardOutlined style={{ fontSize: size / 2.5 }} />;
    }
  };

  if (!type) return null;

  return (
    <Flex
      justify="center"
      align="center"
      style={{ width: size, height: size }}
      className={classes['transaction__image']}>
      {renderIcon(type)}
    </Flex>
  );
};

export default TransactionIcon;
