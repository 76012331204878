import { general, generalFields } from '../../../../lib/schemas/rules';

export const getSignUpScheme = (t: (descriptor: object, values?: object) => string) => ({
  name: [
    general(t)['required-field'],
    ...generalFields(t).name,
  ],
  account: [
    general(t)['required-field'],
    ...generalFields(t).account,
  ],
  surname: [
    general(t)['required-field'],
    ...generalFields(t).surname,
  ],
  gender: [
    general(t)['required-field'],
  ],
  email: [
    general(t)['required-field'],
    ...generalFields(t).email,
  ],
  country: [
    general(t)['required-field'],
  ],
  city: [
    general(t)['required-field'],
    ...generalFields(t).city,
  ],
  address: [
    general(t)['required-field'],
    ...generalFields(t).address,
  ],
  postalCode: [
    general(t)['required-field'],
    ...generalFields(t).postalCode,
  ],
  phoneCountry: [
    general(t)['required-field'],
  ],
  phoneNumber: [
    general(t)['required-field'],
    ...generalFields(t).phoneNumber,
  ],
  password: [
    general(t)['required-field'],
    ...generalFields(t).password,
  ],
  confirmPassword: [
    general(t)['required-field'],
    ...generalFields(t).password,
  ],
  countryOfBirth: [
    general(t)['required-field'],
  ],
})