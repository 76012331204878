import { Typography,  Space } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store';
import AddressTable from './AddressTable';
import classes from './Profile.module.css';
import { getCountries } from 'common/store/selectors/app';
import React from 'react';
import ProfileAvatar from './components/ProfileAvatar';

const { Title } = Typography;

type ProfileTabProps = {
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
};

export default function Profile({ showError, showSuccess }: ProfileTabProps) {
  const meta = useSelector((state: RootState) => state.user.meta);
  const countries = useSelector(getCountries);
  const { firstName, lastName, address } = meta;

  return (
    <Space direction="vertical" size={32} className={classes.wrapper}>
      <Space size={16}>
        <ProfileAvatar showError={showError} showSuccess={showSuccess} />
        <Title level={2}>
          {firstName} {lastName}
        </Title>
      </Space>
      <AddressTable countries={countries} address={address} />
    </Space>
  );
}
