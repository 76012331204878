import React, { useState } from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Flex, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../Settings.module.css';
import { BlackButton } from 'common/components/UI';

const { Text } = Typography;

type EmailInfoStepProps = {
  clientExists: boolean,
  email: string,
  isVerified: boolean;
  onNextStep: () => unknown;
};

const EmailInfoStep: React.FC<EmailInfoStepProps> = ({
  clientExists,
  email,
  isVerified,
  onNextStep,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div>
      <Flex justify="center" align="center" vertical>
        <MailOutlined
          style={{
            fontSize: 36,
            padding: 18,
            boxSizing: 'content-box',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 100,
            marginBottom: 16,
          }}
        />
        {isVerified && <Text className={classes.verified} style={{ marginBottom: 16 }}>{t({id: "profile.settings.email.verified"})}</Text>}
        <Input
          style={{ marginBottom: 32 }}
          disabled
          placeholder={t({id: 'inputs.email'})}
          defaultValue={email}
        />
        {clientExists &&
          <BlackButton id='btn__email-change' type={'primary'} onClick={onNextStep}>
            {t({ id: 'screens.setting.setting.phone.buttons.change' })}
          </BlackButton>
        }
      </Flex>
    </div>
  );
};

export default EmailInfoStep;
