import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MAIN_COLOR } from 'ocean/lib/constants/colors';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { getIBAN, getCards, getTotalBalance } from 'ocean/store/selectors';
import AccountLayout from 'common/components/layout/AccountLayout/AccountLayout';
import { PLAN_NAME } from '../../lib/constants/values';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getUniversalCard } from 'ocean/lib/utils/cards';

const Account = () => {
  const navigate = useNavigate();
  const { showSuccess, showError } = useMessage();
  const accountTitle = PLAN_NAME;
  const accountIBAN = useSelector(getIBAN);
  const accountBalance = useSelector(getTotalBalance);
  const cards = useSelector(getCards)

  const universalCards = cards.map(card=>getUniversalCard(card));

  const onSend = () => {
    navigate(ROUTES.OCEAN_SEND.path);
  };
  const onTopup = () => {
    navigate(ROUTES.OCEAN_TOP_UP.path);
  };

  const onCard = (id: string | number) => {
    navigate(window.location.pathname + `/card/${id}`);
  };

  return (
    <>
      <AccountLayout
        color={MAIN_COLOR}
        monthSpent={0}
        title={accountTitle}
        iban={accountIBAN}
        cards={universalCards}
        balance={accountBalance}
        canAddCard={false}
        onSend={onSend}
        onTopup={onTopup}
        onCard={onCard}
        showError={showError}
        showSuccess={showSuccess}
      />
    </>
  );
};

export default Account;
