import Container from '../../components/UI/Container/Container';
import { Flex, notification, Segmented, Typography } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import Notification from './Notification';
import classes from './Notifications.module.scss';
import { CenteredContainer } from '../../components/UI';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotification,
  getNotifications,
  markAsRead,
  setLevelNotification
} from '../../store/actions/notifications';
import { AppDispatch } from '../../store';
import { EventType, NotificationType } from '../../types/notifications';
import { useIntl } from 'react-intl';
import { eventTypes } from './model/constants';
import moment from 'moment';
import { getUserLocale } from '../../store/selectors/app';
import { handleLangFormat } from '../../lib/utils/lang';
import NotificationDrawer from './NotificationDrawer';
import { SET_LEVEL_NOTIFICATION } from 'common/lib/constants/constants';

const { Text, Title } = Typography;

const Notifications: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { formatMessage: t } = useIntl();
  const availableFilters = [
    ...Object.values(eventTypes).map((obj) => ({
      label: (
        <div id={`notification-label__${obj.value.toLowerCase()}`}>
          {t({ id: obj.label })}
        </div>
      ) as ReactNode,
      value: obj.value,
    })),
  ];
  // const availableFilters = [...Object.values(eventTypes).map(obj => ({
  //   label: t({ id: obj.label }),
  //   value: obj.value
  // }))];
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [unreadAmount, setUnreadAmount] = useState<number>(0);
  const [notifyDrawer, setNotifyDrawer] = useState<NotificationType | null>(null);
  const lang = handleLangFormat(useSelector(getUserLocale));
  const formatFilter = (obj: { label: string; value: string }) => ({
    label: (
      <div id={`notification-label__${obj.value}`}>{t({ id: obj.label })}</div>
    ) as ReactNode,
    value: obj.value,
  });
  const [filter, setFilter] = useState(formatFilter(eventTypes.all));
  console.log('filter', filter);
  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    fetchNotifications();
  }, [filter]);

  useEffect(() => {
    markAllAsRead();
  }, []);

  const fetchNotifications = async () => {
    let eventType: EventType | undefined = undefined;

    if (filter.value !== eventTypes.all.value) {
      eventType = Object.values(eventTypes).find((value) => value.value === filter.value)
        ?.value as EventType;
    }
    
    const response = await dispatch(getNotifications(eventType));

    setNotifications(response.notifications);
    setUnreadAmount(response.unreaded);
  };

  const markAllAsRead = async () => {
    if (unreadAmount > 0) {
      const unreadNotifications = notifications.filter(
        (notification) => notification.unread,
      );
      const unreadNotificationIds = unreadNotifications.map(
        (notification) => notification.id,
      );
      try {
        await Promise.all(
          unreadNotificationIds.map((notificationId) =>
            dispatch(markAsRead(notificationId)),
          ),
        );
      } catch (e) {
        //
      }
    }
  };

  const markAsReadNotify = async (id: number) => {
    // const res = await  dispatch(markAsRead(id))
    // if(res){
    // }
  };

  const handleChange = async(value: EventType) => {
    const filter = availableFilters.find((f) => f.value === value);
    setFilter(filter ?? eventTypes.all);
    const levelType = value as string == 'All' ? "ALL" : value as string == 'VERIFICATION' ? 'FINANCE' : 'TEXT'
    await dispatch(setLevelNotification(levelType))
  };

  const deleteNotify = async (id: number) => {
    try {
      await dispatch(deleteNotification(id));
      fetchNotifications()
    } catch (e) {
      console.log(e);
    }
  };

  const tabNotification = async() => {
    try{
      const res = await dispatch(setLevelNotification('TEXT'))
      console.log('res', res)
    } catch(e){
      console.log(e)
    }
  }

  return (
    <>
      <NotificationDrawer
        isOpen={!!notifyDrawer}
        onClose={() => setNotifyDrawer(null)}
        notification={notifyDrawer}
        onReadNotify={markAsReadNotify}
      />

      <CenteredContainer>
        <Flex className={classes.wrapper} vertical gap={20}>
          <Title level={2}>
            {t({ id: 'screens.setting.setting.setting.nav.notification' })}
          </Title>
          <Segmented
            size="small"
            options={availableFilters}
            className={classes.segmented}
            onChange={(value) => handleChange(value as EventType)}
          />
          {notifications.length === 0 && (
            <Flex>
              <Text style={{ opacity: 0.6 }}>
                {t({ id: 'components.table.table.empty' })}
              </Text>
            </Flex>
          )}
          {notifications.length !== 0 && (
            <Flex vertical gap={16}>
              {notifications.map((notification) => (
                <Notification
                  deleteNotify={deleteNotify}
                  onClick={() => setNotifyDrawer(notification)}
                  key={notification.id}
                  title={notification.parameters.title}
                  text={notification.parameters.text}
                  date={notification.date}
                  unread={notification.unread}
                  id={notification.id}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </CenteredContainer>
    </>
  );
};

export default Notifications;
