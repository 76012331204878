import React, { useState } from 'react';
import CustomCardContainer from 'common/components/UI/CustomCardContainer/CustomCardContainer';
import { Button, Flex, Form, Input, message, notification, Typography } from 'antd';
import { APP } from 'common/lib/constants/constants';
import classes from './ResetPassword.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import AuthHeader from 'common/components/layout/AuthHeader/AuthHeader';
import { resetPassword } from 'common/pages/Auth/ResetPassword/actions';
import { patterns } from 'common/lib/schemas/patterns';
import { useIntl } from 'react-intl';
import { EmailNotFound, EmailSuccess } from '../EmailResult';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [successEmail, setSuccessEmail] = useState('');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();

  const [emailErr, setEmailErr] = useState<string[]>([]);

  const onFieldsChange = () => {
    setEmailErr(form.getFieldError('email'));
  };

  const onFinish = ({ email }: { email: string }) => {
    setLoading(true);
    resetPassword(email)
      .then(() => {
        form.resetFields();
        setErrorEmail('');
        setSuccessEmail(email);
        notification.success({
          message: t({ id: 'screens.resetPassword.request.success.title' }),
          description: t({ id: 'screens.resetPassword.request.success.subTitle' }),
          duration: 1.5,
          key: 'success-reset',
          className: 'toast__success-reset',
        });
        // setTimeout(() => navigate(ROUTES.SIGN_IN.path), 2500);
      })
      .catch(() => {
        setSuccessEmail('');
        setErrorEmail(email);
        form.resetFields();
        message.error({
          content: t({ id: 'screens.resetPassword.request.error.subTitle' }),
          className: 'toast__email-not-exists',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.wrapper}>
      {/*<AuthHeader/>*/}
      {successEmail ? <EmailSuccess email={successEmail} onClick={() => navigate(ROUTES.SIGN_IN.path)}/> : null}
      {errorEmail ? (
        <EmailNotFound email={errorEmail} />
      ) : null}

      {!errorEmail && !successEmail ?  
        <Flex flex={1} justify="center" align="center">
          <CustomCardContainer className={classes.card}>
            <Typography.Text className={classes.companyName}>{APP.NAME}</Typography.Text>
            <Typography.Text className={classes.title}>
              {t({ id: 'screens.auth.login.messages.reset' })}
            </Typography.Text>
            <Typography.Paragraph className={classes.desc}>
              {t({ id: 'mobile.login.forgotPassword.title' })}
            </Typography.Paragraph>
            <Form
              onFieldsChange={onFieldsChange}
              validateTrigger="onBlur"
              form={form}
              disabled={loading}
              onFinish={onFinish}>
              <Form.Item
                help={<div id="error__email">{emailErr[0]}</div>}
                rules={[
                  {
                    pattern: patterns.email,
                    message: t({ id: 'schemas.email.invalid' }),
                  },
                  {
                    required: true,
                    message: t(
                      { id: 'schemas.presence' },
                      { name: t({ id: 'inputs.email' }) },
                    ),
                  },
                ]}
                name="email">
                <Input placeholder={t({ id: 'inputs.email' })} />
              </Form.Item>
              <Button
                id="btn__reset-password"
                loading={loading}
                type="primary"
                onClick={form.submit}
                block>
                {t({ id: 'screens.resetPassword.btn' })}
              </Button>
            </Form>
          </CustomCardContainer>
        </Flex>
    : null}
    </div>
  );
};

export default ResetPassword;
