import {
  combineReducers,
  configureStore,
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
// import { encryptTransform } from 'redux-persist-transform-encrypt'

import appReducer from './reducers/appReducer';
import { Action, Dispatch } from 'redux';
import userReducer from './reducers/userReducer';
import languageReducer from './reducers/language';
import riverReducer from 'river/store/reducers';

// const encryptor = encryptTransform({
//     secretKey: 'weststein-secret-key-storage-phrase',
//     onError: function (error) {
//         console.error('Error while encrypting:', error)
//     }
// })

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: ['theme'],
};
const userPersistConfig = {
  key: 'user',
  storage,
};
const riverPersistConfig = {
  key: 'river',
  storage,
};
const languagePersistConfig = {
  key: 'language',
  storage,
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: persistReducer(userPersistConfig, userReducer),
  language: persistReducer(languagePersistConfig, languageReducer),
  river: persistReducer(riverPersistConfig, riverReducer),
});
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = Dispatch & ThunkDispatch<RootState, unknown, Action>;

const middleware: Middleware[] = [thunkMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

const persistor = persistStore(store);

export { store, persistor };
