import { UserOutlined, WalletOutlined } from '@ant-design/icons';
import ListItem from '../ListItem';
import { useIntl } from 'react-intl';
import ValidateUpdate from './ValidateUpdate';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';

import { useValidationContext } from './context';
import ValidateLogin from './ValidateLogin';
import ValidateConfirmation from './ValidateConfirmation';
import { ValidationMethod } from 'common/types/validation';

const MAPPED_METHOD_TRANSLATE: Record<ValidationMethod, string> = {
  [VALIDATION_METHOD.NO]: 'screens.validation.type.no',
  [VALIDATION_METHOD.SMS]: 'screens.validation.type.sms',
  [VALIDATION_METHOD.TWO_FA]: 'screens.validation.type.2fa',
  [VALIDATION_METHOD.BIOMETRY]: 'screens.validation.type.biometry',
};

const ValidateSettings = () => {
  const { formatMessage: t } = useIntl();
  const {
    email,
    updateParams,
    validateLogin,
    validateConfirmation,
    isOpenUpdate,
    isOpenLogin,
    isOpenConfirmation,
    onCloseLogin,
    onCloseConfirmation,
    onOpenLogin,
    onOpenConfirmation,
    onCloseUpdate,
  } = useValidationContext();

  return (
    <>
      <ListItem
        title={t({ id: 'screens.validation.login.title' })}
        subtitle={validateLogin ?  t({id: MAPPED_METHOD_TRANSLATE[validateLogin]}) : ''}
        withBorder
        iconComponent={
          <UserOutlined
            style={{
              fontSize: 24,
              padding: 12,
              boxSizing: 'content-box',
              background: 'rgba(0, 0, 0, 0.04)',
              borderRadius: 100,
            }}
          />
        }
        onClick={onOpenLogin}
      />
      <ListItem
        title={t({ id: 'screens.validation.confirmation.title' })}
        subtitle={
          validateConfirmation ? t({id: MAPPED_METHOD_TRANSLATE[validateConfirmation]}) : ''
        }
        withBorder
        iconComponent={
          <WalletOutlined
            style={{
              fontSize: 24,
              padding: 12,
              boxSizing: 'content-box',
              background: 'rgba(0, 0, 0, 0.04)',
              borderRadius: 100,
            }}
          />
        }
        onClick={onOpenConfirmation}
      />
      <ValidateLogin
        currentMethod={validateLogin || VALIDATION_METHOD.NO}
        isOpen={isOpenLogin}
        onClose={onCloseLogin}
      />
      <ValidateConfirmation
        currentMethod={validateConfirmation || VALIDATION_METHOD.SMS}
        isOpen={isOpenConfirmation}
        onClose={onCloseConfirmation}
      />
      <ValidateUpdate
        isOpen={isOpenUpdate}
        email={email || ''}
        onClose={onCloseUpdate}
        updateParams={updateParams}
      />
    </>
  );
};

export default ValidateSettings;
