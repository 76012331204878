export const LANGUAGES: { [key: string]: { name: string; symbol: string, icon: string } } = Object.freeze({
    EN: {
        name: 'English',
        symbol: 'EN',
        icon: "gb"
    },
    FR: {
        name: 'Français',
        symbol: 'FR',
        icon: "fr"
    },
    DE: {
        name: 'Deutsch',
        symbol: 'DE',
        icon: "de"
    },
    LV: {
        name: 'Latviski',
        symbol: 'LV',
        icon: "lv"
    },
    IT: {
        name: 'Italian',
        symbol: 'IT',
        icon: "it"
    },
    BG: {
        name: 'Български',
        symbol: 'BG',
        icon: "bg"
    },
    CZ: {
        name: 'Česky',
        symbol: 'CZ',
        icon: "cz"
    },
    EE: {
        name: 'Eesti',
        symbol: 'EE',
        icon: "ee"
    },
    ES: {
        name: 'Español',
        symbol: 'ES',
        icon: "es"
    },
    LT: {
        name: 'Lietuviškai',
        symbol: 'LT',
        icon: "lt"
    },
    PL: {
        name: 'Polski',
        symbol: 'PL',
        icon: "pl"
    },
    RU: {
        name: 'Русский',
        symbol: 'RU',
        icon: "ru"
    }
});
