import riverTransfersApi from 'river/api/transfer';
import { RiverThunkDispatch } from '../../types/thunk';
import { hideLoader, showLoader } from '../../../common/store/actions/app';
import { CreateSepaBody, SendBetweenAccountsBody } from '../../api/dto/transfers';

export const sendBetweenAccounts = (body: SendBetweenAccountsBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await riverTransfersApi.sendBetween(body);

      return response;
    } catch (e) {
      console.log('RIVER: Error sending transfer between accounts: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const createSepa = (accountId: number, body: CreateSepaBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await riverTransfersApi.createSepa(accountId, body);

      return response;
    } catch (e) {
      console.log('RIVER: Error while creating sepa transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const confirmSepa = (accountId: number, paymentId: number, amount: number) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await riverTransfersApi.confirmSepa(accountId, paymentId, amount);

      return response;
    } catch (e) {
      console.log('RIVER: Error while confirming sepa transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const signSepa = (accountId: number, paymentId: number, code: string, isTwoFactorEnabled: boolean = false) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await riverTransfersApi.signSepa(accountId, paymentId, isTwoFactorEnabled ? { google2faCodeFromUser: code } : { code });

      return response;
    } catch (e) {
      console.log('RIVER: Error while signing sepa transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const resendCode = (accountId: number, paymentId: number) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      dispatch(showLoader({ type: 'backdrop' }));
      const { data: { response } } = await riverTransfersApi.resendSepa(accountId, paymentId);

      return response;
    } catch (e) {
      console.log('RIVER: Error while resending code transaction: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  }
}