import React, { useState } from 'react';
import { Modal, Typography, DatePicker, Space, Button, Radio, List } from 'antd';

import classes from './Other.module.css';
import { FILE_FORMAT } from '../../../../../lib/constants/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store';
import { BlackButton, RoundContainer } from '../../../../UI';
import { useIntl } from 'react-intl';
import termsWS from '../../../../../assets/docs/TC.pdf';
import { RightOutlined } from '@ant-design/icons';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';

interface OtherBlockProps {
  onStatementDownload?: ({
    dateRange,
    format,
  }: {
    dateRange: Array<string>;
    format: 'pdf' | 'csv';
  }) => void | Promise<any>;
  river?: boolean,
  fromCard?: boolean
}
const OtherBlock: React.FC<OtherBlockProps> = ({
  onStatementDownload,
  river,
  fromCard ,
}) => {
  const { formatMessage: t, locale } = useIntl();
  const [statementModalVisible, setStatementModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState<Array<string>>([]);
  const [format, setFormat] = useState(FILE_FORMAT.PDF);

  const { showSuccess, showError } = useMessage();

  const dispatch = useDispatch<AppDispatch>();

  const closeStatementModal = () => setStatementModalVisible(false);

  const getLocaleForLink = () => {
    const allowedLocales = ['de', 'es', 'lv', 'fr', 'ru', 'it'];
    return allowedLocales.includes(locale.toLowerCase()) ? locale.toLowerCase() : 'en';
  };
  const termsLink = `https://weststeincard.com/${getLocaleForLink()}/private/terms-and-conditions`;

  const downloadStatement = async () => {
    if (onStatementDownload && dateRange.length > 0) {
      showSuccess(t({id: "river.views.statement.downloading"}), 'toast__download-in-progress')
      dispatch({ type: 'App/SHOW_LOADER' });
      await onStatementDownload({ dateRange, format });
      setDateRange([]);
      dispatch({ type: 'App/HIDE_LOADER' });
      closeStatementModal();
    } else closeStatementModal();
  };

  return (
    <div>
      <Typography.Text className={classes.title}>
        {t({ id: 'account.other' })}
      </Typography.Text>
      <List>
        {(river || !river && fromCard) &&
          <List.Item
            actions={[
              <RoundContainer
                size={32}
                id='other__statement'
                key="show-statement-action"
                onClick={() => setStatementModalVisible(true)}
                bgColor='transparent'
                pointer
              >
                <RightOutlined
                  style={{ fontSize: 16, color: 'rgba(0,0,0,0.6)' }}
                />
              </RoundContainer>
            ]}
          >
            <List.Item.Meta
              title={t({ id: 'account.statement' })} 
            />
          </List.Item>
        }
        {(river || !river && !fromCard) &&
          <List.Item
            actions={[
              <Typography.Link key="show-terms-action" href={termsLink}>
                <RoundContainer
                  size={32}
                  id='other__terms'
                  bgColor='transparent'
                  pointer
                >
                  <RightOutlined
                    style={{ fontSize: 16, color: 'rgba(0,0,0,0.6)' }}
                  />
                </RoundContainer>
              </Typography.Link>,
            ]}
          >
            <List.Item.Meta
              title={t({ id: 'account.tc' })}
            />
          </List.Item>
        }
      </List>

      <Modal
        title={t({ id: 'account.statement' })}
        open={statementModalVisible}
        onCancel={closeStatementModal}
        footer={[]}
        style={{height: 900}}
        destroyOnClose>
        <Typography.Paragraph>
          {t({ id: 'account.statement.description' })}
        </Typography.Paragraph>
        <DatePicker.RangePicker
          style={{ height: 58, marginBottom: 20 }}
          getPopupContainer={triggerNode => triggerNode.parentElement || document.body}
          onChange={(_, dates) => setDateRange(dates)}
          size="large"
          placement='bottomRight'
          renderExtraFooter={() => (
            <Radio.Group
              onChange={(event) => setFormat(event.target.value)}
              value={format}>
              <Radio id='btn__statement-choose-pdf' value={FILE_FORMAT.PDF}>PDF</Radio>
              <Radio id='btn__statement-choose-csv' value={FILE_FORMAT.CSV}>CSV</Radio>
            </Radio.Group>
          )}
          className="w100 input__statement-date"
        />
        <BlackButton id='btn__statement-download' onClick={downloadStatement} type="primary" block>
          {t({ id: 'buttons.download' })} {format.toUpperCase()}
        </BlackButton>
      </Modal>
    </div>
  );
};

export default React.memo(OtherBlock);
