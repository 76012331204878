import React, { useState, useEffect, FC } from 'react';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import { Drawer, Flex, Typography, } from 'antd';
import RoundContainer from 'common/components/UI/RoundContainer/RoundContainer';
import classes from '../Notification.module.scss';
import { useIntl } from 'react-intl';
import { NotificationType } from 'common/types/notifications';
import { markAsRead } from 'common/store/actions/notifications';
import { useDispatch} from 'react-redux';
import { AppDispatch } from 'common/store';

type NotificationDrawerProps = {
  isOpen: boolean;
  onClose: () => unknown;
  notification: NotificationType | null;
  onReadNotify: (id:number) => void
};

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({
  isOpen,
  onClose,
  notification,
  onReadNotify
}) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  
  return (
    <Drawer
      title={t({id: "screens.setting.setting.setting.nav.notification"})}
      open={isOpen}
      closeIcon={<CloseOutlined id='btn__notification-close'/>} 
      onClose={onClose}
      width={560}>
      <Flex vertical justify="center" align="center" gap={16}>
        <Typography.Text className={classes.title}>
          {notification && notification.parameters.title}
        </Typography.Text>
        <Typography.Text className={classes.text}>
          {notification && notification.parameters.text}
        </Typography.Text>

        
      </Flex>
    </Drawer>
  );
};

export default NotificationDrawer;
