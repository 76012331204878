import { Locale } from 'common/locales/types';
import { combineClasses } from 'common/lib/utils/helpers';
import classes from '../../Settings.module.css';

export const getFlagSvg = (locale: Locale) => {
  switch (locale) {
    case 'EN':
      return <span className={combineClasses(classes['language-icon'], `fi fi-gb`)} />;
    case 'FR':
      return <span className={combineClasses(classes['language-icon'], `fi fi-fr`)} />;
    case 'DE':
      return <span className={combineClasses(classes['language-icon'], `fi fi-de`)} />;
    case 'LV':
      return <span className={combineClasses(classes['language-icon'], `fi fi-lv`)} />;
    case 'IT':
      return <span className={combineClasses(classes['language-icon'], `fi fi-it`)} />;
    case 'BG':
      return <span className={combineClasses(classes['language-icon'], `fi fi-bg`)} />;
    case 'CZ':
      return <span className={combineClasses(classes['language-icon'], `fi fi-cz`)} />;
    case 'EE':
      return <span className={combineClasses(classes['language-icon'], `fi fi-ee`)} />;
    case 'ES':
      return <span className={combineClasses(classes['language-icon'], `fi fi-es`)} />;
    case 'LT':
      return <span className={combineClasses(classes['language-icon'], `fi fi-lt`)} />;
    case 'PL':
      return <span className={combineClasses(classes['language-icon'], `fi fi-pl`)} />;
    case 'RU':
      return <span className={combineClasses(classes['language-icon'], `fi fi-ru`)} />;
    default:
      return null;
  }
};
