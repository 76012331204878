import React from 'react';
import { CardInfoT } from 'common/types/cards';
import Option from '../Option';

export const mapCardsToOptions = (
  cards: CardInfoT[],
  onChange: (card: CardInfoT) => void,
) => {
  if (cards.length === 0) return [];

  const fromOptions = cards.map((card: CardInfoT, idx: number) => ({
    key: idx,
    label: (
      <Option
        onChange={() => onChange(card)}
        option={card}
      />
    ),
  }));

  return fromOptions;
};