import React, { useEffect, useRef } from 'react';
import { Button, message, Progress, Typography } from 'antd';
import classes from '../SignUp/SignUp.module.scss';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'common/store';
import { Navigate, useNavigate } from 'react-router-dom';
import userApi from 'common/api/user';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { getLoader } from 'common/store/selectors/app';
import { useIntl } from 'react-intl';
import { GTAG_EVENTS } from '../../../lib/tools/gtag_events';

const VerifyEmail = () => {
  const { verified, id, email } = useSelector((state: RootState) => state.user.meta);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage: t } = useIntl();
  const isFirstRender = useRef(true);
  const codeInputRef = useRef<any>(null);

  const sendCode = async () => {
    await userApi.auth.sendEmailCode();
    message.success({
      content:t({id:'notifications.codeSent.success'}),
      className: 'toast__code-sent'
    });
  }

  const onEnter = async (code: string) => {
    dispatch({ type: 'App/SHOW_LOADER' });
    try {
      await userApi.auth.verifyEmail(code);
      dispatch({ type: 'User/SET_META', payload: { verified: true } });
      codeInputRef?.current?.success()
      gtag('event', GTAG_EVENTS.EMAIL_VERIFIED.name);
      navigate(ROUTES.VERIFY_PHONE.path);
    } catch (e) {
      codeInputRef?.current?.error()
      message.error({
        content: t({id:'schemas.two.factor.code.invalid'}),
        className: 'toast__code-invalid'
      });
    } finally {
      dispatch({ type: 'App/HIDE_LOADER' });
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      sendCode();
    }
  }, []);
  
  if (isFirstRender.current && (verified || !id)) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <Typography.Title className={classes.title}>{t({id:'screens.auth.email.title'})}</Typography.Title>
          <Typography.Text className={classes.subTitle}>
            {t({id:'screens.verifyEmail.subTitle'}, {email: email})}
          </Typography.Text>
          <Progress percent={70} rootClassName={classes.progressBar} showInfo={false} /> 
          <CodeInput ref={codeInputRef} isEmail onEnter={onEnter} onResend={sendCode} timeout={30} />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;