import moment from 'moment';
import {
  OCEAN_TRANSACTION_STATUS,
  OCEAN_TRANSACTION_TYPE,
} from 'ocean/lib/constants/values';
import { TransactionType } from 'ocean/types/transfer';

export const TRANSACTION_COUNT = 10;

export const FILTERS: Record<TransactionType, TransactionType> = {
  ALL: 'ALL',
  OUT: 'OUT',
  IN: 'IN',
};

export const ALL_CONFIG = {
  start: moment('01-01-2020', 'DD-MM-YYYY').startOf('month').format('DD-MM-YYYY'),
  end: moment().endOf('month').format('DD-MM-YYYY'),
  page: 0,
  size: TRANSACTION_COUNT,
};

export const MONTH_CONFIG = {
  start: moment().startOf('month').format('DD-MM-YYYY'),
  end: moment().endOf('month').format('DD-MM-YYYY'),
  page: 0,
  size: 1000,
};

export const TRANSACTION_TYPE_LOCALIZED = {
  [OCEAN_TRANSACTION_TYPE.CARD_RECHARGE]: 'Card recharge',
  [OCEAN_TRANSACTION_TYPE.MONTHLY_CHARGE]: 'Monthly charge',
  [OCEAN_TRANSACTION_TYPE.SEPA_INCOMING_RETURN]: 'SEPA incoming return',
  [OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING]: 'SEPA outgoing',
  [OCEAN_TRANSACTION_TYPE.SEPA_OUTGOING_FEE]: 'SEPA outgoing fee',
};

export const TRANSACTION_STATUS_TAG = {
  [OCEAN_TRANSACTION_STATUS.SUCCESSFUL]: {
    title: 'transactions.item.status.successful',
    color: 'success',
  },
  [OCEAN_TRANSACTION_STATUS.DECLINED]: {
    title: 'transactions.item.status.declined',
    color: 'error',
  },
};
