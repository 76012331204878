import React from 'react';
import { Typography, Space } from 'antd';
import { DataCellProps } from '../types';

const { Text } = Typography;



export default function DataCell({ label, value }: DataCellProps) {
  return (
    <Space size={2} direction="vertical">
      <Text type="secondary">{label}</Text>
      <Text>{value}</Text>
    </Space>
  );
}
