import React, { FC } from 'react';
import classes from './EmailResult.module.scss';
import { Button, message, Progress, Typography, Flex } from 'antd';
import { ReactComponent as CheckmarkIcon } from 'common/assets/svg/checkmark.svg';
import CustomCardContainer from 'common/components/UI/CustomCardContainer/CustomCardContainer';
import { useIntl } from 'react-intl';

type Props = {
  email: string;
  onClick: () => void;
};

export const EmailSuccess: FC<Props> = ({ email, onClick }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Flex flex={1} justify="center" align="center">
      <CustomCardContainer className={classes.card}>
        <CheckmarkIcon className={classes['icon-success']} />
        <Typography.Text className={classes.text}>{t({id: "screens.resetPassword.request.success.check"})}</Typography.Text>
        <Typography.Text className={classes.desc}>
          <strong>{email}</strong> {t({id: "screens.resetPassword.request.success.reset"})}
        </Typography.Text>
        <Button onClick={onClick} id="btn__email_success" type="primary" block>
          {t({ id: 'sections.send.swift.success.button' })}
        </Button>
      </CustomCardContainer>
    </Flex>
  );
};
