import React from 'react';
import { Dropdown, Typography } from 'antd';
import {APP} from "common/lib/constants/constants";
import {CiGlobe} from "react-icons/ci";
import classes from './AuthHeader.module.scss';
import {LANGUAGES} from "common/locales/constants";
import {Link} from "react-router-dom";
import {ROUTES} from "common/components/AppRouter/AppRouter";
import { Locale } from '../../../locales/types';
import { setLocale } from '../../../store/actions/language';
import { useDispatch } from 'react-redux';

const AuthHeader: React.FC = () => {
  const dispatch = useDispatch();

  const onSetLocale = (locale: Locale) => {
    dispatch(setLocale(locale));
  };

    return (
        <div className={classes.authHeaderContainer}>
            <Link to={ROUTES.SIGN_IN.path}>
                <div className={classes.authHeaderTitleContainer}>
                    <Typography.Text className={classes.authHeaderTitle}>{APP.NAME}</Typography.Text>
                </div>
            </Link>
            <Dropdown
              trigger={['click']}
              placement="bottom"
              menu={{
                items: Object.keys(LANGUAGES).map(lang => ({
                  key: lang, label: (
                    <div id={`lang__${lang.toLowerCase()}`} style={{display: 'flex', alignItems: 'center'}} onClick={() => onSetLocale(lang as Locale)}>
                      <span className={`fi fi-${LANGUAGES[lang].icon}`} style={{marginRight: 6}}/>
                      {lang}</div>
                  ),
                })),
              }}
            >
              <CiGlobe id='btn__lang-switcher' style={{cursor: 'pointer'}} size={24}/> 
            </Dropdown>
            {/*<Space>*/}
            {/*    <CiGlobe size={24}/>*/}
            {/*    <Select*/}
            {/*        value="EN"*/}
            {/*        bordered={false}*/}
            {/*        suffixIcon={null}*/}
            {/*        className={classes.languagePicker}*/}
            {/*        popupMatchSelectWidth={90}*/}
            {/*        optionRender={(option) => {*/}
            {/*            const keyAsString = option.key.toString();*/}
            {/*            return <Typography.Text>{LANGUAGES[keyAsString].name}</Typography.Text>;*/}
            {/*        }}*/}
            {/*        options={Object.keys(LANGUAGES).map(lang => ({ value: lang, label: lang }))}*/}
            {/*    />*/}
            {/*</Space>*/}
        </div>
    );
};

export default AuthHeader;
