import React, { useRef, useState } from 'react';
import { Button, Divider, Drawer, Flex, Form, Input, Typography } from 'antd';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { general, generalFields } from 'common/lib/schemas/rules';
import { Push } from 'common/components/UI';
import FormItem from 'antd/es/form/FormItem';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentAccount, getCurrentCard } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './Enroll.module.css';
import { useIntl } from 'react-intl';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import cardApi from 'river/api/card';
import { updateCard } from 'river/store/actions/cards';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';
import { CloseOutlined } from "@ant-design/icons";

type EnrollProps = {
  isOpen: boolean;
  onClose: () => unknown;
  showError?: (text: string, className?: string) => unknown;
  showSuccess?: (text: string, className?: string) => unknown;
};

const Enroll: React.FC<EnrollProps> = ({ isOpen, onClose, showError, showSuccess }) => {
  const { formatMessage: t } = useIntl();
  const dispatch: RiverThunkDispatch = useDispatch();
  const cardId = useSelector(getCurrentCard) || '';
  const account = useSelector(getCurrentAccount) || 0;
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;
  const shouldSendSms = !isBiometryEnabled && !isTwoFactorEnabled;

  const operationId = useRef<string>('');
  const [password, setPassword] = useState<string>('');
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string[]>([])

  const [form] = Form.useForm();
  const validationScheme = {
    password: [general(t)['required-field'], ...generalFields(t)['3ds']],
  };

  const onFieldsChange = () => {
    setValidationError(form.getFieldError('password'));
  };

  const onSign = (code: string) => {
    const params = isTwoFactorEnabled ? { google2faCodeFromUser: code } : {};
    const body = isTwoFactorEnabled
      ? { staticPassword: password }
      : { code, staticPassword: password };

    dispatch(showLoader());
    cardApi
      .signEnrollCard(account, cardId, body, { params })
      .then(({ data: { response: card } }) => {
        dispatch(updateCard(account, card));
        showSuccess?.(t({ id: 'common.message.success' }), 'toast__code-success');
        onCloseOverload();
      })
      .catch(() => {
        showError?.(t({ id: 'common.otp.wrong' }), 'toast__code-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onSendCode = () => {
    dispatch(showLoader());
    cardApi
      .sendEnrollToken(account, cardId)
      .then(({ response }) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }), 'toast__code-sent');
        }

        setNextStep(true);
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }), 'toast__code-sent-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onResendCode = () => {
    dispatch(showLoader());
    cardApi
      .resendEnrollToken(account, cardId)
      .then((response) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }), 'toast__code-sent');
        }
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }), 'toast__code-sent-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onCloseOverload = () => {
    setNextStep(false);
    setPassword('');
    onClose();
  };

  return (
    <Drawer
      closeIcon={<CloseOutlined id='btn__3ds-close'/>}
      title={t({ id: 'river.sheets.createCard.3ds.placeholder' })}
      open={isOpen}
      onClose={onCloseOverload}
      width={560}
      destroyOnClose>
      <Flex vertical justify="center" align="center" gap={16}>
        <Form form={form} onFinish={onSendCode} validateTrigger="onChange" onFieldsChange={onFieldsChange}>
          <FormItem
            help={<div id="error__password-3ds">{validationError[0]}</div>}
            rules={validationScheme.password}
            name="password"
          >
            <Input
              disabled={nextStep}
              placeholder={t({ id: 'river.sheets.createCard.3ds.placeholder' })}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormItem>
          <Typography.Text className={classes.text}>
            {t({ id: 'river.sheets.createCard.3ds.hint' })}
          </Typography.Text>
        </Form>
        <Button id='btn__set-3ds' disabled={nextStep} className={classes.button} onClick={form.submit}>
          {t({ id: 'card.3ds.set' })}
        </Button>
        {nextStep && (
          <>
            <Divider />
            {isBiometryEnabled ? (
              <Push onResend={onResendCode} />
            ) : (
              <>
                <Typography.Text className={classes.text}>
                  {t({
                    id: isTwoFactorEnabled
                      ? 'screens.auth.two.factor.legend'
                      : 'screens.payment.create.create.nav.phone.description',
                  })}
                </Typography.Text>
                <CodeInput
                  timeout={60}
                  onResend={isTwoFactorEnabled ? undefined : onResendCode}
                  onEnter={onSign}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </Drawer>
  );
};

export default Enroll;
