import React, { useState } from 'react';
import DataTable from './components/DataTable';
import { AddressT } from 'common/types/user';
import { DataCellProps } from './types';
import { Country } from 'common/types/app';
import { findCountry } from '../Settings/utils';
import { useIntl } from 'react-intl';
import { Drawer, Flex, Typography } from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BlackButton } from '../../../components/UI';

type AddressTableProps = {
  address: AddressT | undefined;
  countries: Country[];
};

export default function AddressTable({ address, countries }: AddressTableProps) {
  const { formatMessage: t } = useIntl();
  const countryName = address?.country
    ? findCountry(address.country, countries)?.name || ''
    : '';
  const [open, setOpen] = useState<boolean>(false);

  const convertAddress = (address: AddressT | undefined): DataCellProps[] => {
    if (!address) return [];

    return [
      {
        label: t({ id: 'inputs.country' }),
        value: countryName,
      },
      {
        label: t({ id: 'inputs.city' }),
        value: address.city,
      },
      {
        label: t({ id: 'inputs.address' }),
        value: [address.line1, address.line2].filter((el) => el).join(', '),
      },
      {
        label: t({ id: 'inputs.postalCode' }),
        value: address.postalCode,
      },
    ];
  };

  const addressCells = convertAddress(address);

  return (
    <>
      <DataTable onEdit={() => setOpen(true)} title={t({ id: 'inputs.address' })} cells={addressCells} />
      <Drawer
        closeIcon={<CloseOutlined id='btn__address-close'/>}
        title={t({ id: 'inputs.address' })}
        placement="right"
        width={512}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Flex justify="center" align="center" vertical>
          <InfoCircleOutlined
            style={{
              fontSize: 36,
              padding: 18,
              boxSizing: 'content-box',
              background: 'rgba(0, 0, 0, 0.04)',
              borderRadius: 100,
              marginBottom: 16,
            }}
          />
          <div style={{
            borderRadius: 16,
            border: '1px solid rgba(0, 0, 0, 0.08)',
            padding: 24,
            marginBottom: 24
          }}>
            <Typography.Text>{t({ id: 'settings.changeAddress.notification' })}</Typography.Text>
          </div>
          <BlackButton
            id='btn__address-contact'
            type='primary'
            href='mailto:cardservices@weststeincard.com'
          >
            {t({ id: 'buttons.contact' })}
          </BlackButton>
        </Flex>
      </Drawer>
    </>
  );
}
