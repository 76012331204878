import { List, Space, Typography } from 'antd';
import { RoundContainer } from 'common/components/UI';
import React from 'react';

import classes from './Reason.module.css';
import { Eyes, Hand, Other } from 'common/components/icons';
import { getGreyColor } from 'common/lib/utils/helpers';
import { BlockReason } from 'river/types';
import { BLOCK_REASON } from 'river/lib/constants/values';
import { useIntl } from 'react-intl';

type ListItemProps = {
  title: string;
  icon: React.ReactElement;
  isSelected: boolean;
  onSelect: () => unknown;
  id?: string;
};

const ListItem: React.FC<ListItemProps> = ({ title, icon, isSelected, onSelect, id }) => {
  return (
    <List.Item id={id} className={classes.item} onClick={onSelect}>
      <Space size={8}>
        <RoundContainer padding={8}>{icon}</RoundContainer>
        <Typography.Text className={classes.text}>{title}</Typography.Text>
      </Space>
      <RoundContainer bgColor={isSelected ? 'black' : getGreyColor()} size={32}>
        {null}
      </RoundContainer>
    </List.Item>
  );
};

type ReasonBlockProps = {
  selected: BlockReason | null;
  onSelect: (reason: BlockReason) => void;
};

const ReasonBlock: React.FC<ReasonBlockProps> = ({ selected, onSelect }) => {
  const { formatMessage: t } = useIntl();

  return (
    <List className={classes.wrapper}>
      <ListItem
        id='reson__stolen'
        title={t({id: "card.block.reason.stolen"})}
        icon={<Hand />}
        isSelected={selected === BLOCK_REASON.STOLEN}
        onSelect={() => {
          onSelect(BLOCK_REASON.STOLEN);
        }}
      />
      <ListItem
        id='reson__lost'
        title={t({id: "card.block.reason.lost"})}
        icon={<Eyes />}
        isSelected={selected === BLOCK_REASON.LOST}
        onSelect={() => {
          onSelect(BLOCK_REASON.LOST);
        }}
      />
      <ListItem
        id='reson__other'
        title={t({id: "card.block.reason.Other"})}
        icon={<Other />}
        isSelected={selected === BLOCK_REASON.AT_CARDHOLDER_WILL}
        onSelect={() => {
          onSelect(BLOCK_REASON.AT_CARDHOLDER_WILL);
        }}
      />
    </List>
  );
};

export default ReasonBlock;
