import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconComponent = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 15.4522V6.67307C5 6.08779 5.46695 5.6214 6.05293 5.6214C6.63891 5.6214 7.09671 6.08779 7.09671 6.67307V12.2241C7.33476 12.0777 7.59113 11.9223 7.87496 11.7942V3.81984C7.87496 3.23457 8.34192 2.76818 8.9279 2.76818C9.50472 2.76818 9.97167 3.23457 9.97167 3.81984V11.1724C10.2372 11.1267 10.5119 11.0901 10.7774 11.0626V3.05167C10.7774 2.46639 11.2443 2 11.8303 2C12.4163 2 12.8833 2.46639 12.8833 3.05167V11.1632C13.1579 11.2181 13.4143 11.2913 13.6524 11.3736V4.35025C13.6524 3.76497 14.1193 3.29858 14.6961 3.29858C15.2821 3.29858 15.7491 3.76497 15.7491 4.35025V14.0165C15.7491 14.3182 15.8681 14.4188 16.0421 14.4188C16.1794 14.4188 16.3076 14.3182 16.4358 14.0439L17.7084 11.3004C18.1113 10.4774 18.505 10.1024 19.1276 10.1481C19.677 10.1756 20.034 10.5688 19.9974 11.1084C19.97 11.5931 19.6037 12.6813 19.329 13.5409C18.8072 15.2419 18.1937 17.1623 17.333 18.5706C16.0787 20.8569 14.275 22 11.4641 22C7.75594 22 5 19.5583 5 15.4522Z" fill="black"/>
    </svg>
    
);

const Eyes = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Eyes;
