import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import storage from 'common/lib/utils/storage';
import { store } from 'common/store';
import { logout } from 'common/store/actions/user';
import { CommonThunkDispatch } from 'common/types/store';

let baseURL;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'development':
    baseURL = 'https://api-dev.weststeincard.com/api';
    break;
  case 'development2':
    baseURL = 'https://api-dev2.weststeincard.com/api';
    break;
  case 'production':
    baseURL = 'https://api.weststeincard.com/api';
    break;
  case 'demo':
    baseURL = 'https://api-demo.weststeincard.com:10004/api';
    break;
  case 'staging':
    baseURL = 'https://api-staging.weststeincard.com/api';
    break;
  default:
    baseURL = 'https://api.weststeincard.com/api';
}

const setToken = (token: string): void => {
  storage.set('session', token);
  storage.set('sessionCreated', new Date().toUTCString());
};

const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-Frame-Options': 'SAMEORIGIN',
    'Content-Security-Policy': "frame-ancestors 'self'",
  },
});
instance.interceptors.response.use(
  (response) => {
    if (response.headers && response.headers.authorization) {
      setToken(response.headers.authorization as string);
    }

    return response;
  },
  (error: AxiosError) => {
   if (error.request.status === 401 || error.status === 401) {
    (store.dispatch as CommonThunkDispatch)(logout());
   }
   throw error;
  },
);
instance.interceptors.request.use((config) => {
  if (storage.get('session')) config.headers.authorization = storage.get('session');
  return config;
});

export const API = {
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<any> => {
    try {
      const request = await instance.get(url, config);
      return { data: request.data, headers: request.headers, status: request.status };
    } catch (error) {
      throw error;
    }
  },
  post: async <T>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const request = await instance.post(url, body, config);
      return { data: request.data, headers: request.headers, status: request.status };
    } catch (error) {
      throw error;
    }
  },
  put: async <T>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const request = await instance.put(url, body, config);
      return { data: request.data, headers: request.headers, status: request.status };
    } catch (error) {
      throw error;
    }
  },
  patch: async <T>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const request = await instance.patch(url, body, config);
      return { data: request.data, headers: request.headers, status: request.status };
    } catch (error) {
      throw error;
    }
  },
  delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<any> => {
    try {
      const request = await instance.delete(url, config);
      return { data: request.data, headers: request.headers, status: request.status };
    } catch (error) {
      throw error;
    }
  },
  // setKey: (key: string): void => {
  //     instance.defaults.headers.common.Authorization = key;
  // },
  // getKey: (): AxiosHeaderValue | undefined => {
  //     return instance.defaults.headers.common.Authorization;
  // },
};
