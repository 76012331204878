import { API } from '../../common/http';
import { ENDPOINTS } from '../lib/constants/values';

type CardsApi = {
  getCardInfo: (cardId: number) => Promise<any>;
  updateCardInfo: (cardId: number, {
    chanel, deliveryType, udfs, utm }: {
    chanel?: string,
    deliveryType?: 'VC' | 'PC',
    udfs?: Record<string, number>,
    utm?: Record<string, any>
  }) => Promise<any>;
  getPin: (cardId: number | string) => Promise<any>;
  reissue: (cardId: number) => Promise<any>;
  block: (cardId: string | number) => Promise<any>;
  unblock: (cardId: string | number) => Promise<any>;
  getCardNumber: (cardId: number) => Promise<any>;
  requestPlastic: (cardId: number) => Promise<any>;
  sendCVV: (cardId: string | number, { zipCode }: { zipCode: string }) => Promise<any>;
  getExpDate: (cardId: number, { zipCode }: { zipCode: string }) => Promise<any>;
  requestDraft: ({ lastname, udfs }: {
    lastname: string, udfs: Record<string, number>
  }) => Promise<any>;
  requestSelfSecondary: (cardId: number, { chanel, deliveryType, udfs, utm }: {
    chanel: string, deliveryType?: 'PC' | 'VC', udfs?: Record<string, number>, utm?: Record<string, any>
  }) => Promise<any>;
  requestOtherSecondary: (cardId: number, { 
    address, chanel, child, dateOfBirth, deliveryType, email, firstName, lastName, phone,
    phoneCountry, udfs, utm,
  }: {
    address: {
      city: string,
      country: number,
      line1: string,
      line2: string,
      postalCode: string
    },
    chanel: string,
    child: boolean,
    dateOfBirth: string,
    deliveryType?: 'VC' | 'PC',
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    phoneCountry:number,
    udfs?: Record<string, number>,
    utm?: Record<string, any>,
  }) => Promise<any>;
  getCardLimit: (cardId: number) => Promise<any>;
}

const cardsApi: CardsApi = {
  getCardInfo: (cardId) => API.get(ENDPOINTS.CARDS_CTRL.INFO(cardId)),
  updateCardInfo: (cardId, { chanel, deliveryType, udfs, utm } = {}) => API.put(ENDPOINTS.CARDS_CTRL.INFO(cardId), {
    chanel, deliveryType, udfs, utm
  }),
  getPin: (cardId) => API.post(ENDPOINTS.CARDS_CTRL.GET_PIN(cardId)),
  reissue: (cardId) => API.post(ENDPOINTS.CARDS_CTRL.REISSUE(cardId)),
  block: (cardId) => API.post(ENDPOINTS.CARDS_CTRL.BLOCK(cardId)),
  unblock: (cardId) => API.post(ENDPOINTS.CARDS_CTRL.UNBLOCK(cardId)),
  getCardNumber: (cardId) => API.get(ENDPOINTS.CARDS_CTRL.GET_CARD_NUMBER(cardId)),
  requestPlastic: (cardId) => API.put(ENDPOINTS.CARDS_CTRL.REQUEST_PLASTIC(cardId)),
  sendCVV: (cardId, { zipCode }) => API.put(ENDPOINTS.CARDS_CTRL.SEND_CVV(cardId), { zipCode }),
  getExpDate: (cardId, { zipCode }) => API.put(ENDPOINTS.CARDS_CTRL.GET_EXP_DATE(cardId), { zipCode }),
  requestDraft: ({ lastname, udfs }) => API.post(ENDPOINTS.CARDS_CTRL.REQUEST_DRAFT, { lastname, udfs }),
  requestSelfSecondary: (cardId, { chanel, deliveryType = 'VC', udfs, utm }) => API.post(ENDPOINTS.CARDS_CTRL.REQUEST_SELF_SECONDARY(cardId), { chanel, deliveryType, udfs, utm }),
  requestOtherSecondary: (cardId, { address, chanel, child, dateOfBirth, deliveryType = 'VC', email, firstName, lastName, phone,
    phoneCountry, udfs, utm }) => API.post(ENDPOINTS.CARDS_CTRL.REQUEST_OTHER_SECONDARY(cardId), { address, chanel, child, dateOfBirth, deliveryType, email, firstName, lastName, phone,
    phoneCountry, udfs, utm }),
  getCardLimit: (cardId) => API.get(ENDPOINTS.CARDS_CTRL.GET_CARD_LIMIT(cardId)),
}

export default cardsApi;