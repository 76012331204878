import { Typography, Modal, Space, QRCode } from 'antd';
import CodeInput, { CodeInputRef } from 'common/components/UI/CodeInput/CodeInput';
import classnames from './Validate.module.css';
import { useIntl } from 'react-intl';
import { UpdateParams } from './context';
import { useEffect, useRef, useState } from 'react';
import {
  VALIDATION_TYPE,
  VALIDATION_UPDATE_STATUS,
} from 'common/lib/constants/validation';
import { updateActions } from './actions';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { handleErrorWithCommonFallback } from 'common/lib/utils/helpers';
import { ValidationMethod, ValidationUpdateStatus } from 'common/types/validation';
import { setConfirmationValidation, setLoginValidation } from 'common/store/actions/user';

const { Text } = Typography;

type ValidateUpdateProps = {
  isOpen: boolean;
  email: string;
  updateParams?: UpdateParams;
  onClose: () => unknown;
};

const ValidateUpdate = ({
  isOpen,
  email,
  updateParams,
  onClose,
}: ValidateUpdateProps) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<ValidationUpdateStatus | undefined>(
    updateParams?.status,
  );
  const { showError, showSuccess } = useMessage();
  const smsCodeRef = useRef<CodeInputRef>(null);
  const twoFactorCodeRef = useRef<CodeInputRef>(null);
  const identifier = `WestStein (${email})`;
  const provisionUrl = `otpauth://totp/${identifier}?secret=${
    updateParams?.secret || ''
  }`;

  console.log(updateParams);

  const onSetLoginValidation = (method: ValidationMethod) => {
    dispatch(setLoginValidation(method));
  };
  const onSetConfirmationValidation = (method: ValidationMethod) => {
    dispatch(setConfirmationValidation(method));
  };

  const isTwoFactorStep =
    currentStep === VALIDATION_UPDATE_STATUS.NEED_TWO_FA_CONFIRMATION;
  const isSMSStep = currentStep === VALIDATION_UPDATE_STATUS.NEED_SMS_CONFIRMATION;

  const handleGranted = () => {
    if (updateParams?.type === VALIDATION_TYPE.LOGIN) {
      onSetLoginValidation(updateParams.method);
    }
    if (updateParams?.type === VALIDATION_TYPE.CONFIRMATION) {
      onSetConfirmationValidation(updateParams.method);
    }
  };

  const onResendSms = () => {
    dispatch(showLoader());
    updateActions
      .resend(updateParams?.id || '')
      .then(({ data: { response } }) => {
        smsCodeRef.current?.clear();
        showSuccess(t({ id: 'common.otp.sent' }));
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onEnterSmsCode = (code: string) => {
    dispatch(showLoader());
    updateActions
      .confirm(updateParams?.id || '', { smsCode: code })
      .then(({ data: { response } }) => {
        smsCodeRef.current?.clear();
        if (response.status === VALIDATION_UPDATE_STATUS.NEED_TWO_FA_CONFIRMATION) {
          setCurrentStep(response.status);
        }
        if (response.status === VALIDATION_UPDATE_STATUS.GRANTED) {
          showSuccess(t({ id: 'screens.validation.message.success' }));
          handleGranted();
        }
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
        smsCodeRef.current?.clear();
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onEnterTwoFactorCode = (code: string) => {
    dispatch(showLoader());
    updateActions
      .confirm(updateParams?.id || '', { twoFactorCode: code })
      .then(({ data: { response } }) => {
        twoFactorCodeRef.current?.clear();
        if (response.status === VALIDATION_UPDATE_STATUS.GRANTED) {
          showSuccess(t({ id: 'screens.validation.message.success' }));
          handleGranted();
          onClose();
        }
      })
      .catch(({ response }) => {
        twoFactorCodeRef.current?.clear();
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  useEffect(() => {
    if (updateParams && updateParams.status) {
      setCurrentStep(updateParams.status);
    }
  }, [updateParams]);

  if (!isOpen) return;

  return (
    <Modal title="&nbsp;" footer={false} centered open={isOpen} onCancel={onClose}>
      {isSMSStep && (
        <div>
          <Space
            align="center"
            direction="vertical"
            size={48}
            style={{ textAlign: 'center' }}>
            <Text className={classnames.semibold}>{t({ id: 'common.otp.sent' })}</Text>
            <CodeInput onResend={onResendSms} ref={smsCodeRef} onEnter={onEnterSmsCode} />
          </Space>
        </div>
      )}
      {isTwoFactorStep && (
        <div>
          <Space
            align="center"
            direction="vertical"
            size={48}
            style={{ textAlign: 'center' }}>
            {updateParams?.secret ? (
              <>
                <Text className={classnames.semibold}>
                  {t({ id: 'profile.settings.2fa.scan' })}
                </Text>
                <QRCode value={provisionUrl} />
              </>
            ) : (
              <Text className={classnames.semibold}>
                {t({ id: 'screens.auth.two.factor.legend' })}
              </Text>
            )}
            <CodeInput ref={twoFactorCodeRef} onEnter={onEnterTwoFactorCode} />
          </Space>
        </div>
      )}
    </Modal>
  );
};

export default ValidateUpdate;
