import React from 'react';
import classes from '../Transactions.module.scss';
import {
  ArrowLeftOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Flex } from 'antd';
import {
  RIVER_TRANSACTION_STATUS,
  RIVER_TRANSACTION_TYPE,
} from '../../../../../lib/constants/values';

type TransactionIconProps = {
  size: number;
  type?: string;
  status?: string;
};

const TransactionIcon: React.FC<TransactionIconProps> = ({ size, type, status }) => {
  const renderIcon = (type: string | undefined, status: string | undefined) => {
    if (status === RIVER_TRANSACTION_STATUS.PENDING)
      return (
        <HistoryOutlined
          style={{ fontSize: size / 3, position: 'absolute', bottom: -1, right: -1 }}
        />
      );

    if (
      type === RIVER_TRANSACTION_TYPE.TRANSFER_CREDIT ||
      type === RIVER_TRANSACTION_TYPE.SEND_CREDIT
    ) {
      return <ArrowLeftOutlined style={{ fontSize: size / 2.5 }} />;
    }

    if (
      type === RIVER_TRANSACTION_TYPE.TRANSFER_DEBIT ||
      type === RIVER_TRANSACTION_TYPE.SEND_DEBIT
    )
      return <SwapOutlined style={{ fontSize: size / 2.5 }} />;

    if (type === RIVER_TRANSACTION_TYPE.WITHDRAW_SEPA)
      return <CreditCardOutlined style={{ fontSize: size / 2.5 }} />;

    return <CreditCardOutlined style={{ fontSize: size / 2.5 }} />;
  };
  return (
    <Flex
      justify="center"
      align="center"
      style={{ width: size, height: size }}
      className={classes['transaction__image']}>
      {renderIcon(type, status)}
    </Flex>
  );
};

export default TransactionIcon;
