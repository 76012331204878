import { Dispatch } from 'redux';
import API from '../../api';
import { getCurrentAccount } from '../selectors';
import { RootState } from 'common/store';
import { Card } from 'river/types';

export const getCards = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { response: cards },
      } = await API.card.getCards(id);
      dispatch({
        type: 'River/SET_CARDS',
        payload: { accountId: id, cards },
      });
      return cards;
    } catch (e) {
      throw e;
    }
  };
};

export const setCurrentCard = (cardId: number | string) => ({
  type: 'River/SET_CURRENT_CARD',
  payload: cardId,
});

export const updateCurrentCard =
  (card: Card) => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState(),
      currentAccountID = getCurrentAccount(state);
    dispatch({
      type: 'River/UPDATE_CARD',
      payload: { accountId: currentAccountID, card: card },
    });
  };

export const updateCard = (accountId: string | number, card: Card) => ({
  type: 'River/UPDATE_CARD',
  payload: { accountId: +accountId, card: card },
});

export const addCard = (accountId: string | number, card: Card) => ({
  type: 'River/ADD_CARD',
  payload: { accountId: +accountId, card: card },
});

export const removeCard = (accountId: string | number, cardId: string | number) => ({
  type: 'River/REMOVE_CARD',
  payload: { accountId: +accountId, cardId },
});

export const replaceCard = (accountId: string | number, cardId: string | number, updatedCard: Card) => ({
  type: 'River/REPLACE_CARD',
  payload: { accountId: +accountId, cardId, updatedCard },
});

export const showSoftBlockModal = () => ({
  type: 'River/SHOW_SOFT_BLOCK_MODAL'
})

export const hideSoftBlockModal = () => ({
  type: 'River/HIDE_SOFT_BLOCK_MODAL'
})