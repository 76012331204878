import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Progress,
  Space,
  Typography,
  FormRule,
  InputNumber,
  Select,
  Flex,
} from 'antd';
import classes from '../../SignUp.module.scss';
import moment from 'moment';
import { getSignUpScheme } from '../../model/scheme';
import { Country } from 'common/types/app';
import { CreateAccountForm } from 'common/pages/Auth/SignUp/model/types';
import { useIntl } from 'react-intl';
import { RiskModal } from './riskModal';

const currentYear = moment().year();

const WARNING_RISK_LEVEL = ['EXTREME', 'HIGH', 'MEDIUM'];

interface PersonalDataFormProps {
  countries: Country[];
  formValues: Partial<CreateAccountForm>;
}
const PersonalDataForm: React.FC<PersonalDataFormProps> = ({ countries, formValues }) => {
  const [form] = Form.useForm();
  const { formatMessage: t } = useIntl();
  const formRules = getSignUpScheme(t);
  const [riskLevel, setRiskLevel] = useState('');
  const [riskLevelModal, setRiskLevelModal] = useState(false);

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    name: [],
    surname: [],
    gender: [],
    email: [],
    date: [],
    countryOfBirth: [],
  });

  const onFieldsChange = () => {
    setValidationErrors((prevState) => ({
      ...prevState,
      name: form.getFieldError('name'),
      surname: form.getFieldError('surname'),
      gender: form.getFieldError('gender'),
      email: form.getFieldError('email'),
      date: form.getFieldError('date'),
      countryOfBirth: form.getFieldError('countryOfBirth'),
    }));
  };

  const validateDate = (input: string, _: FormRule, value: number) => {
    const date = form.getFieldValue('dateOfBirth');
    let day, month, year;

    if (date) {
      day = date.day;
      month = date.month;
      year = date.year;
    }

    // Чтобы мы могли валидировать три поля с датой,
    // а отображалась одна ошибка
    if (!value) {
      if (
        input === 'day' ||
        (input === 'month' && day) ||
        (input === 'year' && day && month)
      ) {
        return Promise.reject(
          <span id="error__date">
            {t({ id: 'schemas.presence' }, { name: t({ id: 'inputs.dateOfBirth' }) })}
          </span>,
        );
      } else return Promise.reject('');
    }

    if (!day || !month || !year) return Promise.resolve();

    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    const dateStr = `${year}-${formattedMonth}-${formattedDay}`;
    const dateMoment = moment(dateStr, 'YYYY-MM-DD', true);

    if (!dateMoment.isValid()) {
      if (input === 'day') return Promise.reject(t({ id: 'schemas.date.invalid' }));
    }

    const eighteenYearsAgo = moment().subtract(18, 'years');
    if (dateMoment.isAfter(eighteenYearsAgo)) {
      if (input === 'year')
        return Promise.reject(t({ id: 'schemas.dateOfBirth.invalid' }));
    }

    form.setFields([{ name: ['dateOfBirth', 'day'], errors: [] }]);
    return Promise.resolve();
  };

  const isRiskLevel = () => {
    form.validateFields()
    const idCountry = form.getFieldValue('countryOfBirth');
    if (idCountry) {
      const country = countries.find(
        (i) => i.id == idCountry && WARNING_RISK_LEVEL.includes(i.riskLevel),
      );
      if (country) {
        setRiskLevel(country.riskLevel);
        setRiskLevelModal(true);
      } else {
        form.submit();
      }
    }
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      initialValues={formValues}
      onFieldsChange={onFieldsChange}
      form={form}
      validateTrigger="onChange"
      name="step0">
      <Typography.Title className={classes.title}>
        {t({ id: 'screens.auth.signup.private.title' })}
      </Typography.Title>
      <Typography.Text className={classes.subTitle}>
        {t({ id: 'screens.createAccount.subTitle' })}
      </Typography.Text>
      <Progress percent={10} rootClassName={classes.progressBar} showInfo={false} />
      <Form.Item
        help={<div id="error__name">{validationErrors.name[0]}</div>}
        rules={formRules.name}
        name="name">
        <Input placeholder={t({ id: 'inputs.name' })} />
      </Form.Item>
      <Form.Item
        help={<div id="error__surname">{validationErrors.surname[0]}</div>}
        rules={formRules.surname}
        name="surname">
        <Input placeholder={t({ id: 'inputs.surname' })} />
      </Form.Item>
      <Form.Item
        help={<div id="error__gender">{validationErrors.gender[0]}</div>}
        rules={formRules.gender}
        name="gender">
        <Select
          options={[
            {
              value: 'm',
              label: <div id="gender__male">{t({ id: 'inputs.gender.male' })}</div>,
            },
            {
              value: 'f',
              label: <div id="gender__female">{t({ id: 'inputs.gender.female' })}</div>,
            },
          ]}
          placeholder={t({ id: 'inputs.gender' })}
        />
      </Form.Item>
      <Form.Item
        help={<div id="error__email">{validationErrors.email[0]}</div>}
        rules={formRules.email}
        name="email">
        <Input placeholder={t({ id: 'inputs.email' })} />
      </Form.Item>
      <Typography.Text className={classes.subTitle}>
        {t({ id: 'inputs.dateOfBirth' })}
      </Typography.Text>
      <Form.Item>
        <Space wrap={false}>
          <Form.Item
            rules={[{ validator: (rule, value) => validateDate('day', rule, value) }]}
            name={['dateOfBirth', 'day']}
            noStyle>
            <InputNumber min={1} max={31} placeholder={t({ id: 'inputs.day' })} />
          </Form.Item>
          <Form.Item
            rules={[{ validator: (rule, value) => validateDate('month', rule, value) }]}
            name={['dateOfBirth', 'month']}
            noStyle>
            <InputNumber min={1} max={12} placeholder={t({ id: 'inputs.month' })} />
          </Form.Item>
          <Form.Item
            rules={[{ validator: (rule, value) => validateDate('year', rule, value) }]}
            name={['dateOfBirth', 'year']}
            noStyle>
            <InputNumber
              max={currentYear}
              min={currentYear - 100}
              placeholder={t({ id: 'inputs.year' })}
            />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item
        help={<div id="error__countryOfBirth">{validationErrors.countryOfBirth[0]}</div>}
        rules={formRules.countryOfBirth}
        name="countryOfBirth">
        <Select
          showSearch
          filterOption={filterOption}
          placement="bottomLeft"
          placeholder={t({ id: 'cryptolite.survey.form.fields.countryOfBirth' })}>
          {countries.map((country, idx) => (
            <Select.Option
              id={`country${idx}`}
              label={country.name}
              value={country.id}
              key={country.id}>
              <div  style={{ display: 'flex' }}>
                <div
                  className={`fi fi-${country.iso2.toLowerCase()}`}
                  style={{ marginRight: 6 }}
                />
                <div id={`country${idx}`}>{country.name}</div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button id="btn__next" onClick={isRiskLevel} type="primary" block>
        {t({ id: 'buttons.next' })}
      </Button>
      <RiskModal
        riskLevel={riskLevel}
        open={riskLevelModal}
        onOk={() => {
          setRiskLevelModal(false);
          setRiskLevel('');
          form.submit();
        }}
        onClose={() => {
          setRiskLevelModal(false);
        }}
      />
    </Form>
  );
};

export default PersonalDataForm;
