import { Typography } from 'antd';
import { Snowflake } from 'common/components/icons';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';

export const FrozenOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
      <Snowflake />
      <Typography.Text className={classes['text']}>
        {t({ id: 'card.universal.status.frozen' })}
      </Typography.Text>
    </div>
  );
};
