import React from 'react';
import { Col, ColProps, Row, RowProps } from 'antd';
import classes from './GridContainer.module.css'

type GridContainerProps = {
  children: React.ReactNode;
  rowProps?: RowProps;
  colProps?: ColProps;
};

const GridContainer: React.FC<GridContainerProps> = ({
  children,
  rowProps,
  colProps,
}) => {
  return (
    <Row {...rowProps}>
      <Col xs={2} sm={4} md={4} lg={6} xl={8}  className={classes.side}/>
      <Col xs={20} sm={16} md={16} lg={12} xl={8} className={classes.center} {...colProps}>
        {children}
      </Col>
      <Col  xs={2} sm={4} md={4} lg={6} xl={8} className={classes.side} />
    </Row>
  );
};

export default GridContainer;
