import moment from 'moment';
import {
  RIVER_TRANSACTION_STATUS,
  RIVER_TRANSACTION_TYPE,
} from '../../../../../lib/constants/values';

export const TRANSACTION_COUNT = 10;

export type TabsType = 'IN' | 'OUT' | 'PENDING' | 'ALL';

export const FILTERS = {
  ALL: 'ALL',
  OUT: 'OUT',
  IN: 'IN',
  PENDING: 'PENDING',
  HOLD: 'HOLD',
} as const;
export type Filter = keyof typeof FILTERS;

export const FILTERS_CONFIG = {
  [FILTERS.ALL]: {},
  [FILTERS.IN]: { type: 'IN' },
  [FILTERS.OUT]: { type: 'OUT' },
  [FILTERS.PENDING]: { status: RIVER_TRANSACTION_STATUS.PENDING },
};

export const ALL_CONFIG = {
  start: moment('01-01-2020', 'DD-MM-YYYY').startOf('month').format('DD-MM-YYYY'),
  end: moment().endOf('month').format('DD-MM-YYYY'),
  page: 0,
  size: TRANSACTION_COUNT,
};

export const MONTH_CONFIG = {
  start: moment().startOf('month').format('DD-MM-YYYY'),
  end: moment().endOf('month').format('DD-MM-YYYY'),
  page: 0,
  size: 1000,
};

export const TRANSACTION_STATUS_TAG = {
  [RIVER_TRANSACTION_STATUS.APPROVED]: {
    title: 'transactions.item.status.approved',
    color: 'success',
  },
  [RIVER_TRANSACTION_STATUS.PENDING]: {
    title: 'transactions.item.status.pending',
    color: 'processing',
  },
  [RIVER_TRANSACTION_STATUS.DECLINED]: {
    title: 'transactions.item.status.declined',
    color: 'error',
  },
} as const;

export const TRANSACTION_TYPE_LOCALIZED = {
  [RIVER_TRANSACTION_TYPE.TRANSFER_CREDIT]: 'Transfer credit',
  [RIVER_TRANSACTION_TYPE.SEND_CREDIT]: 'Send credit',
  [RIVER_TRANSACTION_TYPE.TRANSFER_DEBIT]: 'Transfer debit',
  [RIVER_TRANSACTION_TYPE.SEND_DEBIT]: 'Send debit',
  [RIVER_TRANSACTION_TYPE.WITHDRAW_SEPA]: 'Withdraw SEPA',
};
