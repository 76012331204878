import React, { useRef, useState } from 'react';
import { Button, Divider, Drawer, Flex, Typography } from 'antd';
import { hideLoader, showLoader } from 'common/store/actions/app';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentAccount, getCurrentCard } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './GetPIN.module.css';
import { useIntl } from 'react-intl';
import { Push } from 'common/components/UI';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import cardApi from 'river/api/card';
import { getValidateConfirmation } from 'common/store/selectors/app';
import { VALIDATION_METHOD } from 'common/lib/constants/validation';
import { CloseOutlined } from "@ant-design/icons";

type GetPINProps = {
  isOpen: boolean;
  onClose: () => unknown;
  showError?: (text: string, className?: string) => unknown;
  showSuccess?: (text: string, className?: string) => unknown;
};

const GetPIN: React.FC<GetPINProps> = ({ isOpen, onClose, showError, showSuccess }) => {
  const { formatMessage: t } = useIntl();
  const account = useSelector(getCurrentAccount) || 0;
  const cardId = useSelector(getCurrentCard) || 0;
  const validateConfirmation = useSelector(getValidateConfirmation);
  const isTwoFactorEnabled = validateConfirmation === VALIDATION_METHOD.TWO_FA;
  const isBiometryEnabled = validateConfirmation === VALIDATION_METHOD.BIOMETRY;
  const shouldSendSms = !isBiometryEnabled && !isTwoFactorEnabled;

  const dispatch: RiverThunkDispatch = useDispatch();
  const operationId = useRef<string>('');
  const [pin, setPIN] = useState<string>('');
  const [nextStep, setNextStep] = useState<boolean>(false);

  const onSign = (code: string) => {
    const params = isTwoFactorEnabled ? { google2faCodeFromUser: code } : { code };

    dispatch(showLoader());
    cardApi
      .signGetPin(account, cardId, { params })
      .then(({ data: { response } }) => {
        console.log();
        showSuccess?.(t({id: 'common.message.success'}), 'toast__code-success');
        setPIN(response.pin);
      })
      .catch(() => {
        showError?.(t({id: 'common.otp.wrong'}), 'toast__code-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onSendCode = () => {
    dispatch(showLoader());
    cardApi
      .sendGetPinToken(account, cardId)
      .then((response) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }), 'toast__code-sent');
        }

        setNextStep(true);
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }), 'toast__code-sent-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onResendCode = () => {
    dispatch(showLoader());
    cardApi
      .resendGetPinToken(account, cardId)
      .then((response) => {
        if (shouldSendSms) {
          showSuccess?.(t({ id: 'common.otp.sent' }), 'toast__code-sent');
        }
        setNextStep(true);
      })
      .catch((error) => {
        showError?.(t({ id: 'common.otp.error' }), 'toast__code-sent-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onCloseOverload = () => {
    setNextStep(false);
    setPIN('');
    onClose();
  };

  return (
    <Drawer
      closeIcon={<CloseOutlined id="btn__change-pin-close" />}
      title={t({ id: 'card.manage.getPIN' })}
      open={isOpen}
      onClose={onCloseOverload}
      width={560}
      destroyOnClose>
      <Flex vertical justify="center" align="center" gap={16}>
        <Typography.Text className={classes.text}>
          {t({ id: 'card.manage.getPIN.description' })}
        </Typography.Text>
        <Button id='btn__change-pin-submit' disabled={nextStep} className={classes.button} onClick={onSendCode} block>
          {t({ id: 'card.manage.getPIN' })}
        </Button>
        {nextStep && (
          <>
            <Divider />
            {pin ? (
              <Flex align="center" justify="center" vertical gap={8}>
                <Typography.Text className={classes.text}>PIN:</Typography.Text>
                <Typography.Text className={classes.pin}>{pin}</Typography.Text>
              </Flex>
            ) : isBiometryEnabled ? (
              <Push onResend={onResendCode} />
            ) : (
              <>
                <Typography.Text className={classes.text}>
                  {t({
                    id: isTwoFactorEnabled
                      ? 'screens.auth.two.factor.legend'
                      : 'screens.payment.create.create.nav.phone.description',
                  })}
                </Typography.Text>
                <CodeInput
                  timeout={60}
                  onResend={!isTwoFactorEnabled ? onResendCode : undefined}
                  onEnter={onSign}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </Drawer>
  );
};

export default GetPIN;
