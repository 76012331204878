import { Flex, Form, FormInstance, Input, InputNumber, Typography } from 'antd';
import classes from '../C2C.module.scss';
import React from 'react';
import { getSendC2CScheme } from '../model/scheme';
import { C2CForm } from '../model/types';
import { CardInfoT } from 'common/types/cards';
import SelectCard from '../../../../components/CardDropdown/SelectCard';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;

type AmountProps = {
  currentFrom: CardInfoT;
  onCancel: () => void;
  fee: number;
  formValues: Partial<C2CForm>;
  form: FormInstance;
}

const Amount: React.FC<AmountProps> = ({
                                         currentFrom,
                                         onCancel,
                                         fee,
                                         formValues,
                                         form,
                                       }) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendC2CScheme(t);

  return (
    <Flex vertical gap={12}>
      <Title level={3}>{t({ id: 'screens.c2cpayment.create.create.title' })}</Title>
      <Flex vertical gap={24}>
        <Flex vertical gap={8}>
          <Text style={{
            fontSize: 16,
            lineHeight: '20px',
          }}>
            {t({ id: 'noty.card.from' })}:
          </Text>
          <SelectCard
            currentOption={currentFrom}
            className="card_disabled"
          />
        </Flex>
        <Form initialValues={formValues} form={form} name="amount">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Form.Item name="to" rules={rules.to}>
              <Input
                size="large"
                disabled
                placeholder={t({ id: 'screens.c2cpayment.create.beneficiary.inputs.to' })}
              />
            </Form.Item>
          </Flex>
          <Flex vertical>
            <Form.Item name="amount">
              <InputNumber
                addonBefore="€"
                disabled
                placeholder={t({ id: 'screens.c2cpayment.create.beneficiary.inputs.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
            <Text style={{ fontSize: 14 }}>
              {t({id: 'screens.payment.create.beneficiary.inputs.amount.info2'}, {amount: (form.getFieldValue('amount') ?? 0) + fee, fee: fee})}
            </Text>
          </Flex>
          <Flex vertical gap={8} style={{ marginTop: 16 }}>
            <Text>{t({ id: 'screens.c2cpayment.create.create.nav.amount' })}:</Text>
            <Form.Item name="confirmAmount" rules={rules.confirmAmount}>
              <InputNumber
                addonBefore="€"
                placeholder={t({ id: 'screens.c2cpayment.create.beneficiary.inputs.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
          </Flex>
          <Flex gap={8}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Amount;