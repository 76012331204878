import { BackButton, CenteredContainer, GreyContainer, GridContainer, RoundContainer } from 'common/components/UI';
import { Flex, MenuProps, message, Typography } from 'antd';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import classes from './TopUpSepa.module.scss';
import { getAccounts, getCurrentAccount } from '../../../store/selectors';
import { Account } from '../../../types';
import AccountDropdown from '../../../components/AccountDropdown/AccountDropdown';
import { RootState } from 'common/store';
import { mapAccountsToOptions } from '../../../components/AccountDropdown/actions';
import { CopyOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { BIC } from '../../../lib/constants/values';

const { Title, Text } = Typography;

const TopUpSepa: React.FC = () => {
  const accounts = useSelector(getAccounts);
  const client = useSelector((state: RootState) => state.river.meta);
  const currentAccount = useSelector(getCurrentAccount);

  const { formatMessage: t } = useIntl();
  const { showError, showSuccess } = useMessage();

  const [currentOption, setCurrentOption] = useState<Account>(currentAccount ? accounts[currentAccount] : Object.values(accounts)[0]);
  const [options, setOptions] = useState<MenuProps['items']>(mapAccountsToOptions(accounts, setCurrentOption));

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccess(t({ id: 'common.clipboard.success' }), 'toast__copy-success');
      })
      .catch(() => {
        showError(t({ id: 'common.clipboard.error' }), 'toast__copy-error');
      });
  };

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.load.sepa.title' })}</Title>
          <AccountDropdown
            label={`${t({ id: 'components.accountsDropdown.header' })}:`}
            options={options}
            currentOption={currentOption}
            id='account'
          />
          <Flex vertical gap={16} className={classes['text']}>
            <Flex gap={8}>
              <Text className={classes.number}>1.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepOne' })}</Text>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>2.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepTwo' })}</Text>
            </Flex>
            <Flex vertical gap={8}>
              <GreyContainer
                title={t({ id: 'sections.load.load.sepa.recipient' }) + ':'}
                action={
                  <RoundContainer
                    size={32}
                    id='recipient-copy-icon'
                    onClick={() => onCopy(client.title)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
                <Text>{client.title}</Text>
              </GreyContainer>
              <GreyContainer
                title="IBAN:"
                action={
                  <RoundContainer
                    size={32}
                    id='iban-copy-icon'
                    onClick={() => onCopy(currentOption.iban)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
                <Text>{currentOption.iban}</Text>
              </GreyContainer>
              <GreyContainer
                title="BIC:"
                action={
                  <RoundContainer
                    size={32}
                    id='bic-copy-icon'
                    onClick={() => onCopy(BIC)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
                <Text>{BIC}</Text>
              </GreyContainer>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>3.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepThree' })}</Text>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>4.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepFour' })}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpSepa;