import {PURGE} from 'redux-persist/es/constants'
import {THEME} from "common/lib/constants/constants";
import {Action} from "redux";
import { Country, Currency } from 'common/types/app';

export type LoaderState = {
    isActive: boolean;
    message: string;
    type: 'transparent' | 'backdrop',
};

type AppReducerInterface = {
    network: boolean;
    activity: boolean;
    loader: LoaderState;
    theme: string;
    settings: Record<string, unknown>;
    countries: Country[],
    currencies: Currency[],
}
type SetAction = {
    type: 'App/SET';
    payload: Partial<AppReducerInterface>;
}
type SetNetworkAction = {
    type: 'App/SET_NETWORK';
    payload: boolean;
}
type SetActivityAction = {
    type: 'App/SET_ACTIVITY';
    payload: boolean;
}
type SetThemeAction = {
    type: 'App/SET_THEME';
    payload: string;
}
type ToggleThemeAction = {
    type: 'App/TOGGLE_THEME';
}
type UpdateSettingsAction = {
    type: 'App/UPDATE_SETTINGS';
    payload: object;
}
type ShowLoaderAction = {
    type: 'App/SHOW_LOADER';
    payload?: {
        message?: string;
        type: 'transparent' | 'backdrop';
    };
}
type HideLoaderAction = {
    type: 'App/HIDE_LOADER';
}
type SetCountriesAction = {
    type: 'App/SET_COUNTRIES';
    payload: Country[];
}
type SetCurrenciesAction = {
    type: 'App/SET_CURRENCIES';
    payload: Currency[];
}

const initialState: AppReducerInterface = {
    network: true,
    activity: false,
    loader: {
        isActive: false,
        message: '',
        type: 'backdrop',
    },
    theme: THEME.LIGHT,
    settings: {},
    countries: [],
    currencies: [],
}

const appReducer = (state: AppReducerInterface = initialState, action: SetAction | SetNetworkAction | SetActivityAction | SetThemeAction | ToggleThemeAction | UpdateSettingsAction | ShowLoaderAction | HideLoaderAction | SetCountriesAction | SetCurrenciesAction | Action<typeof PURGE>) => {
    switch (action.type) {
        case 'App/SET':
            return {...state, ...action.payload}
        case 'App/SET_NETWORK':
            return {
                ...state,
                network: action.payload,
            }
        case 'App/SET_ACTIVITY':
            return {
                ...state,
                activity: action.payload,
            }
        case 'App/SET_THEME': {
            return {
                ...state,
                theme: action.payload,
            }
        }
        case 'App/TOGGLE_THEME': {
            const currentTheme = state.theme;
            let newTheme = '';
            if (currentTheme === THEME.LIGHT) newTheme = THEME.DARK;
            else newTheme = THEME.LIGHT;
            return {
                ...state,
                theme: newTheme,
            }
        }
        case 'App/UPDATE_SETTINGS': {
            return {
                ...state,
                settings: {...state.settings, ...action.payload},
            }
        }
        case 'App/SHOW_LOADER':
            return {
                ...state,
                activity: true,
                loader: {
                    isActive: true,
                    message: action?.payload?.message || '',
                    type: action?.payload?.type || 'transparent',
                },
            };
        case 'App/HIDE_LOADER':
            return {
                ...state,
                loader: initialState.loader,
                activity: initialState.activity,
            };
        case 'App/SET_COUNTRIES': {
            return {
                ...state,
                countries: action.payload,
            }
        }
        case 'App/SET_CURRENCIES': {
            return {
                ...state,
                currencies: action.payload,
            }
        }
        case PURGE:
            return {
                ...initialState,
                theme: state.theme,
            }
        default:
            return state
    }
}

export default appReducer;
