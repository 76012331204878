import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import { FILE_FORMAT } from '../../common/lib/constants/constants';
import {
  CreateBankTransferResponse,
  CreateC2CBody, CreateInternationalPaymentBody,
  GetTransactionsBody,
  GetTransactionsResponse, SignTransferParams, TransferToCardBody,
} from './dto/transfers';

type TransferApi = {
  statement: {
    request: (cardId: number, { start, end, type, status, size, page, language }: GetTransactionsBody) => Promise<GetTransactionsResponse>;
    download: (cardId: number, { start, end, language, format }: {
      start: string, end?: string, language?: string, format?: 'pdf' | 'csv',
    }) => Promise<any>;
    downloadTransaction: (cardId: number, transactionId: string, { language }: { language?: string }) => Promise<any>;
  },
  transferToCard: (cardId: number, body: TransferToCardBody) => Promise<any>,
  sepa: {
    getPayments: (cardId: number) => Promise<any>;
    createPayment: (cardId: number, { amount, beneficiary, currency, details, iban, bic, otpRequired }: {
      amount: number, beneficiary: string, currency?: string, details: string, iban: string, bic: string, otpRequired?: boolean
    }) => Promise<any>;
    confirmPayment: (cardId: number, paymentId: number, { amount, appHash }: { amount?: number, appHash?: string }) => Promise<any>;
    signPayment: (cardId: number, paymentId: number, params: SignTransferParams) => Promise<any>;
    resendSignCode: (cardId: number, paymentId: number, { appHash }?: { appHash?: string }) => Promise<any>;
    rejectPayment: (cardId: number, paymentId: number) => Promise<any>;
    deletePayment: (cardId: number, paymentId: number) => Promise<any>;
    downloadPayment: (cardId: number, paymentId: number) => Promise<any>;
  },
  c2c: {
    getPayments: (cardId: number) => Promise<any>;
    createPayment: (cardId: number, { amount, to, currency }: CreateC2CBody) => Promise<any>;
    confirmPayment: (cardId: number, paymentId: number, { amount, appHash }: { amount?: number, appHash?: string }) => Promise<any>;
    signPayment: (cardId: number, paymentId: number,  params: SignTransferParams) => Promise<any>;
    resendSignCode: (cardId: number, paymentId: number, { appHash }: { appHash?: string }) => Promise<any>;
    deletePayment: (cardId: number, paymentId: number) => Promise<any>;
  },
  swift: {
    getPayments: (cardId: number) => Promise<any>;
    createPayment: (cardId: number, body: CreateInternationalPaymentBody) => Promise<any>;
    confirmPayment: (cardId: number, paymentId: number, { amount, appHash }: { amount?: number, appHash?: string }) => Promise<any>;
    signPayment: (cardId: number, paymentId: number, params: SignTransferParams) => Promise<any>;
    resendSignCode: (cardId: number, paymentId: number, { appHash }: { appHash?: string }) => Promise<any>;
    rejectPayment: (cardId: number, paymentId: number) => Promise<any>;
    deletePayment: (cardId: number, paymentId: number) => Promise<any>;
  },
  invoice: {
    download: (cardId: number, { amount, language }: {
      amount: number, language?: string
    }) => Promise<any>;
  },
  bank: {
    getPayments: ({dateFrom, dateTo, offset, limit}: {
      dateFrom?: string, dateTo?: string, offset?: number, limit?: number
    }) => Promise<any>;
    createPayment: (cardId: number, { amount, details }: {
      amount: number, details: string
    }) => Promise<CreateBankTransferResponse>;
    getPaymentDetails: (paymentId: number) => Promise<any>;
  }
}

const transferApi: TransferApi = {
  statement: {
    request: (cardId, {start, end, type, status, size, page, language}) => API.get(ENDPOINTS.TRANSFER_CTRL.STATEMENT.REQUEST(cardId), {
      params: {
        start, end, type, status, size, page, language,
      }
    }),
    download: (cardId, { start, end, language, format = FILE_FORMAT.PDF }) => API.get(ENDPOINTS.TRANSFER_CTRL.STATEMENT.DOWNLOAD(cardId, format), {
      params: {
        start, end, language,
      },
      headers: {
        'Content-Type': `application/${format}`,
      },
      responseType: 'blob',
    }),
    downloadTransaction: (cardId, transactionId, { language } = {}) => API.get(ENDPOINTS.TRANSFER_CTRL.STATEMENT.DOWNLOAD_SINGLE(cardId, transactionId), {
      params: {
        language,
      },
      headers: {
        'Content-Type': `application/pdf`,
      },
      responseType: 'blob',
    }),
  },
  transferToCard: (cardId: number, body: TransferToCardBody) => API.post(ENDPOINTS.TRANSFER_CTRL.TRANS_TO_CARD(cardId), body),
  sepa: {
    getPayments: (cardId) => API.get(ENDPOINTS.TRANSFER_CTRL.SEPA.LIST(cardId)),
    createPayment: (cardId, { amount, beneficiary, currency = 'EUR', details, iban, bic, otpRequired }) => API.post(ENDPOINTS.TRANSFER_CTRL.SEPA.CREATE(cardId), {
      amount, beneficiary, currency, details, iban, bic, otpRequired, countryCode: iban.slice(0, 2).toLowerCase()
    }),
    confirmPayment: (cardId, paymentId, {amount, appHash} = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.SEPA.CONFIRM(cardId, paymentId), {}, {
      params: { amount, appHash }
    }),
    signPayment: (cardId, paymentId, params) => API.post(ENDPOINTS.TRANSFER_CTRL.SEPA.SIGN(cardId, paymentId), {}, {
      params
    }),
    resendSignCode: (cardId, paymentId, { appHash } = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.SEPA.RESEND_CODE(cardId, paymentId), {}, {
      params: { appHash }
    }),
    rejectPayment: (cardId, paymentId) => API.put(ENDPOINTS.TRANSFER_CTRL.SEPA.REJECT(cardId, paymentId)),
    deletePayment: (cardId, paymentId) => API.put(ENDPOINTS.TRANSFER_CTRL.SEPA.DELETE(cardId, paymentId)),
    downloadPayment: (cardId, paymentId) => API.get(ENDPOINTS.TRANSFER_CTRL.SEPA.DOWNLOAD(cardId, paymentId)),
  },
  c2c: {
    getPayments: (cardId) => API.get(ENDPOINTS.TRANSFER_CTRL.C2C.LIST(cardId)),
    createPayment: (cardId, { amount, to, currency = 'EUR' }) => API.post(ENDPOINTS.TRANSFER_CTRL.C2C.CREATE(cardId), {
      amount, to, currency,
    }),
    confirmPayment: (cardId, paymentId, {amount, appHash} = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.C2C.CONFIRM(cardId, paymentId), {}, {
      params: { amount, appHash }
    }),
    signPayment: (cardId, paymentId, params) => API.post(ENDPOINTS.TRANSFER_CTRL.C2C.SIGN(cardId, paymentId), {}, {
      params
    }),
    resendSignCode: (cardId, paymentId, { appHash } = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.C2C.RESEND_CODE(cardId, paymentId), {}, {
      params: { appHash }
    }),
    deletePayment: (cardId, paymentId) => API.put(ENDPOINTS.TRANSFER_CTRL.C2C.DELETE(cardId, paymentId)),
  },
  swift: {
    getPayments: (cardId) => API.get(ENDPOINTS.TRANSFER_CTRL.SWIFT.LIST(cardId)),
    createPayment: (cardId, body) => API.post(ENDPOINTS.TRANSFER_CTRL.SWIFT.CREATE(cardId), body),
    confirmPayment: (cardId, paymentId, {amount, appHash} = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.SWIFT.CONFIRM(cardId, paymentId), {}, {
      params: { amount, appHash }
    }),
    signPayment: (cardId, paymentId, params) => API.post(ENDPOINTS.TRANSFER_CTRL.SWIFT.SIGN(cardId, paymentId), {}, {
      params
    }),
    resendSignCode: (cardId, paymentId, { appHash } = {}) => API.post(ENDPOINTS.TRANSFER_CTRL.SWIFT.RESEND_CODE(cardId, paymentId), {}, {
      params: { appHash }
    }),
    rejectPayment: (cardId, paymentId) => API.put(ENDPOINTS.TRANSFER_CTRL.SWIFT.REJECT(cardId, paymentId)),
    deletePayment: (cardId, paymentId) => API.put(ENDPOINTS.TRANSFER_CTRL.SWIFT.DELETE(cardId, paymentId)),
  },
  invoice: {
    download: (cardId, { amount, language }) => API.get(ENDPOINTS.TRANSFER_CTRL.INVOICE.DOWNLOAD(cardId), {
      params: { amount, language },
      headers: {
        'Content-Type': `application/${FILE_FORMAT.PDF}`,
      },
      responseType: 'blob',
    })
  },
  bank: {
    getPayments: ({dateFrom, dateTo, offset, limit}) => API.get(ENDPOINTS.TRANSFER_CTRL.OPEN_BANKING.LIST, {
      params: {
        dateFrom, dateTo, offset, limit,
      }
    }),
    createPayment: (cardId, { amount, details }) => API.put(ENDPOINTS.TRANSFER_CTRL.OPEN_BANKING.CREATE(cardId), { amount, details }),
    getPaymentDetails: (paymentId) => API.get(ENDPOINTS.TRANSFER_CTRL.OPEN_BANKING.INFO(paymentId)),
  },
};

export default transferApi;
