import { API } from 'common/http';
import { AxiosResponse } from 'axios';
import { Country } from 'common/types/app';
import { ENDPOINTS } from '../lib/constants/values';

type AppApi = {
  getCountries: (enroll?: boolean) => Promise<any>;
  getCurrencies: () => Promise<any>;
  convertCurrency: (
    cardId: number,
    body: { amount: number; currencyFrom: string; currencyTo: string },
  ) => Promise<any>;
  checkCountryIp: () => Promise<any>;
};

const appApi: AppApi = {
  getCountries: (enroll = undefined) =>
    API.get(ENDPOINTS.GET_COUNTRIES, {
      params: {
        ...(enroll === undefined ? {} : { enroll }),
      },
    }),
  getCurrencies: () => API.get(ENDPOINTS.GET_CURRENCIES),
  convertCurrency: (
    cardId: number,
    body: { amount: number; currencyFrom: string; currencyTo: string },
  ) => API.post(ENDPOINTS.CONVERT_CURRENCY(cardId), body),
  checkCountryIp: () => API.get(ENDPOINTS.CHECK_COUNTRY_IP)
};

export default appApi;
