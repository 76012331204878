import { Flex, Form, FormInstance, Input, InputNumber, MenuProps, Typography } from 'antd';
import classes from '../C2C.module.scss';
import React from 'react';
import { CardDropdown } from '../../../../components';
import { CardInfoT } from 'common/types/cards';
import { C2CForm } from '../model/types';
import { getSendC2CScheme } from '../model/scheme';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

const { Title } = Typography;
const { Text } = Typography;

type BeneficiaryProps = {
  fromOptions: MenuProps['items'];
  currentFrom: CardInfoT;
  formValues: Partial<C2CForm>;
  form: FormInstance;
  onCancel: () => unknown;
}

const Beneficiary: React.FC<BeneficiaryProps> = ({
                                                   fromOptions,
                                                   currentFrom,
                                                   formValues,
                                                   form,
                                                   onCancel,
                                                 }) => {
  const { formatMessage: t } = useIntl();
  const rules = getSendC2CScheme(t);

  return (
    <Flex vertical gap={12}>
      <Title level={3}>{t({ id: 'screens.c2cpayment.create.create.title' })}</Title>
      <Flex vertical gap={24}>
        <CardDropdown
          label={`${t({ id: 'noty.card.from' })}:`}
          options={fromOptions}
          currentOption={currentFrom}
        />
        <Form initialValues={formValues} form={form} name="beneficiary">
          <Flex vertical gap={8}>
            <Text>{t({ id: 'noty.card.to' })}:</Text>
            <Form.Item name="to" rules={rules.to}>
              <Input
                size="large"
                placeholder={t({ id: 'screens.c2cpayment.create.beneficiary.inputs.to' })}
              />
            </Form.Item>
          </Flex>
          <Flex vertical>
            <Form.Item name="amount" rules={rules.amount}>
              <InputNumber
                addonBefore="€"
                placeholder={t({ id: 'screens.c2cpayment.create.beneficiary.inputs.amount' })}
                rootClassName={classes['input']}
              />
            </Form.Item>
            <Text style={{ fontSize: 14 }}>
              {t({ id: 'screens.payment.create.beneficiary.inputs.amount.info' })}
            </Text>
          </Flex>
          <Flex gap={8} style={{marginTop: 16}}>
            <BlackButton
              htmlType="submit"
              type="primary"
            >
              {t({ id: 'buttons.send' })}
            </BlackButton>
            <BlackButton
              onClick={onCancel}
            >
              {t({ id: 'button.simple.cancel' })}
            </BlackButton>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default Beneficiary;