import { RiverThunkDispatch } from "../../types/thunk";
import { hideLoader, showLoader } from "../../../common/store/actions/app";
import { CreateTemplateBody } from "../../api/dto/template";
import api from "../../api";

export const createTemplate = (body: CreateTemplateBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    dispatch(showLoader());
    try {
      await api.template.create(body);
    } catch (err) {
      console.log('RIVER: error while creating template');
      throw err;
    } finally {
      dispatch(hideLoader());
    }
  }
};

export const getTemplates = () => {
  return async (dispatch: RiverThunkDispatch) => {
    dispatch(showLoader());
    try {
      const { data: { response } } = await api.template.list();

      return response;
    } catch (err) {
      console.log('RIVER: error while getting template');
      throw err;
    } finally {
      dispatch(hideLoader());
    }
  }
};

export const editTemplate = (id: string, body: CreateTemplateBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    dispatch(showLoader());
    try {
      const { data: { response } } = await api.template.update(id, body);

      return response;
    } catch (err) {
      console.log('RIVER: error while editing template');
      throw err;
    } finally {
      dispatch(hideLoader());
    }
  }
};

export const deleteTemplate = (id: string) => {
  return async (dispatch: RiverThunkDispatch) => {
    dispatch(showLoader());
    try {
      const { data: { response } } = await api.template.delete(id);

      return response;
    } catch (err) {
      console.log('RIVER: error while deleting template');
      throw err;
    } finally {
      dispatch(hideLoader());
    }
  }
};

