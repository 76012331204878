export default {
  EUR: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-eu`} />
  },
  GBP: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-gb`} />
  },
  CZK: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-cz`} />
  },
  HUF: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-hu`} />
  },
  BHD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-bh`} />
  },
  CHF: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ch`} />
  },
  PLN: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-pl`} />
  },
  RON: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ro`} />
  },
  NOK: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-no`} />
  },
  AUD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-au`} />
  },
  SEK: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-se`} />
  },
  ILS: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-il`} />
  },
  BGN: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-bg`} />
  },
  CAD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ca`} />
  },
  CNY: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-cn`} />
  },
  DKK: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-dk`} />
  },
  HKD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-hk`} />
  },
  MXN: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-mx`} />
  },
  JPY: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-jp`} />
  },
  QAR: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-qa`} />
  },
  AED: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ae`} />
  },
  USD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-us`} />
  },
  NZD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-nz`} />
  },
  TRY: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-tr`} />
  },
  HRK: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-hr`} />
  },
  KWD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-kw`} />
  },
  UGX: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ug`} />
  },
  OMR: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-om`} />
  },
  THB: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-th`} />
  },
  JMD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-jm`} />
  },
  ZAR: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-za`} />
  },
  KES: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-ke`} />
  },
  SGD: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-sg`} />
  },
  SAR: {
    img: () => <span style={{borderRadius: 4, fontSize: 21}} className={`fi fi-sa`} />
  },
}