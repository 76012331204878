import React, { useEffect, useRef } from 'react';
import { Button, message, Progress, Typography } from 'antd';
import classes from '../SignUp/SignUp.module.scss';
import CodeInput from 'common/components/UI/CodeInput/CodeInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'common/store';
import { Navigate, useNavigate } from 'react-router-dom';
import userApi from 'common/api/user';
import { ROUTES } from 'common/components/AppRouter/AppRouter';
import { useIntl } from 'react-intl';
import { GTAG_EVENTS } from '../../../lib/tools/gtag_events';

const VerifyPhone = () => {
  const { phoneVerified, id, phone, phoneCountry } = useSelector((state: RootState) => state.user.meta);
  const codeInputRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage: t } = useIntl();
  const isFirstRender = useRef(true);

  const sendCode = async () => {
    await userApi.auth.sendPhoneCode();
    message.success({
      content:t({id:'notifications.codeSent.success'}),
      className: 'toast__code-sent'
    });
  }
  const onEnter = async (code: string) => {
    dispatch({ type: 'App/SHOW_LOADER' });
    try {
      await userApi.auth.verifyPhone(code);
      dispatch({ type: 'User/SET_META', payload: { phoneVerified: true } });
      gtag('event', GTAG_EVENTS.PHONE_VERIFIED.name);
      codeInputRef?.current?.success()
      navigate(ROUTES.DASHBOARD.path);
      
    } catch (e) {
      codeInputRef?.current?.error()
      message.error({
        content: t({id:'schemas.two.factor.code.invalid'}),
        className: 'toast__code-invalid'
      });
    } finally {
      dispatch({ type: 'App/HIDE_LOADER' });
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      sendCode();
    }
  }, []);

  if (isFirstRender.current && (phoneVerified || !id)) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <Typography.Title className={classes.title}>{t({id:'screens.verifyPhone.title'})}</Typography.Title>
          <Typography.Text className={classes.subTitle}>
            {t({id:'screens.payment.create.create.nav.phone.description'})}
          </Typography.Text>
          <Progress percent={80} rootClassName={classes.progressBar} showInfo={false} />
          <CodeInput ref={codeInputRef} isPhone onEnter={onEnter} onResend={sendCode} timeout={30} />
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;