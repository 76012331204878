import { UserT } from 'common/types/user';
import { UpdateParams, ValidationContext } from './Context';
import { useState } from 'react';

export const ValidateProvider = ({
  userInfo,
  children,
}: {
  userInfo: Partial<UserT>;
  children: React.ReactElement;
}) => {
  const { validateLogin, validateConfirmation, email } = userInfo;
  const [updateParams, setUpdateParams] = useState<UpdateParams | undefined>(undefined);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const onOpenLogin = () => {
    setIsOpenLogin(true);
  };
  const onCloseLogin = () => {
    setIsOpenLogin(false);
  };

  const onOpenConfirmation = () => {
    setIsOpenConfirmation(true);
  };
  const onCloseConfirmation = () => {
    setIsOpenConfirmation(false);
  };

  const onOpenUpdate = (params: UpdateParams) => {
    setUpdateParams(params);
    setIsOpenUpdate(true);
  };
  const onCloseUpdate = () => {
    setUpdateParams(undefined);
    setIsOpenUpdate(false);
  };

  return (
    <ValidationContext.Provider
      value={{
        email: email || '',
        updateParams: updateParams,
        isOpenConfirmation,
        isOpenLogin,
        isOpenUpdate,
        validateConfirmation,
        validateLogin,
        onCloseConfirmation,
        onCloseLogin,
        onCloseUpdate,
        onOpenConfirmation,
        onOpenLogin,
        onOpenUpdate,
      }}>
      {children}
    </ValidationContext.Provider>
  );
};
