import { ERROR_MESSAGES, ERROR_TYPES } from '../constants/errors';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'react-router';
import { ResponseError, Response } from '../../types/app';

const errorMessages = {
  [ERROR_TYPES.INVALID_CARD_NUMBER]: ERROR_MESSAGES.INVALID_CARD_NUMBER,
  [ERROR_TYPES.INVALID_IBAN]: ERROR_MESSAGES.INVALID_IBAN,
  [ERROR_TYPES.NOT_ENOUGH]: ERROR_MESSAGES.NOT_ENOUGH,
  [ERROR_TYPES.RECIPIENT_CARD_NOT_VERIFIED]: ERROR_MESSAGES.RECIPIENT_CARD_NOT_VERIFIED,
  [ERROR_TYPES.SERVICE_UNAVAILABLE]: ERROR_MESSAGES.NO_SUCH_PAYMENT,
};

export const handleError = (
  e: unknown,
  getErrorMessage: (
    errorId: string,
    t: (descriptor: object, values?: object) => string
  ) => string,
  t: (descriptor: object, values?: object) => string
): string => {
  const axiosError = e as AxiosError;
  const axiosResponse = axiosError.response as ErrorResponse;

  if (axiosError && axiosResponse) {
    let { errors } = axiosResponse.data as Response;
    errors = errors as [ResponseError];
    const error = errors[0];
    const errorId = error.id;
    const errorField = error.field;

    // handle incorrect code error that is recognized by error.field
    if (errorField) {
      if (errorField.localeCompare('code') === 0 && errorId === ERROR_TYPES.INVALID_SCHEME)
        return t({ id: ERROR_MESSAGES.INVALID_CODE });
    }

    if (errorField) {
      if (errorField.localeCompare('No such OpenBankingPayment') === 0 && errorId === ERROR_TYPES.SERVICE_UNAVAILABLE)
        return t({ id: 'sections.load.load.bank.checkPayment.notFound' });
    }

    // handle pfs error
    if (errorId === ERROR_TYPES.PFS_ERROR)
      return t({ id: ERROR_MESSAGES.PFS_ERROR });

    return getErrorMessage(errorId, t);
  }

  return t({ id: ERROR_MESSAGES.OTHER });
};