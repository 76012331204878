import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M0 6.00982H20V5.08782C20 3.09596 18.8937 2 16.8902 2H3.10976C1.10627 2 0 3.09596 0 5.08782V6.00982ZM0 14.4122C0 16.404 1.10627 17.5 3.10976 17.5H16.8902C18.8937 17.5 20 16.404 20 14.4122V8.16695H0V14.4122ZM3.07491 12.4638V10.846C3.07491 10.3589 3.41463 10.0196 3.92857 10.0196H6.06272C6.57665 10.0196 6.91638 10.3589 6.91638 10.846V12.4638C6.91638 12.9509 6.57665 13.2901 6.06272 13.2901H3.92857C3.41463 13.2901 3.07491 12.9509 3.07491 12.4638Z"
    />
  </svg>
);

const Card = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Card;
