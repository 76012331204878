import { BackButton, CenteredContainer, GreyContainer, GridContainer, RoundContainer } from 'common/components/UI';
import { Flex, MenuProps, Typography } from 'antd';
import { CardDropdown } from '../../../components';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store';
import React, { useState } from 'react';
import { CardInfoT } from 'common/types/cards';
import classes from './TopUpSepa.module.scss';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import { CopyOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';

const { Title, Text } = Typography;

const TopUpSepa: React.FC = () => {
  const oceanCards = useSelector((state: RootState) => state.user.cards);

  const { formatMessage: t } = useIntl();
  const { showError, showSuccess } = useMessage();

  const [currentOption, setCurrentOption] = useState<CardInfoT>(
    Object.values(oceanCards)[0],
  );
  const [options, setOptions] = useState<MenuProps['items']>(
    mapCardsToOptions(oceanCards, setCurrentOption),
  );

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccess(t({ id: 'common.clipboard.success' }));
      })
      .catch(() => {
        showError(t({ id: 'common.clipboard.error' }));
      });
  };

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.load.sepa.title' })}</Title>
          <CardDropdown
            label={`${t({ id: 'components.cardsDropdown.header' })}:`}
            options={options}
            currentOption={currentOption}
          />
          <Flex vertical gap={8} className={classes['text']}>
            <Flex gap={8}>
              <Text className={classes.number}>1.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepOne' })}</Text>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>2.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepTwo' })}</Text>
            </Flex>
            <Flex vertical gap={8}>
              <GreyContainer
                title={t({ id: 'sections.load.load.sepa.recipient' }) + ':'}
                action={
                  <RoundContainer
                    size={32}
                    onClick={() => onCopy(currentOption.cardName)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
                <Text>{currentOption.cardName}</Text>
              </GreyContainer>
              <GreyContainer
                title="IBAN:"
                action={
                  <RoundContainer
                    size={32}
                    onClick={() => onCopy(currentOption.iban)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
              <Text>{currentOption.iban}</Text>
              </GreyContainer>
              <GreyContainer
                title="BIC:"
                action={
                  <RoundContainer
                    size={32}
                    onClick={() => onCopy(currentOption.bic)}
                    bgColor='transparent'
                    pointer
                  >
                    <CopyOutlined
                      style={{ fontSize: 20 }}
                    />
                  </RoundContainer>
                }>
                <Text>{currentOption.bic}</Text>
              </GreyContainer>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>3.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepThree' })}</Text>
            </Flex>
            <Flex gap={8}>
              <Text className={classes.number}>4.</Text>
              <Text>{t({ id: 'sections.load.load.sepa.stepFour' })}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpSepa;
