import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../Card.module.css';
import { PauseCircleOutlined } from '@ant-design/icons';

export const SuspendedOverlay = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={classes['status-overlay']}>
      <PauseCircleOutlined />
      <Typography.Text className={classes['text']}>{t({id: 'card.universal.status.suspended'})}</Typography.Text>
    </div>
  );
};
