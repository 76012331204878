import React, { useEffect, useState } from 'react';
import { CloseOutlined, PhoneOutlined } from '@ant-design/icons';
import ListItem from '../ListItem';
import { UserT } from 'common/types/user';
import { Carousel, Drawer, Form, Modal, Typography } from 'antd';
import { changePhone, confirmChangePhone } from './actions';
import { CarouselRef } from 'antd/es/carousel';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getCountries, hideLoader, showLoader } from 'common/store/actions/app';
import { CommonThunkDispatch } from 'common/types/store';
import { ApiResponse, Country } from 'common/types/app';
import { CodeInputRef } from 'common/components/UI/CodeInput/CodeInput';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import PhoneInfoStep from './steps/Info';
import PhoneRequestStep from './steps/Request';
import PhoneConfirmStep from './steps/Confirm';
import { appendUserMeta } from 'common/store/actions/user';
import { getPhoneCode } from '../utils';
import { getErrorDefaultMessage } from 'common/lib/utils/helpers';
import SumsubWebSdk from '@sumsub/websdk-react';

type PhoneProps = {
  userInfo: Partial<UserT>;
};

const phoneInitialState = {
  countryCode: -1,
  phoneNumber: '',
  haveErrors: false,
};

export type PhoneChangeState = {
  countryCode: number;
  reason?: string;
  phoneNumber: string;
  haveErrors: boolean;
};

const Phone = ({ userInfo }: PhoneProps) => {
  const { formatMessage: t } = useIntl();
  const dispatch: CommonThunkDispatch = useDispatch();
  const { showError, showSuccess } = useMessage();

  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<number>(-1);
  const [phoneForm, setPhoneForm] = useState<PhoneChangeState>(phoneInitialState);
  const [countries, setCountries] = useState<Country[]>([]);
  const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');
  const [showSumSubModal, setShowSumSubModal] = useState(false);

  const carouselRef = React.useRef<CarouselRef>(null);
  const codeInputRef = React.useRef<CodeInputRef>(null);
  const currentId = React.useRef<string>(null!);

  const phoneNumber = userInfo.phone;
  const isVerified = userInfo.phoneVerified;
  const countryCodeId = userInfo?.phoneCountry || 0;
  const fullNumber =
    phoneCode !== -1 ? `+${phoneCode} ${phoneNumber || ''}` : phoneNumber || '';
  const formattedCountries = countries.filter((country) => country.riskLevel !== 'EXTREME');

  const showDrawer = () => {
    setOpen(true);
  };

  const openSumSubModal = () => setShowSumSubModal(true);
  const closeSumSubModal = () => {
    onSubmit()
    setShowSumSubModal(false);
  }

  const onCloseDrawer = () => {
    setOpen(false);
    carouselRef.current?.goTo(0);
    codeInputRef.current?.clear();
    form.resetFields();
  };

  const onNextSlide = () => {
    if (carouselRef.current && carouselRef.current.next) {
      carouselRef.current.next();
    }
  };

  const onChangePhoneNumber: React.MouseEventHandler<HTMLElement> = (values) => {
    dispatch(showLoader());
    changePhone(phoneForm.countryCode, phoneForm.phoneNumber, phoneForm.reason)
      .then((response) => {
        currentId.current = String(response.id);

        if (response.type === 'PHONE_KYC') {
          setSumSubAccessToken(response.verificationToken)
          openSumSubModal();
        } else {
          onNextSlide();
        }
      })
      .catch((error: ApiResponse<any>) => {
        const errors = error?.data?.errors;
        if (errors?.[0]?.field === 'phone' && errors?.[0]?.id === "schemas.exists") {
          showError(getErrorDefaultMessage(error), 'toast__phone-exists')
        } else {
          showError(getErrorDefaultMessage(error) || t({ id: 'common.message.error' }));
        }
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onSubmit = (code?: string) => {
    dispatch(showLoader());
    confirmChangePhone(currentId.current, code)
      .then((response) => {
        if (String(response.status) === 'GRANTED') {
          if (response?.updateResults && !response?.updateResults?.clientUpdated) {
            showSuccess(t({id: 'river.user.account.sumsub.changePhone.inProgress'}))
          } else {
            dispatch(
              appendUserMeta({
                phoneCountry: phoneForm.countryCode,
                phone: phoneForm.phoneNumber,
              }),
            );
            showSuccess(
              t({ id: 'screens.setting.setting.phone.information.succesfull' }) +
              ' +' +
              getPhoneCode(phoneForm.countryCode, countries) +
              phoneForm.phoneNumber,
            );
          }
        } else if (String(response.status) === 'REJECTED') {
          showError(t({ id: 'profile.settings.request.rejected' }))
        } else {
          showError(t({ id: 'common.message.error' }))
        }

        onCloseDrawer();
      })
      .catch((error: any) => {
        if (error?.data?.errors?.[0]?.id !== "errors.entry.not.found" && error?.data?.errors?.[0]?.id !== "verification.status") {
          codeInputRef.current?.clear();
          showError(getErrorDefaultMessage(error) || t({ id: 'common.message.error' }));
        }
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  useEffect(() => {
    if (countries.length) {
      setPhoneCode(getPhoneCode(countryCodeId, countries));
    }
  }, [countries, countryCodeId]);

  useEffect(() => {
    dispatch(getCountries()).then(setCountries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal closeIcon={<CloseOutlined id='btn__phone-sumsub-close'/>}  style={{ zIndex: 1001 }} footer={null} open={showSumSubModal} onCancel={closeSumSubModal} centered>
        <SumsubWebSdk
          accessToken={sumSubAccessToken}
          expirationHandler={closeSumSubModal}
          config={{}}
          options={{}}
          // onMessage={console.log}
          // onError={console.log}
        />
      </Modal>
      <div id='settings__phone' onClick={showDrawer}>
        <ListItem
          title={t({ id: 'screens.setting.setting.setting.nav.phone' })}
          subtitle={fullNumber}
          verified={isVerified}
          withBorder
          iconComponent={
            <PhoneOutlined
              style={{
                fontSize: 24,
                padding: 12,
                boxSizing: 'content-box',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 100,
              }}
              rotate={90}
            />
          }
        />
      </div>
      <Drawer
        closeIcon={<CloseOutlined id='btn__phone-close'/>}
        styles={{
          body: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        title={t({ id: 'screens.setting.setting.setting.nav.phone' })}
        placement="right"
        width={512}
        onClose={onCloseDrawer}
        destroyOnClose
        open={open}>
        <Carousel ref={carouselRef} dots={false}>
          <PhoneInfoStep
            fullNumber={fullNumber}
            isVerified={!!isVerified}
            onNextStep={onNextSlide}
          />
          <PhoneRequestStep
            form={form}
            countries={formattedCountries}
            onNextStep={onChangePhoneNumber}
            phoneForm={phoneForm}
            setPhoneForm={setPhoneForm}
          />
          <PhoneConfirmStep
            codeInputRef={codeInputRef}
            phoneNumber={fullNumber || ''}
            onSubmit={onSubmit}
          />
        </Carousel>
      </Drawer>
    </>
  );
};

export default Phone;
