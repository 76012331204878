type ValueFromStorage = Record<string, unknown> | string | null

export default {
    get(key: string, session?: boolean): ValueFromStorage  {
      const storage = session ? sessionStorage : localStorage;
      try {
        const data = storage.getItem(key);
        if (!data) return null;
        return JSON.parse(data) as ValueFromStorage;
      } catch (err) {
        return null;
      }
    },
    set(key: string, value: unknown, session?: boolean): void {
      const storage = session ? sessionStorage : localStorage;
      try {
        storage.setItem(key, JSON.stringify(value));
      } catch (err) {
        // Handle or log error if needed
      }
    },
    remove(key: string, session?: boolean): void {
      const storage = session ? sessionStorage : localStorage;
      storage.removeItem(key);
    },
  };
  