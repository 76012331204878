import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconComponent = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.15654 21C3.28657 21 1 17.5496 1 12.4953C1 7.43171 3.28657 4 6.15654 4C9.01711 4 11.3037 7.43171 11.3037 12.4953C11.3037 17.5496 9.01711 21 6.15654 21ZM17.8435 21C14.9829 21 12.6963 17.5496 12.6963 12.4953C12.6963 7.43171 14.9829 4 17.8435 4C20.7134 4 23 7.43171 23 12.4953C23 17.5496 20.7134 21 17.8435 21ZM4.12404 15.7872C3.55004 15.7872 3.05133 15.6473 2.64671 15.3862C3.23011 18.0346 4.60394 19.6851 6.15654 19.6851C8.2361 19.6665 9.9769 16.7383 9.9769 12.4953C9.9769 8.22436 8.2361 5.30554 6.15654 5.31487C4.35928 5.33352 2.80667 7.54361 2.43969 10.91C2.87254 10.5557 3.44654 10.3598 4.12404 10.3598C5.69547 10.3598 6.73995 11.4509 6.73995 13.0642C6.73995 14.7054 5.69547 15.7872 4.12404 15.7872ZM15.8015 15.7872C15.237 15.7872 14.7288 15.638 14.3242 15.3862C14.9076 18.0159 16.2814 19.6758 17.8435 19.6758C19.9042 19.6665 21.6544 16.7477 21.6544 12.4953C21.6544 8.24301 19.9042 5.32419 17.8435 5.32419C16.0368 5.33352 14.4842 7.54361 14.1078 10.91C14.55 10.5557 15.1334 10.3598 15.8015 10.3598C17.373 10.3598 18.4363 11.4509 18.4363 13.0642C18.4363 14.7054 17.373 15.7872 15.8015 15.7872ZM3.38067 12.7471C3.71001 12.8031 4.02053 12.514 4.09581 12.1037C4.1805 11.7027 3.97348 11.3204 3.63473 11.2551C3.3148 11.1898 2.99487 11.4882 2.91018 11.8892C2.8349 12.2995 3.04192 12.7005 3.38067 12.7471ZM15.0582 12.7471C15.3875 12.8031 15.698 12.514 15.7733 12.1037C15.858 11.7027 15.6604 11.311 15.3216 11.2551C14.9923 11.1898 14.6818 11.4882 14.5971 11.8892C14.5124 12.2995 14.7288 12.6912 15.0582 12.7471Z" fill="black"/>
    </svg>
);

const Hand = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconComponent} {...props} />
);

export default Hand;
