import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Flex, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../Settings.module.css';
import { BlackButton } from 'common/components/UI';

const { Text } = Typography;

type PhoneInfoStepProps = {
  isVerified: boolean;
  fullNumber: string;
  onNextStep: () => unknown;
};

const PhoneInfoStep: React.FC<PhoneInfoStepProps> = ({
  isVerified,
  fullNumber,
  onNextStep,
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <div>
      <Flex style={{padding: 24}} className={classes['slide-wrapper']} justify="center" align="center" vertical>
        <PhoneOutlined
          style={{
            fontSize: 36,
            padding: 18,
            boxSizing: 'content-box',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 100,
            marginBottom: 16,
          }}
          rotate={90}
        />
        {isVerified && (
          <Text className={classes.verified} style={{ marginBottom: 16 }}>
            {t({ id: 'profile.settings.phone.verified' })}
          </Text>
        )}
        <Input
          style={{ marginBottom: 32 }}
          disabled
          placeholder="Your phone"
          defaultValue={fullNumber}
        />
        <BlackButton id='btn__phone-change' type={'primary'} onClick={onNextStep}>
          {t({ id: 'screens.setting.setting.phone.buttons.change' })}
        </BlackButton>
      </Flex>
    </div>
  );
};

export default PhoneInfoStep;
