import { RiverThunkDispatch } from '../../types/thunk';
import riverTransactionsApi from '../../api/transactions';
import { GetTransactionsBody } from '../../api/dto/transactions';
import { FILE_FORMAT } from '../../../common/lib/constants/constants';
import { RootState } from '../../../common/store';
import { getCurrentAccount } from '../selectors';
import { getUserLocale } from '../../../common/store/selectors/app';
import { hideLoader, showLoader } from "../../../common/store/actions/app";

export const getTransactions = (accountId: number, body: GetTransactionsBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const { data: { response } } = await riverTransactionsApi.getTransactions(accountId, body);

      return response;
    } catch (e) {
      console.log('RIVER: Error while get transactions: ', e);
      throw e;
    }
  };
};

export const getHolds = (accountId: number, {
  start, end, page, size,
}: GetTransactionsBody) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const { data: { response } } = await riverTransactionsApi.getHolds(accountId, { start, end, page, size });

      return response;
    } catch (e) {
      console.log('RIVER: Error while get holds: ', e);
      throw e;
    }
  };
};

export const getTransactionById = (accountId: number, transactionId: string) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const { data: { response } } = await riverTransactionsApi.getTransactionById(accountId, transactionId);

      return response;
    } catch (e) {
      console.log('RIVER: Error while getting transaction by id: ', e);
      throw e;
    }
  };
};

export const getHoldById = (accountId: number, holdId: string) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const { data: { response } } = await riverTransactionsApi.getHoldById(accountId, holdId);

      return response;
    } catch (e) {
      console.log('RIVER: Error while getting hold by id: ', e);
      throw e;
    }
  };
};

export const downloadTransaction = (accountId: number, transactionId: string) => {
  return async (dispatch: RiverThunkDispatch, getState: () => RootState) => {
    const state = getState(),
      language = getUserLocale(state);
    try {
      const response = await riverTransactionsApi.statement.downloadTransaction(accountId, transactionId, { language });

      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: `application/${FILE_FORMAT.PDF}` }));
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${FILE_FORMAT.PDF}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        throw response.status;
      }

      return response;
    } catch (e) {
      console.log('RIVER: Error while downloading transaction by id: ', e);
      throw e;
    }
  };
};

export const downloadInvoice = (cardId: number, amount: number, language?: string) => {
  return async (dispatch: RiverThunkDispatch) => {
    dispatch(showLoader());
    try {
      const response = await riverTransactionsApi.downloadInvoice(cardId, amount, language);

      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: `application/${FILE_FORMAT.PDF}` }));
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', `statement.${FILE_FORMAT.PDF}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        throw response.status;
      }

      return response;
    } catch (e) {
      console.log('RIVER: Error while downloading transaction by id: ', e);
      throw e;
    } finally {
      dispatch(hideLoader());
    }
  }
};
