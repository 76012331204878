import React from "react";
import { Modal, Result } from "antd";
import { useIntl } from "react-intl";

type ForbiddenModalProps = {
  open: boolean,
  onClose: () => unknown,
}

const Index: React.FC<ForbiddenModalProps> = ({open, onClose}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Modal footer={null} open={open} onCancel={onClose} centered>
      <Result title={(t({id: 'modals.prohibited.text'}))} status='error'/>
    </Modal>
  )
}

export default Index