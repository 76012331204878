import React, {ReactNode} from 'react';
import classes from './CustomCardContainer.module.scss';
import {combineClasses} from "common/lib/utils/helpers";

interface CustomCardContainerProps {
    children: ReactNode;
    className?: string;
}

const CustomCardContainer: React.FC<CustomCardContainerProps> = ({children, className}) => {
    return (
        <div className={combineClasses(classes.container, className)}>
            {children}
        </div>
    );
};

export default CustomCardContainer;
