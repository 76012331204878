import { Drawer, Empty, Flex, Segmented, Typography } from 'antd';
import React from 'react';
import { FILTERS } from './model/constants';
import moment from 'moment';
import { ActivityIndicator, CenteredContainer } from 'common/components/UI';
import TransactionsList, { TransactionItem } from './TransactionsList';
import TransactionDetails from './TransactionDetails';
import { Transaction, TransactionType } from '../../../../types/transfer';
import { useIntl } from 'react-intl';
import { getFilterTypeLabel } from './model/utils';

const { Text, Title } = Typography;

type DrawerTransactionsProps = {
  loading: boolean;
  exportLoading: boolean;
  isOpen?: boolean;
  onClose?: () => unknown;
  onChangeFilter: (activeTab: TransactionType) => void;
  onExportPdf: (transactionId?: string, language?: string) => unknown;
  isEmpty: boolean;
  canLoadMore: boolean;
  onLoadMore: () => void;
  activeTransaction?: Transaction;
  setActiveTransaction: (transaction?: Transaction) => void;
  onDataSelect: (transaction: Transaction) => void;
  groupedData: TransactionItem[];
  currentMonthSpendingAmount: number;
};

const DrawerTransactions: React.FC<DrawerTransactionsProps> = ({
  loading,
  exportLoading,
  isOpen,
  onClose,
  onChangeFilter,
  onExportPdf,
  isEmpty,
  canLoadMore,
  onLoadMore,
  activeTransaction,
  setActiveTransaction,
  onDataSelect,
  groupedData,
  currentMonthSpendingAmount,
}) => {
  const { formatMessage: t } = useIntl();

  const filterSegmentedOptions = Object.values(FILTERS).map((filter) => ({
    value: filter,
    label: getFilterTypeLabel(t, filter),
  }));

  return (
    <Drawer
      title={t({ id: 'transactions.title' })}
      open={isOpen}
      extra={loading && <ActivityIndicator fontSize={18} />}
      onClose={onClose}
      width={560}>
      <Segmented
        style={{ marginBottom: 32 }}
        options={filterSegmentedOptions}
        onChange={(value) => onChangeFilter(value as TransactionType)}
      />
      <Flex vertical>
        {isEmpty && !loading ? (
          <Empty description={t({ id: 'transactions.empty' })} />
        ) : (
          <Flex vertical>
            <Flex vertical gap={4} style={{ marginBottom: 32 }}>
              <Flex justify="space-between">
                <Text strong>
                  {t(
                    { id: 'transactions.spent' },
                    { month: t({ id: `calendar.month.${new Date().getMonth()}` }) },
                  )}
                </Text>
                <Text>{`${currentMonthSpendingAmount.toFixed(2)} €`}</Text>
              </Flex>
            </Flex>
            <TransactionsList
              transactions={groupedData}
              loading={loading}
              canLoadMore={canLoadMore}
              onLoadMore={onLoadMore}
              onDataSelect={onDataSelect}
              // onPayAgain={onPayAgain}
            />
            <TransactionDetails
              loading={exportLoading}
              transaction={activeTransaction}
              setTransaction={setActiveTransaction}
              onExportPdf={onExportPdf}
            />
          </Flex>
        )}
      </Flex>
    </Drawer>
  );
};

export default DrawerTransactions;
