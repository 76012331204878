import { CardInfoT, UniversalCard, UniversalCardStatus } from 'common/types/cards';
import { CardInfo, CardStatus } from 'ocean/types/cards';
import { CARD_DELIVERY_TYPE, CARD_STATUS } from '../constants/values';
import { CARD_UNIVERSAL_STATUS } from 'common/lib/constants/constants';
import moment from 'moment';

export const getCardUniversalStatus = (
  cardStatus: CardStatus | undefined,
): UniversalCardStatus => {
  switch (cardStatus) {
    case CARD_STATUS.DEPOSIT_ONLY:
      return CARD_UNIVERSAL_STATUS.FROZEN;
    case CARD_STATUS.CLOSED:
      return CARD_UNIVERSAL_STATUS.CLOSED;
    default:
      return CARD_UNIVERSAL_STATUS.ACTIVE;
  }
};

export const getUniversalCard = (card: CardInfo): UniversalCard => ({
  cvv: '•••',
  expDate: `${card.expirationDate?.slice(0, 2) ?? '**'}/${card.expirationDate?.slice(2) ?? '**'}`,
  id: card.id,
  iban: card.iban,
  isVirtual: card.deliveryType === CARD_DELIVERY_TYPE.VC,
  number: card.numberFull
    ? card.numberFull.match(/.{1,4}/g)!.join(' ')
    : card.cardNumber,
  universalCardStatus: getCardUniversalStatus(card.cardStatus),
});
