import React, { useState, useEffect } from 'react';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import { Button, Drawer, Flex, Input, Typography, Form } from 'antd';
import RoundContainer from 'common/components/UI/RoundContainer/RoundContainer';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { getSignUpScheme } from 'common/pages/Auth/SignUp/model/scheme';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountTitle } from 'river/store/actions/user';
import { getAccountTitle, getCurrentAccount } from 'river/store/selectors';
import { RiverThunkDispatch } from 'river/types/thunk';
import classes from './DrawerAccountName.module.css';
import { BlackButton } from 'common/components/UI';
import { useIntl } from 'react-intl';

type DrawerAccountNameProps = {
  isOpen: boolean;
  onClose: () => unknown;
  showError?: (text: string, className?: string) => unknown;
  showSuccess?: (text: string, className?: string) => unknown;
};

const DrawerAccountName: React.FC<DrawerAccountNameProps> = ({
  isOpen,
  onClose,
  showError,
  showSuccess,
}) => {
  const { formatMessage: t } = useIntl();
  const accountTitle = useSelector(getAccountTitle);
  const account = useSelector(getCurrentAccount);
  const dispatch: RiverThunkDispatch = useDispatch();
  const [value, setValue] = useState<string>(accountTitle);
  const formRules = getSignUpScheme(t);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    account: [],
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('account', accountTitle)
  },[])



  const onChangeName = (name:string) => {
    if (account) {
      dispatch(showLoader());
      dispatch(setAccountTitle(account, name))
        .then(() => {
          showSuccess?.(t({ id: 'account.name.change.success' }), 'toast__account-name-change-success');
          onClose();
        })
        .catch(() => {
          showError?.(t({ id: 'account.name.change.error' }), 'toast__account-name-change-error');
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  };

  const onFieldsChange = () => {
    setValidationErrors((prevState) => ({
      ...prevState,
      account: form.getFieldError('account'),
    }));
  }; 

  return (
    <Drawer
      title={t({ id: 'account.name.change.title' })}
      open={isOpen}
      closeIcon={<CloseOutlined id='btn__account-name-close'/>} 
      onClose={onClose}
      width={560}>
      <Flex vertical justify="center" align="center" gap={16}>
        <RoundContainer padding={18}>
          <EditFilled style={{ fontSize: 36 }}></EditFilled>
        </RoundContainer>
        <Typography.Text className={classes.text}>
          {t({ id: 'account.name.change.description' })}
        </Typography.Text>

        <Form
        onFinish={(e) => onChangeName(e.account)}
        onFieldsChange={onFieldsChange}
        form={form}
        validateTrigger="onChange"
        style={{
          width: '100%',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: 16,
          alignItems: 'center',
          marginTop: 50,
        }}>
        <Form.Item style={{ width: '100%' }} rules={formRules.account} name="account">
          <Input
            style={{ marginBottom: 32 }}
            placeholder="Account name"
          />
        </Form.Item>
        <BlackButton id='btn__account-name-submit' type="primary" className={classes.button} onClick={form.submit}>
          {t({ id: 'buttons.change' })}
        </BlackButton>
      </Form>
      </Flex>
    </Drawer>
  );
};

export default DrawerAccountName;
