import Phone from './Phone/Phone';
import Email from './Email/Email';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'common/store/index';
import Language from './Language/Language';
import { Locale } from 'common/locales/types';
import { setLocale } from 'common/store/actions/language';
import BugReport from './BugReport/BugReport';
import classes from './Settings.module.css';
import Password from './Password/Password';
import Validation from './TwoFactor';


type SettingsTabProps = {
  showError: (message: string, className?: string) => void;
  showSuccess: (message: string, className?: string) => void;
};

const Settings = ({ showError, showSuccess }: SettingsTabProps) => {
  const dispatch = useDispatch();
  const userMeta = useSelector((state: RootState) => state.user?.meta);
  const locale = useSelector((state: RootState) => state.language.locale);

  const onSetLocale = (locale: Locale) => {
    dispatch(setLocale(locale));
  };

  return (
    <div className={classes.wrapper}>
      <Email userInfo={userMeta} />
      <Phone userInfo={userMeta} />
      <Password
        showError={showError}
        showSuccess={showSuccess}
      />
      <Language
        locale={locale as Locale}
        onSetLocale={onSetLocale}
        showError={showError}
        showSuccess={showSuccess}
      />
      <Validation userInfo={userMeta} />
      <BugReport userInfo={userMeta} />
    </div>
  );
};

export default Settings;
