import UseCountdown from 'common/lib/hooks/useCountdown';
import { Button, Flex, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { MobileOutlined } from '@ant-design/icons';
import classes from './Push.module.css';
import { useDispatch } from 'react-redux';
import { refreshRiver } from 'river/store/actions';
import { RiverThunkDispatch } from 'river/types/thunk';
import { AppDispatch } from 'common/store';
import { hideLoader, showLoader } from 'common/store/actions/app';

const { Text } = Typography;

export interface PushProps {
  timeout?: number;
  onResend?: () => any;
}

const Push = ({ timeout = 60, onResend }: PushProps) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const [countdown, resetCountdown] = UseCountdown(timeout);

  const isResendDisabled = countdown !== 0;

  const formatTime = (time: number): string => {
    const minutes: string = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleResendClick = () => {
    onResend?.();
    resetCountdown(timeout);
  };

  const handleRefresh = async () => {
    dispatch(showLoader());
    await dispatch(refreshRiver());
    dispatch(hideLoader());
    location.reload();
  };

  return (
    <div className={classes.container}>
      <Flex gap={16} className={classes.content}>
        <MobileOutlined style={{ fontSize: 36 }} />
        <Text className={classes.wrap}>{t({ id: 'screens.validation.push.sent' })}</Text>
      </Flex>
      {onResend &&
        (countdown > 0 ? (
          <Text type={'secondary'}>
            {t({ id: 'common.otp.resend.time' }, { time: formatTime(countdown) })}
          </Text>
        ) : (
          <Button
            className={classes['no-padding']}
            type="link"
            disabled={isResendDisabled}
            onClick={handleResendClick}>
            {t({ id: 'common.otp.resend' })}
          </Button>
        ))}
      <Flex gap={8} className={classes.hint} vertical>
        <Text type="warning">{t({ id: 'screens.validation.push.refresh.hint' })}</Text>
        <Button block type="default" onClick={handleRefresh}>
          {t({ id: 'screens.validation.push.refresh.button' })}
        </Button>
      </Flex>
    </div>
  );
};

export default Push;
