import { Drawer, List, Switch, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { VALIDATION_METHOD, VALIDATION_TYPE } from 'common/lib/constants/validation';
import { ValidationMethod } from 'common/types/validation';
import { useValidationContext } from './context';

import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'common/store/actions/app';
import { loginActions } from './actions';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { handleErrorWithCommonFallback } from 'common/lib/utils/helpers';

import classes from './Validate.module.css';

type ValidateLoginProps = {
  isOpen: boolean;
  currentMethod: ValidationMethod;
  onClose: () => unknown;
};

const ValidateLogin = ({ isOpen, currentMethod, onClose }: ValidateLoginProps) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch();
  const { showError } = useMessage();
  const { onOpenUpdate } = useValidationContext();

  const onToggleNo = () => {
    dispatch(showLoader());
    loginActions
      .enableNo()
      .then(({ data: { response } }) => {
        const { id, property1, property2, status } = response;
        onOpenUpdate({
          id: id,
          method: VALIDATION_METHOD.NO,
          status,
          secret: property1,
          type: VALIDATION_TYPE.LOGIN,
        });
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onToggleTwoFactor = () => {
    dispatch(showLoader());
    loginActions
      .enableTwoFactor()
      .then(({ data: { response } }) => {
        const { id, property1, property2, status } = response;
        onOpenUpdate({
          id: id,
          method: VALIDATION_METHOD.TWO_FA,
          status,
          secret: property1,
          type: VALIDATION_TYPE.LOGIN,
        });
      })
      .catch(({ response }) => {
        handleErrorWithCommonFallback(response, t, showError);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  return (
    <Drawer
      title={t({ id: 'screens.validation.login.title' })}
      placement="right"
      width={512}
      onClose={onClose}
      open={isOpen}>
      <Typography.Text className={classes['list-title']}>
        {t({ id: 'screens.validation.list.title' })}
      </Typography.Text>
      <List bordered>
        <List.Item>
          <List.Item.Meta title={t({ id: 'screens.validation.type.no' })} />
          <Switch value={currentMethod === VALIDATION_METHOD.NO} onClick={onToggleNo} />
        </List.Item>
        <List.Item>
          <List.Item.Meta title={t({ id: 'screens.validation.type.2fa' })} />
          <Switch
            value={currentMethod === VALIDATION_METHOD.TWO_FA}
            onClick={onToggleTwoFactor}
          />
        </List.Item>
      </List>
    </Drawer>
  );
};

export default ValidateLogin;
