import React, { CSSProperties } from 'react';
import classes from './RoundContainer.module.css';
import { combineClasses, getGreyColor } from 'common/lib/utils/helpers';

type RoundContainerProps = {
  children: React.ReactNode;
  size?: number;
  id?: string;
  padding?: number;
  bgColor?: string;
  pointer?: boolean;
  onClick?: () => unknown;
};

const RoundContainer: React.FC<RoundContainerProps> = ({
  children,
  size,
  id,
  padding,
  bgColor,
  pointer,
  onClick,
}) => {
  const styles: CSSProperties = {};
  styles.width = size || 'min-content';
  styles.height = size || 'min-content';

  if (padding) {
    styles.padding = padding;
  }

  styles.backgroundColor = bgColor || getGreyColor();

  return (
    <div
      id={id}
      className={combineClasses(classes.wrapper, pointer ? classes.pointer : '')}
      style={styles}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default RoundContainer;
