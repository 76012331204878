import { Country } from 'common/types/app';
import { RootState } from 'common/store/index';
import { LoaderState } from 'common/store/reducers/appReducer';
import { getAddress } from 'ocean/store/selectors';

export const getLoader = (state: RootState): LoaderState => state.app.loader;
export const getCountries = (state: RootState): Country[] => state.app.countries;
export const getEnrollCountries = (state: RootState): Country[] => state.app.countries.filter(country => country.enroll);
export const getPhoneCountries = (state: RootState): Country[] => state.app.countries.filter(country => country.riskLevel !== 'EXTREME');
export const getEUCountries = (state: RootState): Country[] => state.app.countries.filter(country => country.eu);
export const getUserLocale = (state: RootState): string => state.language.locale;
export const getValidateLogin = (state: RootState) => state.user.meta.validateLogin;
export const getValidateConfirmation = (state: RootState) => state.user.meta.validateConfirmation;

export const getIsUserEU = (state: RootState): boolean => {
    const userCountryId = getAddress(state)?.country || -1;
    const euCountries = getEUCountries(state);

    return euCountries.some(country => country.id === userCountryId);
}

