import { API } from 'common/http';
import { ENDPOINTS } from '../lib/constants/values';
import { EventType } from '../types/notifications';
import { NotificationsResponse } from './dto/notifications';
import { SET_LEVEL_NOTIFICATION } from 'common/lib/constants/constants';

type NotificationsApi = {
  get: (eventType?: EventType, dateFrom?: string, dateTo?: string, offset?: number, limit?: number) => Promise<NotificationsResponse>,
  getUnreadCount: () => Promise<any>,
  addToken: (cardholderId: string) => Promise<any>,
  deleteToken: (cardholderId: string) => Promise<any>,
  setReadStatus: (notificationId: string | number) => Promise<any>,
  deleteNotify: (notificationId: string | number) => Promise<any>,
  setLevelNotify: (type: string) => Promise<any>,
};

const notificationsApi: NotificationsApi = {
  get: (eventType, dateFrom, dateTo, offset, limit) =>
    API.get(ENDPOINTS.NOTIFICATIONS_CTRL.GET_LIST, {
      params: {
        eventType,
        dateFrom,
        dateTo,
        offset,
        limit,
      }
    }),
  getUnreadCount: () => API.get(ENDPOINTS.NOTIFICATIONS_CTRL.GET_UNREAD_COUNT),
  addToken: (cardholderId) => API.get(ENDPOINTS.NOTIFICATIONS_CTRL.ADD_TOKEN(cardholderId)),
  deleteToken: (cardholderId) => API.get(ENDPOINTS.NOTIFICATIONS_CTRL.DELETE_TOKEN(cardholderId)),
  setReadStatus: (notificationId) => API.post(ENDPOINTS.NOTIFICATIONS_CTRL.SET_READ_STATUS(notificationId)),
  deleteNotify: (notificationId) => API.post(ENDPOINTS.NOTIFICATIONS_CTRL.DELETE(notificationId)),
  setLevelNotify: (level) => API.post(ENDPOINTS.NOTIFICATIONS_CTRL.SET_LEVEL, {}, {params:{level}})
};

export default notificationsApi;