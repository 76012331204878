import { Flex, Typography } from 'antd';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../../../common/components/AppRouter/AppRouter';
import { BlackButton } from '../../../../../common/components/UI';
import { useIntl } from 'react-intl';

const { Text } = Typography;

const Declined: React.FC = () => {
  const { formatMessage: t } = useIntl();

  return (
    <Flex vertical align="center" gap={32} style={{marginTop: 32}}>
      <Flex vertical align="center" gap={16}>
        <Flex justify="center" align="center" style={{
          borderRadius: '50%',
          backgroundColor: '#FF2121',
          width: 80,
          height: 80,
        }}>
          <CloseOutlined style={{ color: 'white', fontSize: 40 }} />
        </Flex>
        <Flex vertical align="center">
          <Text strong style={{ fontSize: 20 }}>
            {t({ id: 'screens.payment.create.declined.title' })}
          </Text>
        </Flex>
      </Flex>
      <BlackButton href={ROUTES.OCEAN_SEND.path} type="primary">
        {t({ id: 'sections.send.swift.success.button' })}
      </BlackButton>
    </Flex>
  );
};

export default Declined;