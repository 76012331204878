import React, { useState } from 'react';
import { Flex, Space, Typography } from 'antd';
import PlanInfoComponent from './components/Plan';
import { CenteredContainer } from 'common/components/UI';
import DrawerPlanDetails from 'common/components/drawers/DrawerPlanDetails';
import { PLANS } from 'common/lib/constants/constants';

const { Title } = Typography;

const INFO_ARRAY = [
  'Lithuanian IBAN account',
  'Virtual cards',
  'Plastic cards',
  'Secondary cards',
  'Acc to Acc transfers',
  'SEPA payments',
  'Maximum acc balance € 15k',
];

const Plans = () => {
  const [isOpen, setIsOpen] = useState<Record<keyof typeof PLANS, boolean>>({
    [PLANS.RIVER]: false,
    [PLANS.OCEAN]: false,
    [PLANS.SEA]: false,
  });

  const onOpenRiver = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.RIVER]: true }));
  };

  const onCloseRiver = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.RIVER]: false }));
  };

  const onOpenOcean = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.OCEAN]: true }));
  };

  const onCloseOcean = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.OCEAN]: false }));
  };

  const onOpenSea = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.SEA]: true }));
  };

  const onCloseSea = () => {
    setIsOpen((prev) => ({ ...prev, [PLANS.SEA]: false }));
  };

  return (
    <>
      <CenteredContainer>
        <Space style={{ marginTop: 52 }} size={32} direction="vertical" align="center">
          <Title> Choose your WestStein plan</Title>
          <Flex gap={8}>
            <PlanInfoComponent
              title="OCEAN"
              info={INFO_ARRAY}
              onOpen={onOpenOcean}
              bgColor="#E0CCFB"
            />
            <PlanInfoComponent
              title="RIVER"
              info={INFO_ARRAY}
              onOpen={onOpenRiver}
              bgColor="#C5F1C1"
            />
            <PlanInfoComponent
              title="SEA"
              info={INFO_ARRAY}
              onOpen={onOpenSea}
              bgColor="#D4EEEE"
            />
          </Flex>
        </Space>
      </CenteredContainer>
      <DrawerPlanDetails
        title="RIVER PLAN"
        bgColor="#C5F1C1"
        info={INFO_ARRAY}
        isOpen={isOpen[PLANS.RIVER]}
        onClose={onCloseRiver}
        onOpen={() => {}}
      />
      <DrawerPlanDetails
        title="OCEAN PLAN"
        bgColor="#E0CCFB"
        info={INFO_ARRAY}
        isOpen={isOpen[PLANS.OCEAN]}
        onClose={onCloseOcean}
        onOpen={() => {}}
      />
      <DrawerPlanDetails
        title="SEA PLAN"
        bgColor="#D4EEEE"
        info={INFO_ARRAY}
        isOpen={isOpen[PLANS.SEA]}
        onClose={onCloseSea}
        onOpen={() => {}}
      />
    </>
  );
};

export default Plans;
