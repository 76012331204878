import React from 'react';
import { Layout, theme as antdTheme } from 'antd';
import { Outlet } from 'react-router-dom';
import classes from './AppLayout.module.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const AuthLayout = () => {
  const {
    token: { colorBgBase },
  } = antdTheme.useToken();
  return (
    <Layout className={classes.container} style={{ backgroundColor: colorBgBase }}>
      <Header />
      <Layout.Content className={classes.contentContainer}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default AuthLayout;
