import React, { ReactNode } from 'react';
import { message } from 'antd';
import MessageContext from './Context';
import classes from './Message.module.css';

interface IMessageProviderProps {
  children: ReactNode;
}

export const MessageProvider: React.FC<IMessageProviderProps> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showError = (content: ReactNode, className?: string, duration?: number, onClose?: VoidFunction) =>
    messageApi.error({ content, duration, onClose, className: `${classes.message} ${className ?? ''}` });

  const showSuccess = (content: ReactNode, className?: string, duration?: number, onClose?: VoidFunction) =>
    messageApi.success({ content, duration, onClose, className: `${classes.message} ${className ?? ''}` });

  const showWarning = (content: ReactNode, className?: string, duration?: number, onClose?: VoidFunction) =>
    messageApi.warning({ content, duration, onClose, className: `${classes.message} ${className ?? ''}` });

  const showLoading = (content: ReactNode, className?: string, duration?: number, onClose?: VoidFunction) =>
    messageApi.loading({ content, duration, onClose, className: `${classes.message} ${className ?? ''}` });

  const showInfo = (content: ReactNode, className?: string, duration?: number, onClose?: VoidFunction) =>
    messageApi.info({ content, duration, onClose, className: `${classes.message} ${className ?? ''}` });

  return (
    <MessageContext.Provider
      value={{ showError, showSuccess, showWarning, showLoading, showInfo }}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};
