import { ENDPOINTS } from '../lib/constants/values';
import { API } from '../../common/http';

type UserApi = {
  verification: {
    getVerificationStatus: (cardholderId: string) => Promise<any>;
    startVerification: (isWeb: boolean) => Promise<any>;
  },
  hmac: {
    deleteHmac: () => Promise<any>;
    disableHmacNotifications: () => Promise<any>;
    signHmac: (id: number, code: string) => Promise<any>;
    updateHmac: (newSecret: string, appHash?: string) => Promise<any>;
  }
}

const userApi: UserApi = {
  verification: {
    getVerificationStatus: (cardholderId) => API.get(ENDPOINTS.USER_CTRL.VERIFICATION.GET_STATUS, {
      params: {
        cardholderId,
      }
    }),
    startVerification: (isWeb = true) => API.get(ENDPOINTS.USER_CTRL.VERIFICATION.START, {
      params: {
        isWeb,
      }
    }),
  },
  hmac: {
    deleteHmac: () => API.delete(ENDPOINTS.USER_CTRL.HMAC.DELETE),
    disableHmacNotifications: () => API.post(ENDPOINTS.USER_CTRL.HMAC.DISABLE_NOTIFICATIONS),
    signHmac: (id, code) => API.post(ENDPOINTS.USER_CTRL.HMAC.SIGN, {}, {
      params: {
        id, code,
      }
    }),
    updateHmac: (newSecret, appHash) => API.put(ENDPOINTS.USER_CTRL.HMAC.UPDATE, {}, {
      params: { newSecret, appHash }
    })
  }
};

export default userApi;
