import { Flex, Typography } from 'antd';
import classes from './Option.module.scss';
import React from 'react';
import { CardInfoT } from 'common/types/cards';
import { getLinearGradientString } from '../../../common/lib/utils/helpers';
import { Card as CardComponent } from '../../../common/components/UI';
import { MAIN_GRADIENT } from '../../lib/constants/colors';

const { Text } = Typography;

type OptionProps = {
  onChange: (event: any, option: CardInfoT) => unknown;
  option: CardInfoT
}

const Option: React.FC<OptionProps> = ({ onChange, option }) => (
  <Flex justify="space-between" align="center" className={classes['option']}
        onClick={(event) => onChange(event, option)}>
    <Flex vertical gap={4}>
      <Text style={{ fontSize: 14 }}>
        Weststein | {option.cardNumber}
      </Text>
      <Text>
        {option?.availBal ? option?.availBal.toFixed(2) : '0.00'} €
      </Text>
    </Flex>
    <CardComponent
      size="s"
      shortNumber={option.cardNumber.slice(-4) || ''}
      bgColor={getLinearGradientString(MAIN_GRADIENT)}
    />
  </Flex>
);

export default Option;