import React from 'react';
import { Flex, Typography } from 'antd';
import classes from './ActionButton.module.css';

type ActionButtonProps = {
  text: string;
  prefix?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => unknown;
  id?: string;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  prefix,
  icon,
  onClick,
  id
}) => {
  return (
    <Flex flex={1} id={id} className={classes.wrapper} justify="space-between" onClick={onClick}>
      <Flex>
        {prefix &&
          <div style={{marginRight: 12}}>{prefix}</div>
        }
        <Typography.Text className={classes.text}>{text}</Typography.Text>
      </Flex>
      {icon}
    </Flex>
  );
};

export default ActionButton;
