import React from 'react';
import classes from './GlobalLoader.module.scss';
import { ActivityIndicator } from 'common/components/UI';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { getLoader } from 'common/store/selectors/app';
import { combineClasses } from 'common/lib/utils/helpers';

const GlobalLoader = () => {
  const { isActive, message, type } = useSelector(getLoader);

  if (isActive) return (
    <div className={combineClasses(classes.container, type === 'transparent' ? classes.transparent : undefined)}>
      <div className={classes.content}>
        <div className={classes.loaderContainer}>
          <ActivityIndicator fontSize={40} />
        </div>
        {Boolean(message) && (
          <Typography.Text className={classes.message}>{message}</Typography.Text>
        )}
      </div>
    </div>
  )
  return null;
};
export default GlobalLoader;