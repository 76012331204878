export const eventTypes = {
  all: {
    label: 'notifications.types.all',
    value: 'All'
  },
  // cardLimit: {
  //   label: 'notifications.types.cardLimit',
  //   value: 'CARD_LIMIT'
  // },
  verification: {
    label: 'notifications.types.verification',
    value: 'VERIFICATION'
  },
  // refill: {
  //   label: 'notifications.types.refill',
  //   value: 'REFILL'
  // },
  textNotification: {
    label: 'notifications.types.textNotification',
    value: 'TEXT_NOTIFICATION'
  },
  // expiringCard: {
  //   label: 'notifications.types.expiringCard',
  //   value: 'EXPIRING_CARD'
  // },
}