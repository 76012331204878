import { ERROR_MESSAGES, ERROR_TYPES } from '../../../../lib/constants/errors';

export const getErrorMessage = (
  errorId: string,
  t: (descriptor: object, values?: object) => string
) => {
  switch (errorId) {
    case ERROR_TYPES.INVALID_CARD_NUMBER:
      return t({id: ERROR_MESSAGES.INVALID_CARD_NUMBER})
    case ERROR_TYPES.RECIPIENT_CARD_NOT_VERIFIED:
      return t({id: ERROR_MESSAGES.RECIPIENT_CARD_NOT_VERIFIED})
    default:
      return t({id: ERROR_MESSAGES.OTHER})
  }
}