import React from 'react';
import { Spin, SpinProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ActivityIndicatorProps extends SpinProps {
  fontSize?: number;
}
const ActivityIndicator = ({ fontSize, ...props }: ActivityIndicatorProps) => {
  return (
    <Spin
      {...props}
      indicator={
        <LoadingOutlined
          style={{
            fontSize,
          }}
          spin
        />
      }
    />
  );
};
ActivityIndicator.defaultProps = {
  fontSize: 24,
};
export default ActivityIndicator;