import { useState, useEffect } from 'react';

type UseCountdown = (
  timeout?: number,
  onTimerEnds?: () => void,
) => [number, (time?: number) => void];

const useCountdown: UseCountdown = (timeout = 60, onTimerEnds) => {
  const [countdown, setCountdown] = useState(timeout);

  useEffect(() => {
    if (countdown === 0) {
      onTimerEnds?.();
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown, onTimerEnds]);

  const resetCountdown = (time?: number) => {
    setCountdown(time || timeout);
  };

  return [countdown, resetCountdown];
};

export default useCountdown;
